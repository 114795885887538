import React, { useState, useEffect, useRef } from 'react';

// import axios from 'axios';
import axios from '../../services/axios';
import qs from 'qs';
import { Table } from 'antd';
//import { SearchOutlined } from '@ant-design/icons';

const { Column } = Table;

export default function HealthPlusTable({ columns, apiUrl, searchText, filters, sortColumn, sortOrder, initialPageSize, refreshKey = 0 }) {
    let calledFecthAPI = useRef(false);
    // console.log('filters:', filters);

    const getRandomuserParams = (tableParams, filters) => ({
        pagesize: tableParams.pagination?.pageSize,
        pageno: tableParams.pagination?.current,
        ...filters
    });

    // const getRandomuserParams = function(params, filters){
    //     console.log('getRandomuserParams params:', params);
    //     let {pagination, ...rest} = params;
    //     const queryString = {
    //         pagesize: params.pagination?.pageSize,
    //         pageno: params.pagination?.current,
    //         ...rest
    //     };
    //     console.log('queryString:', queryString);
    //     return queryString;
    // };

    //const [apiUrl, setApiUrl] = useState(url);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);

    // const [queryParams, setQueryParams] = useState({
    //     page: 1,
    //     pagesize: initialPageSize || 10,
    //     searchText: null
    // });

    // const [pagination, setPagination] = useState({
    //     page: 1,
    //     pagesize: initialPageSize || 10,
    //     totalrecords: 0,
    //     totalpages: 0
    // });
    // console.log('filters.first_name:', filters.first_name);
    //const [searchParams, setSearchParams] = useState(filters);

    // function extractSearchFields() {
    //     let searchParams = {};
    //     for (let filter in filters) {
    //         console.log(filter);
    //         searchParams[filter] = filters[filter].text;
    //     }
    //     return searchParams;
    // }

    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: initialPageSize || 10,
        },
        // ...searchParams
        //...extractSearchFields()
    });

    function fetchData() { 
        setLoading(true);
        axios.get(`${apiUrl}?${qs.stringify(getRandomuserParams(tableParams, filters), { charset: 'iso-8859-1', skipNulls: true })}`, {
            headers: {
               // 'Authorization': `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOjUsImV4cCI6MTY5OTM1MDc2MTU4NSwiY29tcGFueSI6eyJjb21wYW55X2lkIjoxLCJjb21wYW55X2NvZGUiOiI2ODMxMDYiLCJjb21wYW55X21hc3Rlcl9kYl9uYW1lIjoib21faG9zcGl0YWxfbWFzdGVyIiwiY29tcGFueV90cmFuc2FjdGlvbl9kYl9uYW1lIjoib21faG9zcGl0YWxfdHJhbnNhY3Rpb24iLCJmaW5hbmNpYWxfeWVhcl9pZCI6MSwiaXNfY3VycmVudF9maW5hbmNpYWxfeWVhciI6MSwiY29tcGFueV9zdGF0dXMiOiJBQ1RJVkFURUQifX0._bym0X9R13S6oG52dUmGhyj72AHYICqioz3_ZIOEwxA`
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        })
            .then((response) => {
                // console.log('response:', response);
                setData(response.data.currentpage);
                // setTableParams({
                //     ...tableParams,
                //     pagination: {
                //         ...tableParams.pagination,
                //         total: response.data.totalrecords || 0
                //     }
                // });
                setTableParams({
                    pagination: {
                        ...tableParams.pagination,
                        total: response.data.totalrecords || 0
                    }
                });
                setLoading(false);
            }).catch((error) => {
                setLoading(false);
                console.log('Error occured:', error);
            });
    }

    // function fetchData() {
    //     setLoading(true);
    //     axios.get(`${apiUrl}?${qs.stringify(getRandomuserParams(tableParams), { charset: 'iso-8859-1', skipNull: true })}`)
    //         .then((response) => {
    //             console.log('response:', response);
    //             setData(response.data.currentpage);
    //             setTableParams({
    //                 ...tableParams,
    //                 pagination: {
    //                     ...tableParams.pagination,
    //                     total: response.data.totalrecords || 0
    //                 }
    //             });
    //             setLoading(false);
    //         }).catch((error) => {
    //             setLoading(false);
    //             console.log('Error occured:', error);
    //         });
    // }



    // useEffect(function () {
    //     // if (calledFecthAPI.current) {
    //     //     return;
    //     // }
    //     calledFecthAPI.current = true;
    //     fetchData();
    // }, [apiUrl, JSON.stringify(tableParams), filters]);

    useEffect(function () {
        fetchData();
    }, [JSON.stringify(tableParams), filters, refreshKey]);

    function handleTableChange(pagination, filters, sorter) { 
        // setTableParams({
        //     pagination,
        //     filters,
        //     ...sorter,
        // });
        setTableParams({
            pagination: {
                ...pagination
            },
            filters,
            ...sorter,
        });
        calledFecthAPI.current = false;
        // `dataSource` is useless since `pageSize` changed
        if (pagination.pageSize !== tableParams.pagination?.pageSize) {
            setData([]);
        }
    }

    return (
        <div style={{ margin: '10px 0px' }}>
            <Table
                dataSource={data}
                loading={loading}
                onChange={handleTableChange}
                //pagination={pagination}
                pagination={tableParams.pagination}
                size="middle"
                className='patient-table-wrapper symptam-table'
            >
                {columns.map((col) => (
                    <Column
                        key={col.dataIndex}
                        title={col.title}
                        dataIndex={col.dataIndex}
                        sorter={col.sorter}
                        // sortOrder={
                        //     filteredInfo.columnKey === col.dataIndex && filteredInfo.order
                        // }
                        filterDropdown={col.filterDropdown}
                        filterIcon={col.filterIcon}
                        render={col.render}
                    />
                ))}
            </Table>
        </div>
    );
}