import React from 'react';

import {
    Modal,
} from 'antd';

import Prescription from './prescription-new';
export default function GeneratePrescription({
    open,
    onCreate,
    onCancel,
    patient,
}) {
    return (
        <>
            <Modal
                title="Generate Prescription"
                centered="true"
                open={open}
                okText="Generate"
                destroyOnClose={true}
                mask={true}
                maskClosable={false}
                // onOk={() => {
                    
                // }}
                onCancel={() => {
                    onCancel();
                }}
                width={1300}
                footer={null}
            >
                <Prescription patient={patient}></Prescription>
            </Modal>
        </>
    );
}
