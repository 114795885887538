import { Input, Button, Space, notification, Tooltip, Modal, Typography } from 'antd';


import { PlusOutlined, ScheduleOutlined } from '@ant-design/icons';
import { FaPrescription } from 'react-icons/fa';
import { useState } from 'react';
import {PDFViewer} from '@react-pdf/renderer';
import HealthPlusTable from '../../components/datatable/datatable-new';
import AddPatient from '../../components/patient/AddPatient';
import GeneratePrescription from '../../components/prescription/generate-prescription';
import BookAppointment from '../../components/appointment/book-appointment';

import Invoice from  '../../components/reports/invoice';//'./components/reports/Invoice'
import invoiceData from '../../components/reports/data/invoice-data' ;//'../reports/data/invoice'
const { Search } = Input;
const { Text } = Typography;
export default function Patients() {
    const [filters, setFilters] = useState({});
    const [refreshKey, setRefreshKey] = useState(0);
    //const [showAddPatient, setShowAddPatient] = useState(true);
    const [open, setOpen] = useState(false);
    const [openAppointmentDialog, setOpenAppointmentDialog] = useState(false);
    const [openPrescriptionModal, setOpenPrescriptionModal] = useState(false);
    const [selectedpatient, setSelectedpatient] = useState({});
    const [api, contextHolder] = notification.useNotification();

    const [showInvoice, setShowInvoice] = useState(false);
    const [invoiceDetails, setInvoiceDetails] = useState({});

    const openNotificationWithIcon = (type, title, description) => {
        api[type]({
          message: title,
          description: description,
          placement: "top",
          duration: 2,
          role: "status"
        });
    };


    const onCreate = () => {
        openNotificationWithIcon('success', 'Success', '');
        setOpen(false);
        setRefreshKey((previousKey)=> previousKey + 1);
    };

    function displayInvoice(){
        setShowInvoice(true);
    }

    const openAppointmentModal = (invoiceResponse) => {
        let invoice = invoiceResponse.data;
        setOpenAppointmentDialog(false);
        invoice['company'] = JSON.parse(localStorage.getItem('user'))?.user.company_name || '';
        invoice['company_address'] = JSON.parse(localStorage.getItem('user'))?.user.company_location || '';
        invoice.created_ts = getTodaysDate(invoice.created_ts);
        invoice['items']  = [{
            "sl_no": 1,
            "particular": "Consultation",
            "rate": invoice?.amount,
            "qty": 1,
            "discount": invoice?.discounted_amount,
            "total": invoice?.total_amount
        }];
        console.log('Invoice response:', invoice);
        setInvoiceDetails(invoice);
        displayInvoice();
    };

    //IMPORTANT!!!!!THis is required when we are dealing with column filters, we have an external search so below function will work for ti.
    // const handleFilterChange = (columnKey, value) => {
    //     const newFilters = { ...filters };
    //     newFilters[columnKey] = value.text;
    //     console.log('newFilters:', newFilters);
    //     setFilters(newFilters);
    // };

    const handleFilterChange = (columnKey, value) => {
        if(!value){
           setFilters({});
        } else {
            const newFilters = { ...filters };
            newFilters[columnKey] = (value && value.text) || value;
            console.log('newFilters:', newFilters);
            setFilters(newFilters);
        }
    };

    const onGeneratePrescriptionClick = function (record) {
        setSelectedpatient(() => {
            console.log('record:', record);
            return record;
        });
        setOpenPrescriptionModal(true);
    };

    const onCreatePrescriptionModal = (values) => {
        console.log('Received values of form: ', values);
        setOpenPrescriptionModal(false);
    };

    const getTodaysDate = function(date) {
        let d = new Date();
        if(date){
            d = new Date(date);
        }
        
        let month = '' + (d.getMonth() + 1);
        let day = '' + d.getDate();
        let year = d.getFullYear();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
    
            return [day, month, year ].join('-');
    };

    function generateFullName(record) {
        // Initialize an empty array to store non-empty name parts
        let nameParts = [];
    
        // Add non-empty name parts to the array
        if (record.patient_first_name) {
            nameParts.push(record.patient_first_name);
        }
        if (record.patient_middle_name) {
            nameParts.push(record.patient_middle_name);
        }
        if (record.patient_last_name) {
            nameParts.push(record.patient_last_name);
        }
    
        // Join the name parts with a space separator
        return nameParts.join(' ');
    }

    //columns, url, searchText, filters, sortColumn, sortOrder, initialPageSize
    let columns = [
        {
            title: 'Patient Id',
            dataIndex: 'patient_id',
            sorter: false,
        },
        // {
        //     title: 'Hospital Number',
        //     dataIndex: 'icard_number',
        //     sorter: false,
        // },
        {
            title: 'Patient Full Name',
            dataIndex: 'patient_first_name',
            sorter: false,
            render: (text, record)=>{
               return <Text>{generateFullName(record)}</Text>
            }
        },
        // {
        //     title: 'Patient Name',
        //     dataIndex: 'patient_first_name',
        //     sorter: false
        // },
        // {
        //     title: 'Middle Name',
        //     dataIndex: 'patient_middle_name',
        //     sorter: false,
        // },
        // {
        //     title: 'Last Name',
        //     dataIndex: 'patient_last_name',
        //     sorter: false,
        // },
        {
            title: 'Address',
            dataIndex: 'patient_address',
            sorter: false,
        },
        {
            title: 'Mobile',
            dataIndex: 'mobile',
            sorter: false
        },
        {
            title: 'Gender',
            dataIndex: 'gender',
            sorter: false,
        },
        {
            title: 'DOB/Age',
            dataIndex: 'date_of_birth',
            sorter: false,
            render: (text, record, index) => (
                <>
                    <Space size="middle">
                        {record.date_of_birth ? getTodaysDate(record.date_of_birth): (record.patient_age ? record.patient_age: '')}
                    </Space>
                </>
            )
        },
        
        {
            title: 'Email',
            dataIndex: 'email',
            sorter: false,
        },
        {
            title: 'Blood Group',
            dataIndex: 'blood_group_lookup_id',
            sorter: false,
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record, index) => (
                <>
                    <Space size="middle" style={{ columnGap: '3px'}}>
                        <Tooltip placement="top" title="Schedule Appointment">
                            <Button shape="circle" icon={<ScheduleOutlined />} className={record === selectedpatient ? 'highlighted-book-appintment-btn' : ''} onClick={e => {
                                setSelectedpatient(() => record);
                                setOpenAppointmentDialog(true);
                            }} />
                        </Tooltip>
                    
                        {(JSON.parse(localStorage.getItem('user')).user.user_type_lookup_id ===  1) && (
                                <Tooltip placement="top" title="Generate Prescription">
                                    <Button style={{ marginLeft: "8px"}}
                                        shape="circle"
                                        icon={<FaPrescription />}
                                        onClick={(e) => {
                                            record = {...record, doctor_id: JSON.parse(localStorage.getItem('user')).user.user_type_table_id, patient_name: record.patient_first_name, doctor: `Dr. ${JSON.parse(localStorage.getItem('user')).user.user_first_name} ${JSON.parse(localStorage.getItem('user')).user.user_last_name ? JSON.parse(localStorage.getItem('user')).user.user_last_name : ''}`, qualification: `${JSON.parse(localStorage.getItem('user')).doctor.doctor_qualification}`, prescription_date: `${getTodaysDate()}`, registration_number: `${JSON.parse(localStorage.getItem('user')).doctor.doctor_registration_number}`};
                                            onGeneratePrescriptionClick(record);
                                        }}
                                    />
                                </Tooltip>
                        )}
                    </Space>
                </>
            ),
          },
    ];

    const onSearch = function(value){
        if(isNaN(value)){
            handleFilterChange('patient_first_name', value);
        } else {
            handleFilterChange('mobile', value);
        }
    };

    const url = '/api/patients';
    const sortColumn = 'patient_id';
    const sortOrder = 'asc';
    const initialPageSize = 10;
    return (
        <div className='pad-tb10 marg-lr10'>

            {contextHolder}

            <Modal
                title=""
                centered="true"
                open={showInvoice}
                // okText="Print"
                //cancelText="Close"
                //okButtonProps={{ icon: <ScheduleOutlined />}}
                destroyOnClose={true}
                // cancelButtonProps={{
                //     danger: 'danger',
                //     icon: <MdFreeCancellation />,
                // }}
                mask={true}
                maskClosable={false}
                onOk={() => {
                    setShowInvoice(false);
                    setInvoiceDetails({});
                }}
                onCancel={() => {
                    setShowInvoice(false);
                    setInvoiceDetails({});
                }}
                width={1070}
                footer={null}
            >
                <PDFViewer fileName="invoice.pdf" width="1000" height="800" className="app" >
                    <Invoice invoice={invoiceDetails}/>
                </PDFViewer>
            </Modal>

            {/* {showInvoice &&  (<PDFViewer width="1200" height="600" className="app" >
                        <Invoice invoice={invoiceDetails}/>
                </PDFViewer>)
            } */}
             <Space direction="horizontal">  
                    <Button
                        type="primary"
                        icon={<PlusOutlined />}
                        onClick={() => {
                            setOpen(true);
                        }}
                    >
                        Add Patient
                    </Button>
                    <Search
                        placeholder="Search By Mobile or First Name..."
                        name="first_name_or_mobile"
                        allowClear
                        enterButton
                        style={{
                            width: 300,
                        }}
                        onSearch={(event)=> {onSearch(event)}}
                    />  
            </Space>
            <AddPatient
                open={open}
                onCreate={onCreate}
                onCancel={() => {
                    setOpen(false);
                }}
                onError={()=>{
                    openNotificationWithIcon('error', 'Error', 'OOPS! Something went wrong!');
                }}
            ></AddPatient>

            <BookAppointment
                open={openAppointmentDialog}
                // slots={slots}
                onCreate={(openAppointmentModal)}
                onCancel={() => {
                    setOpenAppointmentDialog(false);
                }}
                patient={selectedpatient}
            ></BookAppointment>

            <GeneratePrescription
                open={openPrescriptionModal}
                onCreate={onCreatePrescriptionModal}
                onCancel={() => {
                    setOpenPrescriptionModal(false);
                }}
                patient={selectedpatient}
            ></GeneratePrescription>

            <HealthPlusTable
                columns={columns}
                apiUrl={url}
                filters={filters}
                sortColumn={sortColumn}
                sortOrder={sortOrder}
                initialPageSize={initialPageSize}
                refreshKey= {refreshKey}
            ></HealthPlusTable>
        </div>
    );
}
