import React, { useEffect, useState } from 'react';
import axios from '../../services/axios';
import {
    Form,
    Modal,
    Input,
    DatePicker,
    Space,
    Row,
    Col,
    Typography,
    Divider,
    notification,
    Checkbox ,
    Button
} from 'antd';
import { ScheduleOutlined } from '@ant-design/icons';
import { MdFreeCancellation } from 'react-icons/md';
import dayjs from 'dayjs';
// import {PDFViewer} from '@react-pdf/renderer'
// import Invoice from  '../reports/invoice';//'./components/reports/Invoice'
// import invoiceData from '../reports/data/invoice-data' ;//'../reports/data/invoice'

import CustomSelect from '../dropdown';

import TimeSots from './timeslots';
const { Text } = Typography;
const layout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 16,
    },
};

export default function BookAppointment({
    open,
    onCreate,
    onCancel,
    patient,
}) {
    const [form] = Form.useForm();
    //const dateFormat = 'DD-MM-YYYY';
    const dateFormat = 'YYYY-MM-DD';
    const getTodaysDate = function (date) {
        let d = date ? new Date(date) : new Date();
        let month = '' + (d.getMonth() + 1);
        let day = '' + d.getDate();
        let year = d.getFullYear();

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;

        return [year, month, day].join('-');
    };


    const [api, contextHolder] = notification.useNotification();
    const [selectedSpecialty, setSelectedSpecialty] = useState(null);
    const [selectedDoctor, setSelectedDoctor] = useState(null);
    const [selectedDoctorDetails, setSelectedDoctorDetails] = useState(null);
    //const [selectedDate, setSelectedDate] = useState(getTodaysDate());
    const [selectedDate, setSelectedDate] = useState(null);
    const [slots, setSlots] = useState([]);
    const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);

    const [isFolloupConsultation, setIsFolloupConsultation] = useState(false);
    const [consultationFees, setConsultationFees] = useState('');
    const [consultationDiscout, setConsultationDiscout] = useState(0);

    const [doctorSearchValue, setDoctorSearchValue] = useState('');

    // const [showInvoice, setShowInvoice] = useState(false);

    const openNotificationWithIcon = (type, title, description) => {
        api[type]({
          message: title,
          description: description,
          placement: "top",
          duration: 2,
          role: "status"
        });
    };

    const onFinish = (values) => {
        //console.log(values);
    };

    // const onReset = () => {
    //     form.resetFields();
    // };

    // const onFill = () => {
    //     form.setFieldsValue({
    //         note: 'Hello world!',
    //         gender: 'Male',
    //     });
    // };

    // eslint-disable-next-line arrow-body-style
    const disabledDate = (current) => {
        // Can not select days before today and today
        //return current && current < dayjs().endOf('day');
        return current && current < dayjs().subtract(1, 'day'); //Disable date yesterday and before
    };

    // function handleOnFinish() {}

    // function onFinishFailed() {}
    const labelCol = {
        span: 4, // this means the label will take 4/24 of the row width
        offset: 0, // this means the label will have no offset from the left
        style: {
            width: '100px', // this means the label will have a fixed width of 100px
            textAlign: 'left',
        },
    };

    const handleSpecialtyChange = (value) => {
        form.resetFields(['time_slot_date']);
        setSelectedSpecialty(value);
        setSelectedDoctor(null);
        setSelectedDate(null);
        setSelectedTimeSlot(null);
        setConsultationDiscout(0);
        setConsultationFees(0);
        setIsFolloupConsultation(false);
        setSlots([]);
    };

    const handleDoctorChange = (value) => {
        console.log('handleDoctorChange selectedDate before:', selectedDate);
        setSelectedDoctor(value);
        form.resetFields(['time_slot_date']);
        setSelectedDate(()=>{
            return null;
        });
        setSelectedTimeSlot(null);
        setConsultationDiscout(0);
        setConsultationFees(0);
        setIsFolloupConsultation(false);
        setSlots([]);
        setTimeout(()=> {
            console.log('handleDoctorChange selectedDate after :', selectedDate);
        }, 1000);
    };

    const handleSlotChange = (timeSlot)=>{
        setSelectedTimeSlot(timeSlot);
    };

    const handleDoctorSearch = (value) => {
        setDoctorSearchValue(value);
    };

    useEffect(()=>{
        async function getDoctor(){
            try{
                let doctor = await axios.get(`/api/doctors/${selectedDoctor}`);
                setSelectedDoctorDetails(doctor.data);
            } catch(exception){
                console.error('Error in getting doctor by id:', exception);
            }
        }
        
        //If doctor id set then only
        if(selectedDoctor){
            getDoctor();
        }
        
    }, [selectedDoctor]);

    useEffect(()=>{
        async function getDoctorSlots(){
            try{
                //api/doctors/18/slots?date=2023-12-25
                let slots = await axios.get(`/api/doctors/${selectedDoctor}/slots?date=${selectedDate}`);
                console.log('slots url:', slots);
                //enum('Open','Booked','Cancelled','Not Available') DEFAULT 'Open',

                slots.data.forEach((slot)=>{
                    if(slot.status.toLowerCase() === 'Booked'.toLowerCase()){
                        slot.status_color = '#f50';
                    }

                    if(slot.status.toLowerCase() === 'Open'.toLowerCase()){
                        slot.status_color = '#2db7f5';
                    }

                    if(slot.status.toLowerCase() === 'Cancelled'.toLowerCase()){
                        slot.status_color = '#ff0000';
                    }

                    if(slot.status.toLowerCase() === 'Not Available'.toLowerCase()){
                        slot.status_color = '#FFFF00';
                    }
                });
                setSlots(slots.data);
            } catch(exception){
                console.error('Error in getting doctor slots by doctor id & Slot date:', exception);
            }
        }
        
        //If doctor id set then only
        if(selectedDoctor && selectedDate){
            getDoctorSlots();
        }
        
    }, [selectedDoctor, selectedDate]);

    const onDateChange = (date, dateString) => {
        console.log('getTodaysDate(dateString:', getTodaysDate(dateString));
        //setSelectedDate(getTodaysDate(dateString));
        if(dateString){
            setSelectedDate(getTodaysDate(dateString));
        } else {
            setSelectedDate(null);
        }
    };

    const reserveSlot = function(callback){
        let payload = {
            patient_id: patient.patient_id,
            time_slot_id: selectedTimeSlot.time_slot_id,
            amount: consultationFees,
            discount_percentage: consultationDiscout
        };

        axios.post('/api/hospital/appointment', payload).then((response)=>{
            form.resetFields(['time_slot_date']);
            setSelectedSpecialty(null);
            setSelectedDoctor(null);
            setSelectedDate(null);
            setSelectedTimeSlot(null);
            setConsultationDiscout(0);
            setConsultationFees(0);
            setIsFolloupConsultation(false);
            setSlots([]);
            return callback(null, response);
            //openNotificationWithIcon('success', 'Success', 'Appointment is scheduled!');
        }).catch((error)=>{
            form.resetFields(['time_slot_date']);
            setSelectedSpecialty(null);
            setSelectedDoctor(null);
            setSelectedDate(null);
            setSelectedTimeSlot(null);
            setConsultationDiscout(0);
            setConsultationFees(0);
            setIsFolloupConsultation(false);
            setSlots([]);
            console.log('Error occured while creating the appointment:', error);
            openNotificationWithIcon('error', 'Error', 'OOPS! Something went wrong!');
            return callback(error);
        });

        // setSelectedSpecialty(null);
        // setSelectedDoctor(null);
        // setSelectedDate(null);
        // setSlots([]);
    };

    // function displayInvoice(){
    //     setShowInvoice(true);
    // }


    const onFollowUpChange = (e) => {
        setIsFolloupConsultation(e.target.checked);
        // setConsultationFees(0);
        // setConsultationDiscout(0);
        if(e.target.checked){
            setConsultationFees(0);
            setConsultationDiscout(0);
        } 
    };

    // const [consultationFees, setConsultationFees] = useState(0);
    // const [consultationDiscout, setConsultationDiscout] = useState(0);
    const onConsultationFeesChange = (e) => {
        let value = e.target.value;
        setConsultationFees(value);
    };

    const onDiscountChange = (e)=>{
        let value = e.target.value;
        setConsultationDiscout(value);
    };

    return (
        <>
            {contextHolder}
            {/* {showInvoice &&  (<PDFViewer width="1000" height="600" className="app" >
                        <Invoice invoice={invoiceData}/>
                </PDFViewer>)
            } */}
            
            <Modal
                title="Schedule Appointment"
                centered="true"
                open={open}
                okText="Schedule Appointment"
                // okButtonProps={{ icon: <ScheduleOutlined />, disabled: (selectedDate && selectedDoctor && selectedSpecialty && selectedTimeSlot && consultationFees) ? false : true }}
                okButtonProps={{ icon: <ScheduleOutlined />, disabled:  ((selectedDate && selectedDoctor && selectedSpecialty && selectedTimeSlot &&  (!isFolloupConsultation ? (consultationFees ? true: false) : true))   ? false : true) }}
                destroyOnClose={true}
                cancelButtonProps={{
                    danger: 'danger',
                    icon: <MdFreeCancellation />,
                }}
                mask={true}
                maskClosable={false}
                onOk={() => {
                    form.validateFields().then((values) => {
                        //reserveSlot(values);
                        form.resetFields();
                        reserveSlot((error, response)=>{
                            if(error){
                                return console.log('Error in scheduling the appointment:', error);
                            }
                            onCreate(response);
                        });
                       // form.resetFields();
                      // displayInvoice();
                    })
                    .catch((info) => {
                        console.log('Validate Failed:', info);
                    });
                }}
                onCancel={() => {
                    form.resetFields();
                    setSelectedSpecialty(null);
                    setSelectedDoctor(null);
                    setSelectedDate(null);
                    setSelectedTimeSlot(null);
                    setConsultationDiscout(0);
                    setConsultationFees(0);
                    setIsFolloupConsultation(false);
                    setSlots([]);
                    onCancel();
                }}
                width={1000}
            >
                <Form  preserve={false}
                    {...layout}
                    form={form}
                    name="book-appointment"
                    layout="vertical"
                    onFinish={onFinish}
                    style={{
                        // maxWidth: 750,
                        marginTop: '30px',
                        paddingLeft: '5px',
                    }}
                    // layout="inline"
                >
                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                        {/* <Col className="gutter-row" span={1}></Col> */}
                        <Col className="gutter-row" span={12}>
                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                <Col className="gutter-row" span={24}>
                                    <Text
                                        style={{
                                            display: 'inline-block',
                                            float: 'left',
                                            paddingRight: '5px',
                                            fontWeight: '500',
                                        }}
                                    >
                                        Patient Id:
                                    </Text>
                                    <Text>{patient && patient.patient_id}</Text>
                                </Col>
                            </Row>

                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                <Col className="gutter-row" span={24}>
                                    <Text
                                        style={{
                                            display: 'inline-block',
                                            float: 'left',
                                            paddingRight: '5px',
                                            fontWeight: '500',
                                        }}
                                    >
                                        Patient Name:
                                    </Text>
                                    <Text>
                                        {patient && patient.patient_first_name}{' '}
                                        {patient && patient.patient_middle_name}{' '}
                                        {patient && patient.patient_last_name}
                                    </Text>
                                </Col>
                            </Row>

                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                <Col className="gutter-row" span={24}>
                                    <Text
                                        style={{
                                            display: 'inline-block',
                                            float: 'left',
                                            paddingRight: '5px',
                                            fontWeight: '500',
                                        }}
                                    >
                                        Patient Address:
                                    </Text>
                                    <Text>
                                        {patient && patient.patient_address}
                                    </Text>
                                </Col>
                                <Col className="gutter-row" span={24}>
                                    <Text
                                        style={{
                                            display: 'inline-block',
                                            float: 'left',
                                            paddingRight: '5px',
                                            fontWeight: '500',
                                        }}
                                    >
                                        Appointment Date:
                                    </Text>
                                    <Text>
                                        {selectedDate}
                                    </Text>
                                </Col>
                                <Col className="gutter-row" span={24}>
                                    <Text
                                        style={{
                                            display: 'inline-block',
                                            float: 'left',
                                            paddingRight: '5px',
                                            fontWeight: '500',
                                        }}
                                    >
                                        Cons. Fees: {consultationFees}
                                        &nbsp;(Discount: {consultationDiscout} %)
                                    </Text>
                                    {/* <Text>
                                        {consultationFees}
                                    </Text> */}
                                </Col>
                            </Row>
                        </Col>
                        

                        <Col className="gutter-row" span={12}>
                            <div style={{ marginBottom: '5px' }}>
                                <Text
                                    style={{
                                        paddingRight: '5px',
                                        fontWeight: '500',
                                    }}
                                >
                                    Doctor:
                                </Text> 
                                <Text>{selectedDoctorDetails && selectedDoctorDetails.first_name} {' '}
                                        {selectedDoctorDetails && selectedDoctorDetails.middle_name} {' '}
                                        {selectedDoctorDetails && selectedDoctorDetails.last_name}, {' '} </Text>
                                <Text
                                    style={{
                                        display: 'inline-block',
                                        fontStyle: 'italic',
                                    }}
                                >
                                    {selectedDoctorDetails && selectedDoctorDetails.qualification},  {' '} ({selectedDoctorDetails && selectedDoctorDetails.specialty_id} - speciality)
                                </Text>
                            </div>
                        </Col>
                        {/* <Col className="gutter-row" span={4}></Col> */}
                    </Row>

                    <Divider style={{ margin: '10px 0px' }} />
                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col
                            className="gutter-row"
                            span={6}
                            style={{ paddingRight: '0px' }}
                        >
                            <Form.Item
                                label=""
                                name="followup"
                                className="prescription-label"
                                style={{ fontWeight: '500',  width: '100%' }}
                                wrapperCol={{ span: 24 }}
                                labelCol={{ span: 24 }} // Control the width of the label
                            >
                                <Checkbox onChange={onFollowUpChange} checked={isFolloupConsultation}>Is Follow-up Consultation?</Checkbox>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                        <Col
                            className="gutter-row"
                            span={6}
                            style={{ paddingRight: '0px' }}
                        >
                            <Form.Item
                                //labelCol={{ ...labelCol }}
                                // labelCol={{
                                //     span: 8, // this means the label will take 4/24 of the row width
                                //     offset: 0, // this means the label will have no offset from the left
                                //     style: {
                                //         //width: '100px', // this means the label will have a fixed width of 100px
                                //         textAlign: 'left',
                                //     },
                                // }}
                                label="Speciality"
                                name="doctor_speciality"
                                className="prescription-label"
                                style={{ fontWeight: '500',  width: '100%' }}
                                wrapperCol={{ span: 24 }}
                            >
                                <CustomSelect
                                    selectedValue={selectedSpecialty}
                                    apiEndpoint={`/api/users/${
                                        JSON.parse(localStorage.getItem('user'))
                                            .user.user_id
                                    }/speciality`}
                                    placeholder="Select Specialty"
                                    optionFormat={{
                                        id: 'specialty_id',
                                        label: 'specialty_name',
                                    }}
                                    onChange={handleSpecialtyChange}
                                />
                            </Form.Item>
                        </Col>
                        <Col
                            className="gutter-row"
                            span={5}
                            style={{ paddingRight: '0px' }}
                        >
                            <Form.Item
                                // labelCol={{
                                //     span: 6, // this means the label will take 4/24 of the row width
                                //     offset: 0, // this means the label will have no offset from the left
                                //     style: {
                                //         //width: '100px', // this means the label will have a fixed width of 100px
                                //         textAlign: 'left',
                                //     },
                                // }}
                                label="Doctor"
                                name="doctor_id"
                                className="prescription-label"
                                style={{
                                    fontWeight: '500',
                                    marginBottom: '10px',
                                }}
                                wrapperCol={{ span: 24 }}
                            >
                                <CustomSelect
                                    selectedValue={selectedDoctor}
                                    apiEndpoint={`/api/doctors?specialty_id=${selectedSpecialty}`}
                                    placeholder="Select Doctor"
                                    optionFormat={{
                                        id: 'doctor_id',
                                        label: 'first_name',
                                    }}
                                    onChange={handleDoctorChange}
                                    disabled={!selectedSpecialty}
                                    handleSearch={handleDoctorSearch}
                                />
                            </Form.Item>
                        </Col>

                        <Col
                            className="gutter-row"
                            span={4}
                            style={{ paddingRight: '0px' }}
                        >
                            <Form.Item
                                label='Date'
                                name="time_slot_date"
                                className="prescription-label"
                                style={{ fontWeight: '500' }}
                                wrapperCol={{ span: 24 }}
                            > 
                                <Space direction="horizontal" size={12}>
                                    <DatePicker
                                        // value={selectedDate}
                                        // value={selectedDate ? dayjs(selectedDate).format('DD/MM/YYYY') : null}
                                        format={dateFormat}
                                        disabledDate={disabledDate}
                                        onChange={onDateChange}
                                        disabled={!selectedSpecialty || !selectedDoctor}
                                        allowClear
                                    />
                                </Space>
                            </Form.Item>
                        </Col>
                        <Col
                            className="gutter-row"
                            span={3}
                            style={{ paddingRight: '0px' }}
                        >
                            <Form.Item
                                label="Fees"
                                // name="amount" //Do not use name when it is a controlled element of entd form.item
                                className="prescription-label"
                                style={{ fontWeight: '500' }}
                                wrapperCol={{ span: 24 }}
                            >
                                {/* {consultationFees} */}
                                <Input value={consultationFees} onChange={onConsultationFeesChange} disabled={isFolloupConsultation}/>
                            </Form.Item>
                        </Col>
                        <Col
                            className="gutter-row"
                            span={3}
                            style={{ paddingRight: '0px' }}
                        >
                            <Form.Item
                                label="Discount (%)"
                                // name="discount_percentage" //Do not use name when it is a controlled element of entd form.item
                                className="prescription-label"
                                style={{ fontWeight: '500' }}
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                            >
                                {/* {consultationDiscout} */}
                                <Input value={consultationDiscout} onChange={onDiscountChange} disabled={isFolloupConsultation}/>
                            </Form.Item>
                        </Col>
                        {/* <Col className="gutter-row" span={4}></Col> */}
                    </Row>

                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                        <Col xs={12} md={23} lg={23}>
                            <Form.Item
                                label=""
                                labelCol={{ ...labelCol }}
                                name="time_slot_time"
                                className="prescription-label"
                                wrapperCol={{ span: 24 }}
                                style={{ marginBottom: '30px' }}
                            >
                                <Space>
                                    <TimeSots
                                        slots={slots}
                                        direction="horizontal"
                                        size={24}
                                        tagSelected={handleSlotChange}
                                    ></TimeSots>
                                </Space>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </>
    );
}
