import { Typography } from 'antd';
import { useState } from 'react';
import { Button, Space, Modal, Tooltip } from 'antd';
import { Select } from 'antd';
import { FaPrescription } from 'react-icons/fa';
import PreviewPrescription from '../../components/prescription/preview-prescription';
import HealthPlusTable from '../../components/datatable/datatable-new';
const { Text } = Typography;
const { Option } = Select;

export default function MyPrescriptions({
    gridPageSize,
    refreshKey,
    setRefreshKey,
}) {
    let doctorId;
    const storage = JSON.parse(localStorage.getItem('user'));
    if (
        storage &&
        storage.user &&
        storage.user.user_type_lookup_id &&
        parseInt(storage.user.user_type_lookup_id) === 1 &&
        storage.doctor &&
        storage.doctor.doctor_id
    ) {
        doctorId = storage.doctor.doctor_id;
    }
    
    const [filters, setFilters] = useState({
        doctorId: doctorId,
        searchFor: 'today',
    });
    const [openPrescriptionModal, setOpenPrescriptionModal] = useState(false);
    const [selectedPrescriptionRecord, setSelectedPrescriptionRecord] = useState({});
    const [selectedFilterValue, setSelectedFilterValue] = useState('Today');

    const handleSelectChange = function(value){
        let searchFor;
        setSelectedFilterValue(value);
        switch (value) {
            case 'Today':
                searchFor = 'today';    
            setSelectedFilterValue('Today');
                break;
            case 'Yesterday':
                searchFor = 'yesterday';    
                setSelectedFilterValue('Yesterday');
                break;
            case 'This Week':
                searchFor = 'this_week';    
                setSelectedFilterValue('This Week');
                break;
            case 'This Month':
                searchFor = 'this_month';    
                setSelectedFilterValue('This Month');
                break;
            default:
                break;
        }
        setFilters({ ...filters, searchFor: searchFor });
    };

    const selectOptions = [{
        key: 1,
        value: "Today"
    },{
        key: 1,
        value: "Yesterday"
    },{
        key: 1,
        value: "This Week"
    },{
        key: 1,
        value: "This Month"
    }];

    function generateFullName(record) {
        // Initialize an empty array to store non-empty name parts
        let nameParts = [];

        // Add non-empty name parts to the array
        if (record.patient_first_name) {
            nameParts.push(record.patient_first_name);
        }
        if (record.patient_middle_name) {
            nameParts.push(record.patient_middle_name);
        }
        if (record.patient_last_name) {
            nameParts.push(record.patient_last_name);
        }

        // Join the name parts with a space separator
        return nameParts.join(' ');
    }

    const onGeneratePrescriptionClick = function (record) {
        setSelectedPrescriptionRecord(() => {
            return record;
        });
        setOpenPrescriptionModal(true);
    };

    let columns = [
        {
            title: 'Presc. Id',
            dataIndex: 'prescription_id'
        },
        {
            title: 'Date',
            dataIndex: 'prescription_date',
            sorter: false,
            // render: (text, record) => ({
            //     children: <span>{formatDate(text)}</span>,
            // })
        },
        {
            title: 'Patient Full Name',
            dataIndex: 'patient_first_name',
            sorter: false,
            render: (text, record) => {
                return <Text>{generateFullName(record)}</Text>;
                // return <Text>Sharad Biradar</Text>
            },
        },
        {
            title: 'Gender',
            dataIndex: 'patient_gender',
            sorter: false,
        },
        {
            title: 'Address',
            dataIndex: 'patient_address',
            sorter: false,
        },
        {
            title: 'Actions',
            key: 'action',
            render: (_, record) => (
                <>
                    <Space size="middle">
                        <Tooltip placement="top" title="View Prescription">
                            <Button
                                shape="circle"
                                icon={<FaPrescription />}
                                onClick={(e) => {
                                    onGeneratePrescriptionClick(record);
                                }}
                            />
                        </Tooltip>
                    </Space>
                </>
            ),
        },
    ];

    const url = `/api/doctors/${doctorId}/dashboard/prescriptions`;
    const sortColumn = 'prescription_id';
    const sortOrder = 'desc';
    const initialPageSize = gridPageSize || 5;
    

    return (
        <div className="pad-tb10 marg-lr10">
            <Select value={selectedFilterValue}
            style={{ width: '200px'}}
                placeholder='Today'
                onChange={handleSelectChange}
                filterOption={false} // Disable default filtering since we handle it manually
            >
                {selectOptions.map((option) => (
                    <Option key={option.key} value={option.value}>
                        {option.value}
                    </Option>
                ))}
            </Select>
            <HealthPlusTable
                columns={columns}
                apiUrl={url}
                filters={filters}
                sortColumn={sortColumn}
                sortOrder={sortOrder}
                initialPageSize={initialPageSize}
                refreshKey={refreshKey}
            ></HealthPlusTable>

            <Modal
                title="View Prescription"
                centered="true"
                open={openPrescriptionModal}
                okText="Generate"
                mask={true}
                destroyOnClose={true}
                maskClosable={false}
                onCancel={() => {
                    setOpenPrescriptionModal(false);
                }}
                width={'1200px'}
                footer={null}
            >
                <PreviewPrescription
                    prescriptionDetails={selectedPrescriptionRecord}
                ></PreviewPrescription>
            </Modal>
        </div>
    );
}
