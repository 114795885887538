import { Tag } from 'antd';
// status : 'Confirmed','In Progress','Consulted','Cancelled','Refunded'
export default function AppointmentStatus({ status }) {
    let color = '#4096ff';
    if (status.toLowerCase() === 'Confirmed'.toLowerCase()) {
        color = '#4096ff';
    } else if (status.toLowerCase() === 'Consulted'.toLowerCase()) {
        color = '#52C41A';
    } else if (status.toLowerCase() === 'Cancelled'.toLowerCase()) {
        color = '#ff4d4f';
    } else if (status.toLowerCase() === 'Refunded'.toLowerCase()) {
        color = '#FA8C16';
    } else if (status.toLowerCase() === 'In Progress'.toLowerCase()) {
        color = '#722ED1';
    }

    return (
        <Tag color={color} key={status}>
            {' '}
            {status.toUpperCase()}{' '}
        </Tag>
    );
}
