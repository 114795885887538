import React, { useState, useEffect } from 'react';
import {
    Form,
    Input,
    DatePicker,
    Select,
    Button,
    Upload,
    Avatar,
    Row,
    Col,
    notification,
} from 'antd';
import { UserOutlined, UploadOutlined } from '@ant-design/icons';

import axios from '../../services/axios';

const { Option } = Select;

const UserProfilePage = () => {
    const [form] = Form.useForm();
    const [api, contextHolder] = notification.useNotification();

    const [userData, setUserData] = useState(null);
    const openNotificationWithIcon = (type, title, description) => {
        api[type]({
            message: title,
            description: description,
            placement: 'top',
            duration: 2,
            role: 'status',
        });
    };

    const getTodaysDate = function (date) {
        let d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;

        return [year, month, day].join('-');
    };

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                let url = `/api/users/${
                    JSON.parse(localStorage.getItem('user')).user.user_id
                }`;
                const response = await axios.get(url);
                //response.data.user_dob = getTodaysDate(response.data.user_dob);
                delete response.data.user_dob;
                setUserData(response.data);
                form.setFieldsValue(response.data); // Bind data to form fields
            } catch (error) {
                openNotificationWithIcon(
                    'error',
                    'Error',
                    'Error in getting user profile data..',
                );
                console.error('Error fetching user data:', error);
            }
        };

        fetchUserData();
    }, [form]);

    const onFinish = async (values) => {
        try {
            let url = `/api/users/${
                JSON.parse(localStorage.getItem('user')).user.user_id
            }`;
            await axios.put(url, values);
            openNotificationWithIcon(
                'success',
                'Success',
                'Saved Profile sucessfully!',
            );
        } catch (error) {
            openNotificationWithIcon(
                'error',
                'Error',
                'Error in saving user profile!',
            );
        }
    };

    return (
        <Form
            form={form}
            onFinish={onFinish}
            initialValues={userData} // Initialize form with fetched data
        >
            {contextHolder}
            <Row justify="center" style={{ marginBottom: 24 }}>
                <Avatar size={128} icon={<UserOutlined />} />
            </Row>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col offset={1} xs={20} lg={10}>
                    <Form.Item
                        label="Username"
                        name="user_name"
                        plaintext 
                        rules={[{ required: true }]}
                    >
                        <Input prefix={<UserOutlined />} placeholder='Username' />
                    </Form.Item>
                    <Form.Item
                        label="Password"
                        name="password"
                        rules={[{ required: true }]}
                    >
                        <Input.Password  type='password' placeholder='Password'/>
                    </Form.Item>
                    <Form.Item
                        label="First Name"
                        name="user_first_name"
                        rules={[{ required: true }]} 
                    >
                        <Input placeholder='First Name' />
                    </Form.Item>
                    <Form.Item label="Middle Name" name="user_middle_name">
                        <Input placeholder='Middle Name' />
                    </Form.Item>
                    <Form.Item label="Last Name" name="user_last_name">
                        <Input placeholder='Last Name' />
                    </Form.Item>


                    <Form.Item label="Gender" name="user_gender">
                        <Select> 
                            <Option value="male">Male</Option>
                            <Option value="female">Female</Option>
                            <Option value="other">Other</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item label="Date of Birth" name="user_dob">
                        <DatePicker />
                    </Form.Item>
                    <Form.Item label="Mobile" name="user_mobile">
                        <Input placeholder='Mobile'/>
                    </Form.Item>

                    <Form.Item label="Email" name="user_email">
                        <Input placeholder='Email'/>
                    </Form.Item>
                    {/* <Form.Item label="Avatar">
                        <Form.Item name="avatar" noStyle>
                            <Upload
                                name="avatar"
                                listType="picture-card"
                                showUploadList={false}
                                beforeUpload={() => false}
                            >
                                <Button icon={<UploadOutlined />}>
                                    Upload
                                </Button>
                            </Upload>
                        </Form.Item>
                    </Form.Item> */}
                </Col>
                <Col offset={1} xs={20} lg={10}>
                    <Form.Item label="Building Name" name="user_building_name">
                        <Input placeholder='Building Name'/>
                    </Form.Item>
                    <Form.Item label="Street Name" name="user_street_name">
                        <Input placeholder='Street Name'/>
                    </Form.Item>
                    <Form.Item label="Landmark" name="user_landmark">
                        <Input placeholder='Landmark' />
                    </Form.Item>
                    <Form.Item label="Area Name" name="user_area_name">
                        <Input placeholder='Area Name'/>
                    </Form.Item>
                    <Form.Item label="Pincode" name="user_pincode">
                        <Input placeholder='Pin Code'/>
                    </Form.Item>
                </Col>
            </Row>

            <Row justify="center">
                <Col>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Save
                        </Button>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
};

export default UserProfilePage;
