import { Card } from 'antd';
import { Typography } from 'antd';
const { Title } = Typography;


export default function WelcomeMessage({todaysAppointments}) {
    const currentTime = new Date().getHours();
    let greeting;
    let doctorName = '';
    const storage = JSON.parse(localStorage.getItem('user'));
    if (
        storage &&
        storage.user &&
        storage.user.user_type_lookup_id &&
        parseInt(storage.user.user_type_lookup_id) === 1 &&
        storage.doctor &&
        storage.doctor.doctor_id
    ) {
        doctorName = storage.doctor.doctor_name;
    }

    if (currentTime >= 5 && currentTime < 12) {
        greeting = "Good morning";
    } else if (currentTime >= 12 && currentTime < 18) {
        greeting = "Good afternoon";
    } else {
        greeting = "Good evening";
    }

    return (
        <Card.Grid className='dashboard-card pad-tb10' style={{ backgroundColor: "rgb(240 236 255)"}}> 
            <div>
                <Title level={4}>{greeting}, {doctorName}!</Title>
                {todaysAppointments <= 0 ? <p style={{fontSize:'14px'}}>You do not have scheduled appointments for today, but you can consult the walk-in patients.</p> : <p>You have {todaysAppointments} appointments for today.</p>}
            </div>
        </Card.Grid> 
    );
}
