import React from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    thankyouContainer: {
        marginTop: 20,
        flexDirection: 'row', // Add this style to align items horizontally
        justifyContent: 'space-between',
        fontSize: 12,
    },
    leftColumn: {
        flex: 1, // Take up 50% of the available space
    },
    rightColumn: {
        flex: 1, // Take up 50% of the available space
        textAlign: 'right', // Align text to the right
    }
   
    // titleContainer:{
    //     flexDirection: 'row',
    //     marginTop: 12
    // },
    // reportTitle:{
    //     fontSize: 12,
    //     textAlign: 'center',
    //     // textTransform: 'uppercase',
    // }
  });


  const InvoiceThankYouMsg = () => (
    <>
        <View style={styles.thankyouContainer}>
            <View style={styles.leftColumn}>
                <Text style={styles.reportTitle}>Thank You!</Text>
            </View>
            <View style={styles.rightColumn}>
                <Text style={styles.reportTitle}>Signature</Text>
            </View>
        </View>
    </>
  );
  
  export default InvoiceThankYouMsg;