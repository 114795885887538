import React, { useState, useEffect } from 'react';
// import { useSearchParams } from 'react-router-dom';
// import ReactToPrint from 'react-to-print';
import { SaveOutlined, EyeOutlined, PrinterOutlined, LeftCircleOutlined, RightCircleOutlined, DownCircleOutlined, UpCircleOutlined  } from '@ant-design/icons';
import {
    Button,
    Typography,
    Form,
    Input,
    Row,
    Col,
    DatePicker,
    notification,
    Tabs,
    Modal
} from 'antd';


import axios from '../../services/axios';
import DoctorPrescriptions from './doctor-prescriptions';
import dayjs from 'dayjs';
// import Editor from '../editor/editor';
import PrescriptionDynamicForm from './prescription-form';
import PreviewPrescription from './preview-prescription';
import TagInput from '../tag-input';
import FollowupMedicineTable from './followup-medicine-table';
import './prescription-new.css';
const { Text, Title } = Typography;
const { TabPane } = Tabs;
const { TextArea} = Input;


export default function Prescription({ patient }) {
    

    // const previewComponentRef = React.useRef(); //print 
    const labelCol = {
        span: 4, // this means the label will take 4/24 of the row width
        offset: 0, // this means the label will have no offset from the left
        style: {
            width: '100px', // this means the label will have a fixed width of 100px
            textAlign: 'left',
        },
    };
    const [openPrescriptionModal, setOpenPrescriptionModal] = useState(false);
    const [refreshKey, setRefreshKey] = useState(0);
    const [api, contextHolder] = notification.useNotification();
    const [form] = Form.useForm();
    // const [queryParams] = useSearchParams();
    const episodeNumber = `${patient.patient_id}-${Date.now()}`;

    const [selectedMedicines, setSelectedMedicines] = useState([]); 
    const [selectedMedicineContents, setSelectedMedicineContents] = useState([]); //Holds contents of the medicine on the given index of medicine on dynamic form {ID: 1234, content: 'content1, content2'}
    //const [medicineContentsMap, setMedicineContentsMap] = useState({}); //Holds MedicinId and Content mapping 

    const [selectedPrescriptionRecord, setSelectedPrescriptionRecord] = useState({});


    const [savedComplaintsTags, setSavedComplaintsTags] = useState([]); //Complaints input cointents
    const [savedDiagnosisTags, setSavedDiagnosisTags] = useState([]); //Diagnosis input cointents
    const [savedTestsTags, setSavedTestsTags] = useState([]); //Diagnosis input cointents
    const [savedAdviseTags, setSavedAdviseTags] = useState([]); 
    const [savedHistory, setSavedHistory] = useState(null); 

    const [isVitalBtnDisabled, setIsVitalBtnDisabled] = useState(true);
    const [vitals, setVitals] = useState({}); //SET Vitals and variable is used in case of setting vitals as wellas settings follow ups
    const [vitalsModeOn, setVitalsModeOn] = useState(false);

    const [followupMedicines, setFollowupMedicines] = useState([]);

    const [isMedicineContainerVisible, setIsMedicineContainerVisible] = useState(false);


    //https://ant.design/components/notification#api
    const openNotificationWithIcon = (type, title, description) => {
        api[type]({
          message: title,
          description: description,
          placement: "top",
          duration: 2,
          role: "status",
          zIndexPopup: 9999999999
        });
    };

    useEffect(() => {
        axios.get(`/api/doctors/${patient.doctor_id}/vitals?patientId=${patient.patient_id}`)
        .then(function (response) {
            console.log('Vitals from api:', response, response.data.is_completed);
            // setIsVitalBtnDisabled(()=>{
            //     return false;
            // });
            if(response.data[0] && response.data[0].is_completed === 0){
                setIsVitalBtnDisabled(()=>{
                    return false;
                });
                response.data[0].next_visit_date = dayjs(response.data[0].next_visit_date, 'DD-MM-YYYY'); // Convert to dayjs object
                setVitals(response.data[0]);
                setVitalsModeOn(true);
                form.setFieldsValue(response.data[0]); 
                setSavedComplaintsTags(response.data[0].complaint);
                setSavedDiagnosisTags(response.data[0].diagnosis);
                setSavedTestsTags(response.data[0].tests);
                setSavedAdviseTags(response.data[0].advice);
                setSavedHistory(response.data[0].history);
            }
        })
        .catch(function (error) {
            console.log('Error while fetching vitals:', error);
            openNotificationWithIcon('error', 'Error', `OOPS! SOmething went wrong while retrieving the Vitals!.`);
        });
    }, [patient.patient_id]);

    const toggleFollowupMedicineContainerVisibility = () => {
        setIsMedicineContainerVisible(!isMedicineContainerVisible);
    };

    const resetTagInputs = () => {
        setSavedComplaintsTags([]);
        setSavedDiagnosisTags([]);
        setSavedTestsTags([]);
        setSavedAdviseTags([]);

        //Need to set to refresh all the tag input components, after setting the state the component will be refreshed on state change
        setVitals((prevVitals) => ({
            ...prevVitals,
            complaint: [],
            diagnosis: [],
            tests: [],
            advice: []
        }));
    };

    const handleComplaintsTagsChange = (tags) => {
        setSavedComplaintsTags(tags);
    };

    const handleDiagnosisTagsChange = (tags) => {
        setSavedDiagnosisTags(tags);
    };

    const handleTestsTagsChange = (tags) => {
        setSavedTestsTags(tags);
    };

    const handleAdviseTagsChange = (tags) => {
        setSavedAdviseTags(tags);
    };

    const handleHistoryChange = (e) => {
        //setSavedHistory(e.target.value);
       // const value = e.target.value.replace(/\n/g, "<br>");
        setSavedHistory(e.target.value);
    };

    const handleFollowUpConsultation = () => {
        axios.get(`/api/doctors/${patient.doctor_id}/prescriptions/followups?patientId=${patient.patient_id}`)
        .then(function (response) {
            console.log('Follow up details from api:', response.data);
            if(response.data && response.data.length){
                response.data[0].next_visit_date = dayjs(response.data[0].next_visit_date, 'DD-MM-YYYY'); // Convert to dayjs object
                setFollowupMedicines(response.data[0].medicines);

                //Delete the not required fields...from the response...
                delete response.data[0].vital_bp;
                delete response.data[0].vital_heart_rate;
                delete response.data[0].vital_height;
                delete response.data[0].vital_spo2;
                delete response.data[0].vital_temperature;
                delete response.data[0].vital_weight;
                delete response.data[0].medicines;
                delete response.data[0].next_visit_date;
                delete response.data[0].referenced_doctor;
                delete response.data[0].doctor_address;
                delete response.data[0].prescription_id;

                if(response.data[0].is_completed === false ||  response.data[0].is_completed === true){
                    delete response.data[0].is_completed;
                }
                
                //If vitals are saved earlier and now Dr is precscribing the actual medicines then do not load from the earlier prescriptions
                if(!vitalsModeOn){
                    setVitals(response.data[0]); //NOTE: Setting vitals as vitals object is passwed as value to tags and other components
                    //setVitals(response.data[0]);
                    form.setFieldsValue(response.data[0]); 
                    //form.setFields({ history:response.data[0].history }); //TO set particular value
                    setSavedComplaintsTags(response.data[0].complaint);
                    setSavedDiagnosisTags(response.data[0].diagnosis);
                    setSavedTestsTags(response.data[0].tests);
                    setSavedAdviseTags(response.data[0].advice);
                    setSavedHistory(response.data[0].history);
                }
            } else {
                openNotificationWithIcon('info', 'Info', `Patient is visiting for the first time, No earlier prescriptions Saved!.`);
            }
        })
        .catch(function (error) {
            console.log('Error while fetching vitals:', error);
            openNotificationWithIcon('error', 'Error', `OOPS! SOmething went wrong while retrieving the Vitals!.`);
        });
    };

    function handleOnFinish(values) {
        if(values.next_visit_date && dayjs(values.next_visit_date).isValid()){
            values.next_visit_date = new Date(values.next_visit_date);
            values.next_visit_date = `${values.next_visit_date.getFullYear()}-${values.next_visit_date.getMonth()+1}-${values.next_visit_date.getDate()}`
        } else {
            values.next_visit_date = null;
        }

        if(vitals.prescription_id || (values.medicines && values.medicines.length > 0)){
            values.is_completed = true;
            values.prescription_id = vitals.prescription_id;
        }

        //get medicines from the selected medicines contents
        let medicine = [];
        if(values.medicines){
            for(let i=0; i<values.medicines.length;i++){
                medicine = selectedMedicineContents.filter((medicine)=>{
                    return values.medicines[i].medicine_id === medicine.Id;
                });
                values.medicines[i]['name'] = medicine[0].name;
                values.medicines[i]['Id'] = medicine[0].Id;
                values.medicines[i]['salt_composition'] = medicine[0].salt_composition;
                values.medicines[i]['product_form'] = medicine[0]['Product Form'];
                console.log('values.medicines[i]:', values.medicines[i]); //
            }
            console.log('values.medicines:', values.medicines);
        }
        
        //let payload = {...values, episode_number: episodeNumber, patient_id: patient.patient_id, doctor_id: patient.doctor_id}; const [savedHistory, setSavedHistory] = useState(null); 
        let payload = {...values, episode_number: episodeNumber, patient_id: patient.patient_id, doctor_id: patient.doctor_id, complaint: savedComplaintsTags, history: savedHistory, diagnosis: savedDiagnosisTags, tests: savedTestsTags, advice: savedAdviseTags };
        
        axios.post(`/api/doctors/${patient.doctor_id}/prescriptions`, payload)
        .then(function () {
            let sucessMessage = (values && values.medicines && values.medicines.length ) ? 'Prescription has been saved sucessfully!': `Vitals are saved sucessfully!.`;
            form.resetFields();
            resetTagInputs();
            values.medicines = [];
            setRefreshKey((previousKey)=> previousKey + 1);
            openNotificationWithIcon('success', 'Success', sucessMessage);
        })
        .catch(function (error) {
            let sucessMessage = (values && values.medicines && values.medicines.length)  ? 'OOPS! SOmething went wrong while saving the Prescription!': `OOPS! SOmething went wrong while saving the Vitals!.`;
            form.resetFields();
            resetTagInputs();
            values.medicines = [];
            console.log('Error in saving the prescription error:', error);
            openNotificationWithIcon('error', 'Error', sucessMessage);
        });
    }


    const disabledDate = (current) => {
        // Can not select days before today and today
        return current && current <= dayjs().endOf('day');
    };

    function updateRefreshKey(){
        setRefreshKey((previousKey)=> previousKey + 1);
    }

    function getMedicineById(selectedMedicineIndex, medicineId){
        axios.get(`/api/cache/${medicineId}`)
        .then(function (medicine) {
            // let newMedicineContentsMap = {...medicineContentsMap};
            // newMedicineContentsMap[medicineId] = medicine.salt_composition;
            // setMedicineContentsMap(newMedicineContentsMap);
            const updatedSelectedMedicineContents = [...selectedMedicineContents];
            updatedSelectedMedicineContents[selectedMedicineIndex] = medicine.data;
            setSelectedMedicineContents(updatedSelectedMedicineContents); 
            console.log('selected medicine index:', selectedMedicineIndex);
            console.log('selected medicine contents:', medicine);
        })
        .catch(function (error) {
            console.log(`Error in getting medicine by Id:`);
            console.log(error);
        });
    }

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
        form.resetFields();
    };

    const onPreviewClick = function(){      
        setOpenPrescriptionModal(true);  
        console.log('form.values:', form.values);
        form.values = form.values = form.getFieldsValue();
        //const [selectedPrescriptionRecord, setSelectedPrescriptionRecord] = useState({});
        console.log('VALUES', form.values);
        if( form.values.next_visit_date){
             form.values.next_visit_date = new Date( form.values.next_visit_date);
             form.values.next_visit_date = `${ form.values.next_visit_date.getFullYear()}-${ form.values.next_visit_date.getMonth()+1}-${ form.values.next_visit_date.getDate()}`
        }

        //get medicines from the selected medicines contents
        let medicine = [];
        if(form && form.values && form.values.medicines){
            for(let i=0; i< form.values.medicines.length;i++){
                medicine = selectedMedicineContents.filter((medicine)=>{
                    return  form.values.medicines[i].medicine_id === medicine.Id;
                });
                 form.values.medicines[i]['name'] = medicine[0].name;
                 form.values.medicines[i]['Id'] = medicine[0].Id;
                 form.values.medicines[i]['salt_composition'] = medicine[0].salt_composition;
                 form.values.medicines[i]['product_form'] = medicine[0]['Product Form'];
                console.log(' form.values.medicines[i]:',  form.values.medicines[i]); //
            }
        }

        console.log(' form.values.medicines:',  form.values.medicines);

        //let payload = {...form.values, episode_number: episodeNumber, patient_id: patient.patient_id, doctor_id: patient.doctor_id};

        //let payload = {...form.values, episode_number: episodeNumber, patient_id: patient.patient_id, doctor_id: patient.doctor_id, patient_first_name: patient.patient_first_name, patient_middle_name: patient.patient_middle_name, patient_last_name: patient.patient_last_name, first_name: patient.first_name, middle_name: patient.middle_name, last_name: patient.last_name, patient_address: patient.patient_address, patient_gender: patient.patient_gender, prescription_date: "Todays date", registration_number: patient.registration_number, history: savedHistory, complaint: savedComplaintsTags, diagnosis: savedDiagnosisTags, tests: savedTestsTags, advice: savedAdviseTags};
        console.log('Patient:', patient);
        let payload = {...form.values, episode_number: episodeNumber, patient_id: patient.patient_id, doctor_id: patient.doctor_id, first_name: patient.doctor, registration_number: patient.registration_number, qualification: patient.qualification, patient_first_name: patient.patient_name, patient_name: patient.patient_last_name, patient_gender: patient.gender, middle_name: patient.middle_name, last_name: patient.last_name, patient_address: patient.patient_address, prescription_date: patient.timeSlotDate,  history: savedHistory, complaint: savedComplaintsTags, diagnosis: savedDiagnosisTags, tests: savedTestsTags, advice: savedAdviseTags};
        setSelectedPrescriptionRecord(payload);
    };

    const handleMedicineSelect = (index, value) => {
        console.log(`Selected medicine at index ${index}: ${value}`);
        console.log('selectedMedicines:', selectedMedicines);
        console.log('setSelectedMedicines:', setSelectedMedicines);
        console.log('selectedMedicines:', selectedMedicineContents);
        // You can maintain an array of selected medicines in the parent component's state
        // For example, using useState hook:
        const updatedMedicines = [...selectedMedicines]; // Assuming selectedMedicines is a state variable
        updatedMedicines[index] = value;
        setSelectedMedicines(updatedMedicines); // Update the state with the new array of selected medicines
        getMedicineById(index, value);
    };

    // const handlePrint = () => {
    //     window.print();
    // };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && e.target.tagName !== "TEXTAREA") {
          e.preventDefault();
        }
    };

    //get dr name by aappending Dr 
    function getDrName(drName){
        let doctorName = '';
        if(drName && drName.toLowerCase().indexOf('dr.'.toLowerCase()) >=0){
            doctorName =  `${drName}`;
        } else {
            doctorName =  `Dr. ${drName}`;   
        }
        return doctorName;
    }

    return (
        <div className="pad-tb10">
            {contextHolder}
            <Form form={form}
                layout="horizontal"
                onFinish={handleOnFinish}
                onFinishFailed={onFinishFailed}
                onKeyDown={handleKeyDown}
            >
                <Row
                    gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                    className="prescription-details"
                >
                    <Col xs={12} lg={6} style={{paddingLeft: '24px'}}>
                        <div style={{ marginBottom: '5px' }}>
                            <Text
                                style={{
                                    display: 'inline-block',
                                    float: 'left',
                                    paddingRight: '5px',
                                    fontWeight: '500',
                                }}
                            >
                                Episode No:
                            </Text>
                            <Text>{episodeNumber}</Text>
                        </div>
                        <div style={{ marginBottom: '5px' }}>
                            <Text
                                style={{
                                    display: 'inline-block',
                                    float: 'left',
                                    paddingRight: '5px',
                                    fontWeight: '500',
                                }}
                            >
                                Patient Id:
                            </Text>
                            <Text>{patient.patient_id}</Text>
                        </div>

                        <div style={{ marginBottom: '5px' }}>
                            <Text
                                style={{
                                    display: 'inline-block',
                                    float: 'left',
                                    paddingRight: '5px',
                                    fontWeight: '500',
                                }}
                            >
                                Patient Name:
                            </Text>
                            <Text>{patient.patient_name}</Text>
                        </div>

                        {/* <div style={{ marginBottom: '5px' }}>
                            <Text
                                style={{
                                    display: 'inline-block',
                                    float: 'left',
                                    paddingRight: '5px',
                                    fontWeight: '500',
                                }}
                            >
                                Patient Address:
                            </Text>
                            <Text>
                                Robert Robertson, 1234 NW Bobcat Lane, St.
                                Robert, MO 65584-5678a
                            </Text>
                        </div> */}
                    </Col>

                    <Col xs={12} offset={1} lg={7}>
                        {/* <div style={{ marginBottom: '5px' }}>
                            <Text
                                style={{
                                    display: 'inline-block',
                                    float: 'left',
                                    paddingRight: '5px',
                                    fontWeight: '500',
                                }}
                            >
                                Patient Name:
                            </Text>
                            <Text>
                                {patient?.patient_name}{' '}
                                {patient?.patient_middle_name}{' '}
                                {patient?.patient_last_name}
                            </Text>
                        </div>

                        <div style={{ marginBottom: '5px' }}>
                            <Text
                                style={{
                                    display: 'inline-block',
                                    float: 'left',
                                    paddingRight: '5px',
                                    fontWeight: '500',
                                }}
                            >
                                Patient Address:
                            </Text>
                            <Text>
                                Robert Robertson, 1234 NW Bobcat Lane, St.
                                Robert, MO 65584-5678a
                            </Text>
                        </div> */}
                    </Col>

                    <Col xs={12} lg={7}>
                        <div style={{ marginBottom: '5px' }}>
                            <Text
                                style={{
                                    paddingRight: '5px',
                                    fontWeight: '500',
                                }}
                            >
                                Doctor:
                            </Text>

                            <Text>{getDrName(patient?.doctor)}, &nbsp;</Text>
                            {/* <Text>{patient?.doctor}, &nbsp;</Text> */}
                            <Text
                                style={{
                                    display: 'inline-block',
                                    fontStyle: 'italic',
                                }}
                            >
                                &nbsp;{patient?.qualification}
                            </Text>
                        </div>
                    </Col>
                </Row>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="mb5" style={{marginLeft: "5px"}}>
                    <Col xs={12}  lg={24} style={{paddingLeft:'4'}}>
                        {/* <Tabs type="card" defaultActiveKey="1" items={items} onChange={onTabChange} className='pad-tb10'/> */}
                        <Tabs defaultActiveKey="1" size="medium" className='prescription-tab'>
                            <TabPane tab="Vitals" key="1">
                                <Row
                                    gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                                    className="mb5"
                                >
                                    <Col xs={12} lg={4}>
                                        <Form.Item label="BP" name="vital_bp">
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={12} lg={4}>
                                        <Form.Item label="Pulse" name="vital_heart_rate">
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={12} lg={4}>
                                        <Form.Item label="Height" name="vital_height">
                                            <Input />
                                        </Form.Item>
                                    </Col>

                                    <Col xs={12} lg={4}>
                                        <Form.Item label="Weight" name="vital_weight">
                                            <Input />
                                        </Form.Item>
                                    </Col>

                                    <Col xs={12} lg={4}>
                                        <Form.Item
                                            label="Temp."
                                            name="vital_temperature"
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>

                                    <Col xs={12}  lg={4}>
                                        <Form.Item label="Spo2" name="vital_spo2">
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </TabPane>
                            <TabPane tab="History" key="2">
                                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                    <Col xs={20} lg={20}>
                                        <Form.Item
                                            label=""
                                            labelCol={{ ...labelCol }}
                                            name="history"
                                            className="prescription-label"
                                        >
                                            
                                            {/* <Editor
                                                data={{ message: 'Shard' }}
                                                id="history_editor"
                                                onChange={function (data) {
                                                    console.log(
                                                        'datachanged....',
                                                        data,
                                                    );
                                                }}
                                            ></Editor> */}
                                             <TextArea rows={5} placeholder="Type history here" value={savedHistory} onChange={handleHistoryChange} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </TabPane>
                            <TabPane tab="Complaints" key="3">
                                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                    <Col xs={20} lg={20}>
                                        <Form.Item
                                            label=""
                                            labelCol={{ ...labelCol }}
                                            name="complaint"
                                            className="prescription-label"
                                        >
                                            {/* <Input /> */}
                                            {/* <Editor
                                                data={{ message: 'Shard' }}
                                                id="complaints_editor"
                                                onChange={function (data) {
                                                    console.log(
                                                        'datachanged....',
                                                        data,
                                                    );
                                                }}
                                            ></Editor> */}
                                            <div>
                                                <TagInput size="large" onTagsChange={handleComplaintsTagsChange} values={vitals.complaint} type="complaints"/>
                                            </div>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </TabPane>
                            <TabPane tab="Diagnosis" key="4">
                                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                    <Col xs={20} lg={20}> 
                                        <Form.Item
                                            label=""
                                            name="diagnosis"
                                            labelCol={{ ...labelCol }}
                                        >
                                            {/* <Editor
                                                data={{ message: 'Shard' }}
                                                id="diagnosis_editor"
                                                onChange={function (data) {
                                                    console.log(
                                                        'datachanged....',
                                                        data,
                                                    );
                                                }}
                                            ></Editor> */}

                                            <div>
                                                <TagInput size="large" onTagsChange={handleDiagnosisTagsChange} values={vitals.diagnosis} type="diagnosis"/>
                                            </div>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </TabPane>
                            <TabPane tab="Medicines" key="5">
                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                <Col xs={12}  lg={24}>
                                    <PrescriptionDynamicForm 
                                    onMedicineSelect={handleMedicineSelect} 
                                    selectedMedicines={selectedMedicines} 
                                    setSelectedMedicines={setSelectedMedicines} 
                                    selectedMedicineContents={selectedMedicineContents}
                                    />
                                </Col>
                            </Row>
                            </TabPane>
                            <TabPane tab="Tests" key="6">
                                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                    <Col xs={20} lg={20}>
                                        <Form.Item
                                            label=""
                                            labelCol={{ ...labelCol }}
                                            name="tests"
                                        >
                                            {/* <Editor
                                                data={{ message: 'Shard' }}
                                                id="tests_editor"
                                                onChange={function (data) {
                                                    console.log(
                                                        'datachanged....',
                                                        data,
                                                    );
                                                }}
                                            ></Editor> */}
                                            <div>
                                                <TagInput size="large" onTagsChange={handleTestsTagsChange} values={vitals.tests} type="tests"/>
                                            </div>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </TabPane>
                            <TabPane tab="Advise" key="7">
                                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                    <Col xs={20} lg={20}>
                                        <Form.Item
                                            label=""
                                            labelCol={{ ...labelCol }}
                                            name="advice"
                                        >
                                            {/* <Editor
                                                data={{ message: 'Shard' }}
                                                id="advise_editor"
                                                onChange={function (data) {
                                                    console.log(
                                                        'datachanged....',
                                                        data,
                                                    );
                                                }}
                                            ></Editor> */}

                                            <div>
                                                <TagInput size="large" onTagsChange={handleAdviseTagsChange} values={vitals.advice} type="advice"/>
                                            </div>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </TabPane>
                            <TabPane tab="Note" key="8">
                                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                    <Col xs={6} lg={6}>
                                        <Form.Item
                                            label="Next Visit"
                                            labelCol={{
                                                span: 9, // this means the label will take 4/24 of the row width
                                                offset: 0, // this means the label will have no offset from the left
                                                style: {
                                                    //width: '100px', // this means the label will have a fixed width of 100px
                                                    textAlign: 'left',
                                                },
                                            }}
                                            name="next_visit_date"
                                        >
                                            <DatePicker
                                                format="MM-DD-YYYY"
                                                disabledDate={disabledDate}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={11} lg={8}>
                                        <Form.Item
                                            label="Referred To"
                                            labelCol={{
                                                span: 7, // this means the label will take 4/24 of the row width
                                                offset: 0, // this means the label will have no offset from the left
                                                style: {
                                                    //width: '100px', // this means the label will have a fixed width of 100px
                                                    textAlign: 'left',
                                                },
                                            }}
                                            name="referenced_doctor"
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>

                                    <Col xs={11} lg={10}>
                                        <Form.Item
                                            label="Ref. Dr Address"
                                            labelCol={{
                                                span: 7, // this means the label will take 4/24 of the row width
                                                offset: 0, // this means the label will have no offset from the left
                                                style: {
                                                    //width: '100px', // this means the label will have a fixed width of 100px
                                                    textAlign: 'left',
                                                },
                                            }}
                                            name="doctor_address"
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </TabPane>
                        </Tabs>
                    </Col>
                </Row>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                        <Col xs={24} offset={15} lg={9} style={{display: 'flex', justifyContent: 'end'}}> 
                            <Form.Item labelCol={{ ...labelCol }}>
                                <Button
                                    type="primary"
                                    danger
                                    icon={<SaveOutlined />}
                                    style={{ marginRight: '5px' }}
                                    disabled={isVitalBtnDisabled}
                                    onClick={ ()=>{form.resetFields(); resetTagInputs();}}
                                >
                                    Reset
                                </Button>
                                <Button
                                    type="primary"
                                    icon={<SaveOutlined />}
                                    htmlType="submit"
                                    style={{ marginRight: '5px' }}
                                >
                                    Save
                                </Button>
                                <Button onClick={ onPreviewClick}
                                    type="primary"
                                    icon={<EyeOutlined />}
                                    style={{ marginRight: '5px'}}
                                >
                                    {' '}
                                    Preview{' '}
                                </Button>
                                <Button onClick={ handleFollowUpConsultation}
                                    type="primary"
                                    icon={<EyeOutlined />}
                                    style={{ marginRight: '5px', backgroundColor: "green"}}
                                >
                                   Follow Up
                                </Button>
                                <Button 
                                    type="primary"
                                    icon={<PrinterOutlined />}
                                    style={{ marginRight: '5px', display:"none"  }}
                                >
                                    {' '}
                                    Print{' '}
                                </Button>
                            </Form.Item>
                        </Col>
                </Row>

                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col xs={24} lg={24} style={{ position: 'relative', paddingLeft:'12px', paddingRight:'12px' }}>
                       <div style={{ display: 'flex', justifyContent: 'space-between', paddingRight: '5px'}}>
                        <h3 style={{marginLeft: "12px"}}>Previous Prescriptions</h3>
                        {followupMedicines.length > 0 &&
                            <div className='medicines-slide-btn'>
                                <Button   
                                type="Primary" 
                                icon={isMedicineContainerVisible ? <UpCircleOutlined /> : <DownCircleOutlined />  }
                                style={{ marginRight: '5px', backgroundColor: "purple", color: "white"}}
                                onClick={toggleFollowupMedicineContainerVisibility}></Button>
                            </div>
                        }
                        </div>
                        <DoctorPrescriptions patient={patient} refreshKey={refreshKey} setRefreshKey={updateRefreshKey}></DoctorPrescriptions>
                            <div
                                className='followup-medicine-container'
                                style={{ display: isMedicineContainerVisible ? 'block' : 'none' }}
                            >
                                <Title level={5}>Previous Medicines</Title>
                                <div className='followup-medicine-table-container'>
                                    {vitals && Object.keys(vitals).length > 0 ? 
                                        <FollowupMedicineTable tableData={followupMedicines}></FollowupMedicineTable>
                                    : null}
                                </div>
                            </div>
                    </Col>
                    <Col xs={4} lg={1}>
                        {/* {followupMedicines.length > 0 &&
                            <div className='medicines-slide-btn'>
                                <Button   
                                type="Primary" 
                                icon={isMedicineContainerVisible ? <UpCircleOutlined /> : <DownCircleOutlined />  }
                                style={{ marginRight: '5px', backgroundColor: "purple", color: "white"}}
                                onClick={toggleFollowupMedicineContainerVisibility}></Button>
                            </div>
                        } */}
                    </Col>
                    
                </Row>
            </Form>

            <Modal
                title="Preview Prescription"
                centered="true"
                open={openPrescriptionModal}
                okText="Preview"
                // okButtonProps={{ icon: <ScheduleOutlined /> }}
                // cancelButtonProps={{
                //     danger: 'danger',
                //     icon: <MdFreeCancellation />,
                // }}
                mask={true}
                destroyOnClose={true}
                maskClosable={false}
                // onOk={() => {
                    
                // }}
                onCancel={() => {
                    console.log("typeof onCancel", typeof onCancel);
                    // onCancel();
                    setOpenPrescriptionModal(false);
                }}
                width={'1300px'}
                footer={null}
            >
                <PreviewPrescription prescriptionDetails={selectedPrescriptionRecord}></PreviewPrescription>
            </Modal>

            
            {/* this code is to generate the print but it is not working as we have to wrap the PreviewPrescription component  nside forwardRef */}
            {/* <ReactToPrint
                trigger={() => <Button onClick={ handlePrint} type="primary" icon={<EyeOutlined />} style={{ marginRight: '5px'}}> Print Prescription</Button>}
                content={() => previewComponentRef.current}
            />
            {Object.keys(selectedPrescriptionRecord).length > 0 && (
                 <div style={{ display: 'none' }}>
                 <PreviewPrescription
                     ref={previewComponentRef}
                     prescriptionDetails={selectedPrescriptionRecord}
                 />
             </div> 
            )} */}
        </div>
    );
}
