import React, {useEffect, useState} from 'react';
import { Chart } from 'react-google-charts';
import axios from '../../services/axios';
export default function PatientLastVisits({title}) {
    const doctorId = JSON.parse(localStorage.getItem('user')).doctor.doctor_id;
    const [data, setData] = useState([]);

    useEffect(() => {
        axios
            .get(`/api/doctors/${doctorId}/dashboard/patientvisits`)
            .then((response) => {
                if (response && response.data) {
                    setData(response.data); // Set all appointments
                }
            })
            .catch((error) => {
                console.log('Error in Getting next appointments:', error);
            });
    }, [doctorId]);

    const options = {
        chart: {
            title: 'Patient Visits',
            subtitle: 'Date, Patient Count'
        },
    };

    return (
        <Chart
            chartType="Bar"
            width="100%"
            height="350px"
            data={data}
            options={options}
        />
    );
}
