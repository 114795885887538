import React, { useState, useEffect } from 'react';
import html2pdf from 'html2pdf.js';
import './preview-prescription.css';
const PreviewPrescription = ({ prescriptionDetails }) => {
    const [showPreview, setShowPreview] = useState(false);
    console.log('Sharad prescription:', prescriptionDetails);
    const [pdfPreview, setPdfPreview] = useState('');
    const [prescription, setPrescription] = useState(prescriptionDetails);

    useEffect(() => {
        // Update the prescription state when the prescriptionDetails prop changes
        if (prescriptionDetails !== prescription) {
            setPrescription(prescriptionDetails);
        }
    }, [prescriptionDetails, prescription]);

    const generatePDF = async () => {
        // const toDataUrl = async function (url, callback) {
        //     //Convert to base64
        //     return new Promise((resolve, reject) => {
        //         var xhr = new XMLHttpRequest();
        //         xhr.onload = function () {
        //             var reader = new FileReader();
        //             reader.onloadend = function () {
        //                 resolve(reader.result);
        //             };
        //             reader.readAsDataURL(xhr.response);
        //         };
        //         xhr.onerror = () => {
        //             reject({
        //                 status: this.status,
        //                 statusText: xhr.statusText,
        //             });
        //         };
        //         xhr.open('GET', url);
        //         xhr.responseType = 'blob';
        //         xhr.send();
        //     });
        // };

        // var element = document.getElementById('canvas_div_pdf');
        let element = document.getElementById('element-to-print');
        // clone the element
        let clonedElement = element.cloneNode(true);

        element.style.display = showPreview ? 'block' : 'none';
        // change display of cloned element
        clonedElement.style.display = 'block';

        if (
            JSON.parse(localStorage.getItem('user'))?.user
                ?.show_prescription_header === parseInt(0)
        ) {
            //Hide header from the print due to letter head
            clonedElement.querySelector('.header').style.display = 'none';
            //Hide the <HR> Line
            clonedElement.querySelector('hr').style.display = 'none';
        }

        //Hide preview button from HTML Preview
        let previewBtn = clonedElement.querySelectorAll('.footer-container');
        previewBtn[0].style.display = 'none';

        // remove cloned element
        clonedElement.remove();

        //let pageMargins =  [10, 15, 15, 15];

        //Hospital has letter pad there prescription will be printed...in ths case show header will be false from DB
        // if(JSON.parse(localStorage.getItem('user'))?.user?.show_prescription_header === parseInt(0)){
        //     // DO not show header from our side, Hospital will keep the letter had which has the header, so we just have to keep some margin from top so that it is not overriten on the letter head
        //     // //Get margins from local storage
        //     pageMargins = JSON.parse(localStorage.getItem('user'))?.user?.prescription_header_margins;
        //     pageMargins = pageMargins.split(',');
        // }
        // element.style.display = "none";
        //UnComment this code in react
        // let image = document.getElementById('header-logo');
        // let imageSrc = image.src;
        // let urlBase64 = await toDataUrl(imageSrc);
        // imageSrc.src= urlBase64;

        const pdf = await html2pdf()
            .from(clonedElement)
            .set({
                //margin: [3, 15, 15, 15], //Not used
                // margin: [10, 15, 15, 15], //Original
                //margin: [90, 35, 70, 15], //Only for OM Hospital
                margin:
                    JSON.parse(localStorage.getItem('user'))?.user
                        ?.show_prescription_header === parseInt(0)
                        ? JSON.parse(
                              JSON.parse(localStorage.getItem('user'))?.user
                                  ?.prescription_header_margins,
                          )
                        : [10, 15, 15, 15],
                filename: 'prescription.pdf',
                image: { type: 'jpeg', quality: 0.98 },
                html2canvas: {
                    scale: 2,
                    logging: true,
                    dpi: 192,
                    letterRendering: true,
                },
                jsPDF: { unit: 'mm', format: 'a4', orientation: 'p' },
                pagebreak: {
                    avoid: ['tr', 'img', 'li', 'ul', 'h2', 'h3', 'div'],
                },
            })
            .toPdf()
            .get('pdf');
        // .then(function (pdf) {
        //     let totalPages = pdf.internal.getNumberOfPages();
        //     for (let i = 1; i <= totalPages; i++) {
        //         pdf.setPage(i);
        //         pdf.setFontSize(8);
        //         pdf.setTextColor(150);
        //         pdf.text(
        //             'Page ' + i + ' of ' + totalPages + '',
        //             pdf.internal.pageSize.getWidth() - 30,
        //             pdf.internal.pageSize.getHeight() - 8,
        //         );
        //     }

        //     // Set the PDF data for preview
        //     // const pdfData = pdf.output('datauristring');
        //     // setPdfPreview(pdfData);
        // });
        let totalPages = pdf.internal.getNumberOfPages();
        for (let i = 1; i <= totalPages; i++) {
            pdf.setPage(i);
            pdf.setFontSize(8);
            pdf.setTextColor(150);
            pdf.text(
                'Page ' + i + ' of ' + totalPages + '',
                pdf.internal.pageSize.getWidth() - 30,
                pdf.internal.pageSize.getHeight() - 8,
            );
        }

        const pdfData = pdf.output('datauristring');
        setPdfPreview(pdfData);

        // Move setShowPreview(true) here
        setShowPreview(true);

        //Old code
        // html2pdf()
        //     .from(clonedElement)
        //     .set({
        //         margin: [3, 15, 15, 15],
        //         filename: 'prescription.pdf',
        //         image: { type: 'jpeg', quality: 0.98 },
        //         html2canvas: {
        //             scale: 2,
        //             logging: true,
        //             dpi: 192,
        //             letterRendering: true,
        //         },
        //         jsPDF: { unit: 'mm', format: 'a4', orientation: 'p' },
        //         pagebreak: { avoid: ['tr', 'img'] },
        //     })
        //     .toPdf()
        //     .get('pdf')
        //     .then(function (pdf) {
        //         var totalPages = pdf.internal.getNumberOfPages();

        //         for (let i = 1; i <= totalPages; i++) {
        //             pdf.setPage(i);
        //             pdf.setFontSize(8);
        //             pdf.setTextColor(150);
        //             pdf.text(
        //                 'Page ' + i + ' of ' + totalPages + '',
        //                 pdf.internal.pageSize.getWidth() - 30,
        //                 pdf.internal.pageSize.getHeight() - 8,
        //             );
        //         }

        //         // Set the PDF data for preview
        //         const pdfData = pdf.output('datauristring');
        //         setPdfPreview(pdfData);
        //     });
    };

    const handlePreviewClick = () => {
        // Update the prescription state before generating the PDF
        setPrescription(prescriptionDetails);
        generatePDF();
        // setShowPreview(true);
    };

    // <th key="prescription-table-header-column-drug">Drug</th>
    //                             <th key="prescription-table-header-column-drug">Contents</th>
    //                             <th key="prescription-table-header-column-dosage">Dosage</th>
    //                             <th key="prescription-table-header-column-frequency">Frequency</th>
    //                             <th key="prescription-table-header-column-duration">Duration</th>
    //                             <th key="prescription-table-header-column-note">Note</th>
    function renderMedications() {
        console.log('prescription: sharad:', prescription);
        if (
            prescription &&
            prescription.medicines &&
            prescription.medicines.length > 0
        ) {
            let medications = prescription.medicines.map(
                function (medication, index) {
                    return (
                        <tr className="page-break" id={index}>
                            <td className="font-popins font-size-12">
                                <div>
                                    <span
                                        style={{ textTransform: 'uppercase' }}
                                        className="font-size-12"
                                    >
                                        {medication.medicine_name ||
                                            medication.name}{' '}
                                        <span
                                            style={{
                                                textTransform: 'uppercase',
                                            }}
                                            className="font-size-11"
                                        >
                                            (
                                            {medication.medicine_contents ||
                                                medication.salt_composition}
                                            )
                                        </span>
                                    </span>
                                </div>
                            </td>
                            {/* <div><span style={{textTransform: "uppercase"}} className='font-size-12'>({medication.medicine_contents || medication.salt_composition})</span></div></td> */}
                            {/* <td>{medication.medicine_contents || medication.salt_composition}</td> */}
                            <td>{medication.volume}</td>
                            <td>{medication.product_form}</td>
                            <td>{medication.dose}</td>
                            <td>{medication.frequency}</td>
                            <td>{medication.when_to_take}</td>
                            <td>{medication.duration}</td>
                            {/* <td>{medication.special_note}</td> */}
                        </tr>
                    );
                },
            );
            return medications;
        } else {
            return <p>No medications found for this prescription.</p>;
        }
    }

    // function renderHistory(){
    //     let medications = <p></p>;
    //     if(prescription && prescription.history && prescription.history.blocks && Array.isArray(prescription.history.blocks)){
    //         prescription.history.blocks.forEach(function(data, index) {
    //             if(data.type === 'list' && Array.isArray(data.data.items)){
    //                 medications = data.data.items.map((item)=>{
    //                     return  <li key={`history-prescritpion-${index}`}>{item}</li>
    //                 });
    //             }
    //         });
    //     }
    //     return medications;
    // }

    function renderHistory() {
        let medications = <p></p>;
        if (prescription && prescription.history) {
            // return <li key={`complaints-prescritpion-1`}>{prescription.history}</li>
            return <span>{prescription.history}</span>;
        }

        return medications;
    }

    function renderComplaints() {
        let medications = <p></p>;
        if (prescription && prescription.complaint) {
            medications = prescription.complaint.map(function (item, index) {
                return <li key={`complaints-prescritpion-${index}`}>{item}</li>;
            });
        }

        return medications;
    }

    function renderDiagnosis() {
        let medications = <p></p>;

        if (prescription && prescription.diagnosis) {
            medications = prescription.diagnosis.map(function (item, index) {
                return <li key={`diagnosis-prescritpion-${index}`}>{item}</li>;
            });
        }

        return medications;
    }

    function renderTests() {
        let medications = <p></p>;

        if (prescription && prescription.tests) {
            medications = prescription.tests.map(function (item, index) {
                return <li key={`tests-prescritpion-${index}`}>{item}</li>;
            });
        }

        return medications;
    }

    function renderAdvice() {
        let medications = <p></p>;

        if (prescription && prescription.tests) {
            medications = prescription.advice.map(function (item, index) {
                return <li key={`advice-prescritpion-${index}`}>{item}</li>;
            });
        }

        return medications;
    }
    function getHospitalName() {
        let hospitalName = '';
        if (localStorage.getItem('user')) {
            hospitalName = JSON.parse(localStorage.getItem('user')).user
                .company_name;
        }
        return hospitalName;
    }

    function getHospitalSlogan() {
        let hospitalSlogan = '';
        if (localStorage.getItem('user')) {
            hospitalSlogan = JSON.parse(localStorage.getItem('user')).user
                .company_slogan;
        }
        return hospitalSlogan;
    }

    function getDrName() {
        let doctorName = 'Referral Dr.';
        if (
            prescription.first_name &&
            prescription.first_name
                .toLowerCase()
                .indexOf('dr.'.toLowerCase()) >= 0
        ) {
            if (prescription.last_name) {
                doctorName = `${prescription.first_name} ${prescription.last_name}`;
            } else {
                doctorName = `${prescription.first_name}`;
            }
        } else {
            if (prescription.last_name) {
                doctorName = `Dr. ${prescription.first_name} ${prescription.last_name}`;
            } else {
                doctorName = `Dr. ${prescription.first_name}`;
            }
        }
        return doctorName;
    }

    const getTodaysDate = function (date) {
        let d = new Date();
        if (date) {
            d = new Date(date);
        }

        let month = '' + (d.getMonth() + 1);
        let day = '' + d.getDate();
        let year = d.getFullYear();

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;

        return [day, month, year].join('-');
    };

    return (
        <>
            <style>
                {`
            @import url('https://fonts.googleapis.com/css?family=Poppins:wght@300;400;600;700;800;900&display=swap');

            .App {
                font-family: sans-serif;
            }

            * {
                box-sizing: border-box;
            }

            body {
                margin: 0;
                font-family: Arial, Helvetica, sans-serif;
            }

            .header {
                overflow: hidden;
                background-color: #f1f1f1;
                padding: 20px 20px, 10px 20px;
            }

            .header a {
                float: left;
                color: black;
                text-align: center;
                padding: 12px;
                text-decoration: none;
                font-size: 18px;
                line-height: 25px;
                border-radius: 4px;
            }

            .header a.logo {
                font-size: 25px;
                font-weight: bold;
            }

            .header a:hover {
                background-color: #ddd;
                color: black;
            }

            .header a.active {
                background-color: dodgerblue;
                color: white;
            }

            .header-right {
                float: right;
            }

            .footer {
                position: fixed;
                padding: 10px 10px 10px 10px;
                bottom: 0;
                width: 100%;
                height: 40px;
            }

            @media screen and (max-width: 500px) {
                .header a {
                float: none;
                display: block;
                text-align: left;
                }
                .header-right {
                float: none;
                }
            }

            .footer {
                padding: 10px;
                width: 100%;
                height: 40px;
                text-align: center;
            }

            .footer-container {
                position: fixed;
                bottom: 0;
                width: 100%;
                height: 40px;
                text-align: center;
                padding: 10px;
            }

            .display_inine {
                display: inline-block;
            }

            #prescription {
                font-family: Arial, Helvetica, sans-serif;
                border-collapse: collapse;
                width: 100%;
            }

            #prescription td,
            #prescription th {
                border: 1px solid #ddd;
                padding: 3px !important;
            }

            #prescription th {
                padding: 3px !important;
            }

            /* New Styles */
            header {
                color: #fb5379;
                text-align: left;
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-size: 18px;
                padding-bottom: 0px;
            }

            .header-left {
                display: flex;
                align-items: center;
            }

            .hospital-logo {
                width: 50px;
                height: 50px;
                margin-right: 10px;
            }

            .hospital-info {
                font-size: 20px;
            }

            .tagline {
                font-style: italic;
                font-size: 13px;
                display: inline-block;
            }

            .patient-info {
                font-size: 16px;
                margin: 20px;
            }

            .hr {
                border: 1px solid #127ac3;
            }

            .container {
                margin: 20px;
            }

            h2 {
                font-size: 18px;
                color: #007bff;
            }

            h3 {
                font-size: 16px;
                color: #333;
            }

            p {
                font-size: 14px;
                color: #555;
            }

            table {
                width: 100%;
                border-collapse: collapse;
            }

            th,
            td {
                border: 1px solid #ddd;
                padding: 5px;
                text-align: left;
            }

            th {
                background-color: #f2f2f2;
            }
            `}
            </style>
            <div
                id="element-to-print"
                style={{
                    padding: '10px',
                    paddingBottom: '0px',
                    paddingTop: '0px',
                }}
            >
                <div
                    className="header"
                    style={{ backgroundColor: ' rgb(255, 255, 255)' }}
                >
                    <img
                        alt="logo"
                        id="header-logo"
                        src="https://www.liblogo.com/img-logo/he1440c445-healthcare-logo-common-healthcare-and-hospitals-logo-design-mistakes-zillion-designs.png"
                        width="60px"
                        height="60px"
                        style={{
                            float: 'left',
                            backgroundColor: 'rgb(255, 255, 255)',
                        }}
                    ></img>
                    <div style={{ float: 'left', paddingLeft: '10px' }}>
                        <h2
                            style={{
                                color: '#fb5379',
                                display: 'inline-block',
                                paddingLeft: '10px',
                                fontSize: '30px',
                            }}
                        >
                            {getHospitalName()}
                        </h2>

                        <div className="tagline" id="tagline">
                            <p
                                style={{
                                    fontStyle: 'italic',
                                    display: 'inline-block',
                                    marginLeft: '20px',
                                }}
                            >
                                {/* "Caring Heart, Healing Hands" */}
                                {getHospitalSlogan()}
                            </p>
                        </div>
                    </div>
                </div>
                <hr
                    style={{ borderTop: '1px solid #127ac3', marginTop: '0px' }}
                />
                <div className="doctor-details">
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        <div className="font-popins font-size-13 patient-details">
                            <p className="font-popins font-size-13">
                                Episode No: {prescription.episode_number}
                            </p>
                            <p className="font-popins font-size-13">
                                Patient: {prescription.patient_first_name}{' '}
                                {prescription.patient_middle_name}{' '}
                                {prescription.patient_last_name}
                            </p>
                            {prescription.patient_address && (
                                <p className="font-popins font-size-13">
                                    Address: {prescription.patient_address}
                                </p>
                            )}
                            {prescription.patient_gender && (
                                <p className="font-popins font-size-13">
                                    Gender: {prescription.patient_gender}{' '}
                                </p>
                            )}
                            <p className="font-popins font-size-13">
                                Date:{' '}
                                {(prescription.prescription_date
                                    ? getTodaysDate(
                                          prescription.prescription_date,
                                      )
                                    : getTodaysDate()) || getTodaysDate()}
                            </p>
                        </div>
                        <div
                            style={{ display: 'flex', flexDirection: 'column' }}
                        >
                            <div style={{ color: '#5c7893', fontSize: '14px' }}>
                                {getDrName()}
                            </div>
                            <div
                                style={{
                                    fontWeight: '500',
                                    color: '#4d4d4d',
                                    fontSize: '12px',
                                }}
                            >
                                {prescription.qualification}
                            </div>
                            <div>
                                <p
                                    style={{
                                        fontWeight: '500',
                                        color: '#4d4d4d',
                                        display: 'block',
                                    }}
                                >
                                    Reg. No:{' '}
                                    <b>{prescription.registration_number}</b>
                                </p>
                            </div>
                        </div>
                    </div>

                    <div id="" className="font-popins font-size-13">
                        {(prescription.vital_bp ||
                            prescription.vital_heart_rate ||
                            prescription.vital_temperature ||
                            prescription.vital_spo2 ||
                            prescription.vital_height ||
                            prescription.vital_weight) && (
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <h2
                                    style={{
                                        marginTop: '5px',
                                        marginRight: '10px',
                                    }}
                                    className="font-popins font-size-15"
                                >
                                    Vitals
                                </h2>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexWrap: 'wrap',
                                        marginTop: '5px',
                                    }}
                                >
                                    {prescription.vital_bp && (
                                        <div
                                            style={{
                                                display: 'inline',
                                                marginRight: '10px',
                                            }}
                                            className="font-popins font-size-13"
                                        >
                                            <span>BP:</span>{' '}
                                            {prescription.vital_bp}
                                        </div>
                                    )}
                                    {prescription.vital_heart_rate && (
                                        <div
                                            style={{
                                                display: 'inline',
                                                marginRight: '10px',
                                            }}
                                            className="font-popins font-size-13"
                                        >
                                            <span>Pulse:</span>{' '}
                                            {prescription.vital_heart_rate}
                                        </div>
                                    )}
                                    {prescription.vital_temperature && (
                                        <div
                                            style={{
                                                display: 'inline',
                                                marginRight: '10px',
                                            }}
                                            className="font-popins font-size-13"
                                        >
                                            <span>Temperature:</span>{' '}
                                            {prescription.vital_temperature}
                                        </div>
                                    )}
                                    {prescription.vital_spo2 && (
                                        <div
                                            style={{
                                                display: 'inline',
                                                marginRight: '10px',
                                            }}
                                            className="font-popins font-size-13"
                                        >
                                            <span>Spo2:</span>{' '}
                                            {prescription.vital_spo2}
                                        </div>
                                    )}
                                    {prescription.vital_height && (
                                        <div
                                            style={{
                                                display: 'inline',
                                                marginRight: '10px',
                                            }}
                                            className="font-popins font-size-13"
                                        >
                                            <span>Height:</span>{' '}
                                            {prescription.vital_height}
                                        </div>
                                    )}
                                    {prescription.vital_weight && (
                                        <div
                                            style={{
                                                display: 'inline',
                                                marginRight: '10px',
                                            }}
                                            className="font-popins font-size-13"
                                        >
                                            <span>Weight:</span>{' '}
                                            {prescription.vital_weight}
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}

                        {prescription.history && (
                            <>
                                <h2
                                    style={{ marginTop: '0px' }}
                                    className="font-popins font-size-15"
                                >
                                    History
                                </h2>
                                <p className="prescription-history font-popins font-size-13">
                                    {' '}
                                    {renderHistory()}{' '}
                                </p>
                            </>
                        )}

                        {/* { (prescription && prescription.complaint && prescription.complaint.length > 0) && <>
                            <h2 style={{ marginTop: "10px"}} className='font-popins font-size-15'>Complaints</h2>
                            <ul style={{listStyle: 'none'}} className='font-popins font-size-13'>
                                {renderComplaints()}
                            </ul>
                            </>
                        } */}

                        {prescription &&
                            prescription.complaint &&
                            prescription.complaint.length > 0 && (
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <h2
                                        style={{
                                            marginTop: '0px',
                                            marginRight: '5px',
                                        }}
                                        className="font-popins font-size-15"
                                    >
                                        Complaints:
                                    </h2>
                                    <ul
                                        style={{
                                            listStyle: 'none',
                                            margin: 0,
                                            padding: 0,
                                            marginTop: '2px',
                                        }}
                                        className="font-popins font-size-13 margin-t10 presc-prevw-ul"
                                    >
                                        {renderComplaints()}
                                    </ul>
                                </div>
                            )}

                        {/* <p>Patient complaints go here.</p>
                        <h2>Observations</h2>
                        <p>Doctor's observations go here.</p> */}
                        {/* { (prescription && prescription.diagnosis && prescription.diagnosis.length > 0) && <>
                                <h2 style={{ marginTop: "10px"}} className='font-popins font-size-15'>Diagnosis</h2>
                                <ul style={{listStyle: 'none'}} className='font-popins font-size-13'>
                                    {renderDiagnosis()}
                                </ul>
                            </>
                        } */}

                        {prescription &&
                            prescription.diagnosis &&
                            prescription.diagnosis.length > 0 && (
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <h2
                                        style={{
                                            marginTop: '0px',
                                            marginRight: '5px',
                                        }}
                                        className="font-popins font-size-15"
                                    >
                                        Diagnosis:
                                    </h2>
                                    <ul
                                        style={{
                                            listStyle: 'none',
                                            margin: 0,
                                            padding: 0,
                                            marginTop: '2px',
                                        }}
                                        className="font-popins font-size-13 presc-prevw-ul"
                                    >
                                        {renderDiagnosis()}
                                    </ul>
                                </div>
                            )}

                        {prescription &&
                            prescription.medicines &&
                            prescription.medicines.length > 0 && (
                                <>
                                    <h2
                                        style={{ marginTop: '0px' }}
                                        className="font-popins font-size-15"
                                    >
                                        Medicines
                                    </h2>
                                    <table id="prescription">
                                        <thead>
                                            <tr
                                                key="prescription-table-header"
                                                className="font-popins font-size-13"
                                            >
                                                <th
                                                    key="prescription-table-header-column-drug"
                                                    style={{
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    Drug
                                                </th>
                                                {/* <th key="prescription-table-header-column-contents">Contents</th> */}
                                                <th
                                                    key="prescription-table-header-column-volume"
                                                    style={{
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    Volume
                                                </th>
                                                <th
                                                    key="prescription-table-header-column-type"
                                                    style={{
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    Type
                                                </th>
                                                <th
                                                    key="prescription-table-header-column-dosage"
                                                    style={{
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    Dosage
                                                </th>
                                                <th
                                                    key="prescription-table-header-column-frequency"
                                                    style={{
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    Frequency
                                                </th>
                                                <th
                                                    key="prescription-table-header-column-frequency"
                                                    style={{
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    When
                                                </th>
                                                <th
                                                    key="prescription-table-header-column-duration"
                                                    style={{
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    Duration
                                                </th>
                                                {/* <th key="prescription-table-header-column-note">Note</th> */}
                                            </tr>
                                        </thead>
                                        <tbody>{renderMedications()}</tbody>
                                    </table>
                                </>
                            )}

                        {/* {prescription &&
                            prescription.tests &&
                            prescription.tests.length > 0 && (
                                <>
                                    <h2
                                        style={{ marginTop: '0px' }}
                                        className="font-popins font-size-15"
                                    >
                                        Tests
                                    </h2>
                                    <ul
                                        style={{ listStyle: 'none' }}
                                        className="font-popins font-size-13"
                                    >
                                        {renderTests()}
                                    </ul>
                                </>
                            )} */}

                        {prescription &&
                            prescription.tests &&
                            prescription.tests.length > 0 && (
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <h2
                                        style={{
                                            marginTop: '0px',
                                            marginRight: '5px',
                                        }}
                                        className="font-popins font-size-15"
                                    >
                                        Tests
                                    </h2>
                                    <ul
                                        style={{
                                            listStyle: 'none',
                                            margin: 0,
                                            padding: 0,
                                            marginTop: '2px',
                                        }}
                                        className="font-popins font-size-13 presc-prevw-ul"
                                    >
                                        {renderTests()}
                                    </ul>
                                </div>
                            )}

                        {/* { (prescription && prescription.advice && prescription.advice.length > 0) && 
                            <>
                                <h2 style={{marginTop: "10px"}} className='font-popins font-size-15'>Advice</h2>
                                <ul style={{listStyle: 'none'}} className='font-popins font-size-13'>
                                    {renderAdvice()}
                                </ul>
                            </>
                        } */}

                        {prescription &&
                            prescription.advice &&
                            prescription.advice.length > 0 && (
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <h2
                                        style={{
                                            marginTop: '0px',
                                            marginRight: '5px',
                                        }}
                                        className="font-popins font-size-15"
                                    >
                                        Advice:
                                    </h2>
                                    <ul
                                        style={{
                                            listStyle: 'none',
                                            margin: 0,
                                            padding: 0,
                                            marginTop: '2px',
                                        }}
                                        className="font-popins font-size-13 presc-prevw-ul"
                                    >
                                        {renderAdvice()}
                                    </ul>
                                </div>
                            )}

                        {/* { (prescription.referenced_doctor || prescription.doctor_address || prescription.next_visit_date) && 
                            <>
                                <h2 style={{marginTop: "10px"}} className='font-popins font-size-15'>Note</h2>
                                <ul style={{listStyle: 'none'}} className='font-popins font-size-13'>
                                    { prescription.referenced_doctor && <li key="prescription-reffered-dr">Referred To Dr: {prescription.referenced_doctor}</li>}
                                    { prescription.doctor_address && <li key="prescription-reffered-dr-address">Referred Dr Address: {prescription.doctor_address}</li>}
                                    { prescription.next_visit_date && <li key="prescription-nxt-visit">Next Visit: {prescription.next_visit_date}</li>}
                                </ul>
                            </>
                        } */}

                        {(prescription.referenced_doctor ||
                            prescription.doctor_address ||
                            prescription.next_visit_date) && (
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <h2
                                    style={{
                                        marginTop: '2px',
                                        marginRight: '10px',
                                    }}
                                    className="font-popins font-size-15"
                                >
                                    Note:
                                </h2>
                                <ul
                                    style={{
                                        listStyle: 'none',
                                        margin: 0,
                                        padding: 0,
                                        display: 'flex',
                                        alignItems: 'center',
                                        marginTop: '3px',
                                    }}
                                    className="font-popins font-size-13 presc-prevw-ul"
                                >
                                    {prescription.next_visit_date && (
                                        <li key="prescription-nxt-visit">
                                            <spab
                                                style={{ fontWeight: 'bold' }}
                                            >
                                                Next Visit:
                                            </spab>{' '}
                                            {prescription.next_visit_date}
                                        </li>
                                    )}
                                    {prescription.referenced_doctor && (
                                        <li key="prescription-reffered-dr">
                                            <span
                                                style={{
                                                    fontWeight: 'bold',
                                                    marginLeft: '5px',
                                                }}
                                            >
                                                Ref. To:{' '}
                                            </span>
                                            {prescription.referenced_doctor}
                                        </li>
                                    )}
                                    {/* { prescription.doctor_address && <li key="prescription-reffered-dr-address"><span style={{ fontWeight: 'bold', marginLeft: "5px"}}> Ref. Dr Address:  </span>{prescription.doctor_address}</li>} */}
                                </ul>
                            </div>
                        )}
                    </div>
                </div>

                {/* <div className="footer-container" style={{ bottom: '0px' }}>
                    <footer className="footer">
                        <button onClick={()=>{handlePreviewClick()}}>Preview</button>
                    </footer>
                </div> */}

                <div
                    className="footer-container"
                    style={{ bottom: '0px', position: 'relative' }}
                >
                    <button
                        className="custom-primary-btn"
                        onClick={() => {
                            handlePreviewClick();
                        }}
                    >
                        Preview
                    </button>
                </div>
            </div>
            {/* <button onClick={()=>{handlePreviewClick()}}>Preview Prescription</button> */}
            {showPreview && (
                <iframe
                    title="prescription"
                    src={pdfPreview}
                    width="100%"
                    height="800px"
                />
            )}
        </>
    );
};

export default PreviewPrescription;
