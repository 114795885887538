import React, {useEffect} from 'react';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Form, Button, Select, Input, Row, Col, Tooltip } from 'antd';


import CustomSelect from '../dropdown';

const { TextArea } = Input;
//https://codesandbox.io/s/antd-form-list-multiple-formitem-dynamic-fields-cje76?file=/src/App.js:852-864
//https://api.drugbank.com/v1/drug_names?q=Tylenol
export default function PrescriptionDynamicForm({onMedicineSelect, selectedMedicines, setSelectedMedicines, selectedMedicineContents}) {
    console.log('selectedMedicineContents:', selectedMedicineContents);
    const labelCol = {
        span: 24,
        style: {
            // width: "100px", // this means the label will have a fixed width of 100px
            textAlign: 'center',
        },
    };

    const inputMargin = {
        marginRight: '5px',
    };

    // useEffect(()=>{

    // }, [selectedMedicineContents]);

    const onMedicineChange = function(value){
       console.log('onMedicineChange synamic form:', value);
    };

    const showMedicineContents = function(selectedMedicineContents, index){
        if((selectedMedicineContents && selectedMedicineContents[index])){
            return `(${selectedMedicineContents[index].salt_composition})`;
        }
        else {
            return '';
        }
    }
    
    return (
        <>
            <Form.List name="medicines">
                {(fields, { add, remove }) => {
                    return (
                        <div style={{ width: '100%'}}>
                            {fields.map((field, index) => {
                                return (
                                    <div key={field.key} style={{ borderBottom: "1px solid #000"  }}>
                                        <Row>
                                            <Col
                                                xs={12}
                                                lg={6}
                                                style={{ ...inputMargin }}
                                            >
                                                <Form.Item
                                                    name={[index, 'medicine_id']}
                                                    label="Medicine"
                                                    labelCol={{ ...labelCol }}
                                                    style={{ marginBottom: "5px"}}
                                                >
                                                   <CustomSelect className="font-popins font-size-13"
                                                        apiEndpoint="api/cache"
                                                        placeholder="Type Medicine name"
                                                        optionFormat={{
                                                            id: 'Id',
                                                            label: 'name',
                                                        }}
                                                        onChange={(value) => onMedicineSelect(index, value)}
                                                        selectedValue={selectedMedicines[index]} // Assuming selectedValue is the prop for selected medicine in CustomSelect
                                                        setSelectedValue={(newValue) => setSelectedMedicines[index](newValue)} // Assuming setSelectedValue is the prop for updating selected medicine in CustomSelect
                                                    />
                                                    
                                                </Form.Item>
                                                
                                                <p style={{ paddingLeft: "5px", paddingRight: "5px"}} className='font-popins font-size-13'>{showMedicineContents(selectedMedicineContents, index)}</p>
                                                {/* <Tooltip placement="top" title={(selectedMedicineContents && selectedMedicineContents[index]) ? selectedMedicineContents[index].salt_composition : ''}>
                                                        <p style={{ paddingLeft: "5px", paddingRight: "5px"}} className='font-popins font-size-13'>({(selectedMedicineContents && selectedMedicineContents[index]) ? selectedMedicineContents[index].salt_composition : ''})</p>
                                                </Tooltip> */}

                                                {/* <Form.Item
                                                    name={[index, 'medicine_name']}
                                                    label="Medicine"
                                                    labelCol={{ ...labelCol }}
                                                >
                                                    <Input placeholder="Medicine name" />
                                                </Form.Item> */}
                                            </Col>
                                            {/* <Col xs={12} lg={2}>
                                                <Form.Item
                                                    name={[
                                                        index,
                                                        'contents',
                                                    ]}
                                                    label="Contents"
                                                    labelCol={{ ...labelCol }}
                                                >
                                                    <Tooltip placement="top" title={(selectedMedicineContents && selectedMedicineContents[index]) ? selectedMedicineContents[index].salt_composition : ''}>
                                                        <p style={{ paddingLeft: "5px", paddingRight: "5px"}} className='ellipsis font-popins font-size-13'>{(selectedMedicineContents && selectedMedicineContents[index]) ? selectedMedicineContents[index].salt_composition : ''}</p>
                                                    </Tooltip>
                                                </Form.Item>
                                            </Col> */}
                                            <Col xs={12} lg={2}>
                                                <Form.Item
                                                    name={[
                                                        index,
                                                        'product_form',
                                                    ]}
                                                    label="Type"
                                                    labelCol={{ ...labelCol }}
                                                >
                                                    <Tooltip placement="top" title={(selectedMedicineContents && selectedMedicineContents[index]) ? selectedMedicineContents[index]['Product Form'] : ''}>
                                                        <p style={{ paddingLeft: "5px", paddingRight: "5px"}} className='ellipsis'>{(selectedMedicineContents && selectedMedicineContents[index]) ? selectedMedicineContents[index]['Product Form'] : ''}</p>
                                                    </Tooltip>
                                                </Form.Item>
                                            </Col>
                                            <Col
                                                xs={12}
                                                lg={2}
                                                style={{ ...inputMargin }}
                                            >
                                                <Form.Item
                                                    name={[index, 'volume']}
                                                    label="volume"
                                                    labelCol={{ ...labelCol }}
                                                >
                                                    <Input placeholder="5ML" />
                                                </Form.Item>
                                            </Col>
                                            <Col
                                                xs={12}
                                                lg={3}
                                                style={{ ...inputMargin }}
                                            >
                                                <Form.Item
                                                    name={[index, 'dose']}
                                                    label="Dose"
                                                    labelCol={{ ...labelCol }}
                                                >
                                                    <Select>
                                                        <Select.Option value="1-0-0">
                                                            1-0-0
                                                        </Select.Option>
                                                        <Select.Option value="1-1-0">
                                                            1-1-0
                                                        </Select.Option>
                                                        <Select.Option value="1-0-1">
                                                            1-0-1
                                                        </Select.Option>
                                                        <Select.Option value="1-1-1">
                                                            1-1-1
                                                        </Select.Option>
                                                        <Select.Option value="0-1-0">
                                                            0-1-0
                                                        </Select.Option>
                                                        <Select.Option value="0-0-1">
                                                            0-0-1
                                                        </Select.Option>
                                                        <Select.Option value="1-1-1-1">
                                                            1-1-1-1
                                                        </Select.Option>
                                                        <Select.Option value="SOS">
                                                            SOS
                                                        </Select.Option>
                                                        <Select.Option value=" 1/2-0-0">
                                                            1/2-0-0
                                                        </Select.Option>
                                                        <Select.Option value="1/2-1/2-0">
                                                            1/2-1/2-0
                                                        </Select.Option>
                                                        <Select.Option value="1/2-0-1/2">
                                                            1/2-0-1/2
                                                        </Select.Option>
                                                        <Select.Option value="1/2-1/2-1/2">
                                                            1/2-1/2-1/2
                                                        </Select.Option>
                                                        <Select.Option value="0-1/2-0">
                                                            0-1/2-0
                                                        </Select.Option>
                                                        <Select.Option value="0-0-1/2">
                                                            0-0-1/2
                                                        </Select.Option>
                                                        <Select.Option value="1/2-1/2-1/2-1/2">
                                                            1/2-1/2-1/2-1/2
                                                        </Select.Option>
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col
                                                xs={12}
                                                lg={2}
                                                style={{ ...inputMargin }}
                                            >
                                                <Form.Item
                                                    name={[index, 'when_to_take']}
                                                    label="When"
                                                    labelCol={{ ...labelCol }}
                                                >
                                                    <Select style={{ width: '100%' }}>
                                                        <Select.Option value="After Food">
                                                            After Food
                                                        </Select.Option>
                                                        <Select.Option value="Before Food">
                                                            Before Food
                                                        </Select.Option>
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col
                                                xs={12}
                                                lg={2}
                                                style={{ ...inputMargin }}
                                            >
                                                <Form.Item
                                                    name={[index, 'frequency']}
                                                    label="Frequency"
                                                    labelCol={{ ...labelCol }}
                                                    style={{ paddingBottom: "0px"}}
                                                >
                                                    <Input placeholder="Frequence...." />
                                                </Form.Item>
                                            </Col>
                                            <Col
                                                xs={12}
                                                lg={2}
                                                style={{ ...inputMargin }}
                                            >
                                                <Form.Item
                                                    name={[index, 'duration']}
                                                    label="Duration"
                                                    labelCol={{ ...labelCol }}
                                                >
                                                    <Input placeholder="Duration...." />
                                                </Form.Item>
                                            </Col>
                                            
                                            <Col xs={12} lg={2}>
                                                <Form.Item
                                                    style={{
                                                        marginBottom: '10px',
                                                    }}
                                                ></Form.Item>
                                                <Button
                                                    type="danger"
                                                    className="dynamic-delete-button"
                                                    onClick={() =>
                                                        remove(field.name)
                                                    }
                                                    icon={
                                                        <MinusCircleOutlined />
                                                    }
                                                ></Button>
                                            </Col>
                                        </Row>
                                        {/* {fields.length > 1 ? (
                                            <Button
                                                type="danger"
                                                className="dynamic-delete-button"
                                                onClick={() =>
                                                    remove(field.name)
                                                }
                                                icon={<MinusCircleOutlined />}
                                            >
                                                Remove Above Field
                                            </Button>
                                        ) : null} */}
                                    </div>
                                );
                            })}

                            <Form.Item style={{ textAlign: 'center' }}>
                                <Button
                                    type="dashed"
                                    onClick={() => add()}
                                    style={{ width: '20%' }}
                                >
                                    <PlusOutlined /> Add Medicine
                                </Button>
                            </Form.Item>
                        </div>
                    );
                }}
            </Form.List>
        </>
    );
}
