import React, { useState, useEffect } from 'react';
import { Select } from 'antd';
//import axios from 'axios';
import axios from '../services/axios';
const { Option } = Select;

const CustomSelect = ({
    apiEndpoint,
    placeholder,
    onChange,
    optionFormat,
    disabled = false,
    selectedValue = undefined
}) => {
    const [options, setOptions] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const key = optionFormat.label;

    useEffect(() => {
        let params = {};
        if (searchValue) {
            params = { params: { [key]: searchValue } };
        }

        if (apiEndpoint) {
            // console.log('Dropdown.js apiEndpoint, optionFormat, key:', apiEndpoint, optionFormat, key);
            axios
                .get(apiEndpoint, params)
                .then((response) => setOptions(response.data.currentpage))
                .catch((error) =>
                    console.error(
                        `Error fetching data from ${apiEndpoint}:`,
                        error,
                    ),
                );
        }
    }, [apiEndpoint, searchValue, key]);

    const handleSearch = (value) => {
        setSearchValue(value);
    };
    
    const handleSpecialityChange = (value) => {
        // Reset search value when speciality dropdown value changes
        setSearchValue('');
    };


    const uniqueId = optionFormat.id;
    const label = optionFormat.label;

    return (
        <Select value={selectedValue}
            showSearch
            allowClear
            placeholder={placeholder}
            onChange={onChange}
            onSearch={handleSearch}
            onDropdownVisibleChange={handleSpecialityChange}
            disabled={disabled}
            filterOption={false} // Disable default filtering since we handle it manually
        >
            {options.map((option) => (
                <Option key={option[uniqueId]} value={option[uniqueId]}>
                    {option[label]}
                </Option>
            ))}
        </Select>
    );
};
export default CustomSelect;
