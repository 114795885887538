import React from 'react';

const FollowupMedicineTable = ({ tableData }) => {
    console.log('tableData:', tableData);
    if (tableData.length === 0) {
        return <p>No prescriptions available.</p>;
    }


    return (
        <table className='followup-medicine-table' style={{ borderCollapse: "collapse", width: "100%"}}>
            <thead>
                <tr>
                    <th style={{border: "1px solid #dddddd", textAlign: "left", padding: "5px"}}>Medicine</th>
                    <th style={{border: "1px solid #dddddd", textAlign: "left", padding: "5px"}}>Type</th>
                    <th style={{border: "1px solid #dddddd", textAlign: "left", padding: "5px"}}>Volume</th>
                    <th style={{border: "1px solid #dddddd", textAlign: "left", padding: "5px"}}>Dose</th>
                    <th style={{border: "1px solid #dddddd", textAlign: "left", padding: "5px"}}>When</th>
                    <th style={{border: "1px solid #dddddd", textAlign: "left", padding: "5px"}}>Frequency</th>
                    <th style={{border: "1px solid #dddddd", textAlign: "left", padding: "5px"}}>Duration</th>
                </tr>
            </thead>
            <tbody>
                {tableData.map((row, index) => (
                    <tr key={index}>
                        <td style={{border: "1px solid #dddddd", textAlign: "left", padding: "5px"}}>{row.medicine_name}</td>
                        <td style={{border: "1px solid #dddddd", textAlign: "left", padding: "5px"}}>{row.product_form}</td>
                        <td style={{border: "1px solid #dddddd", textAlign: "left", padding: "5px"}}>{row.volume}</td>
                        <td style={{border: "1px solid #dddddd", textAlign: "left", padding: "5px"}}>{row.dose}</td>
                        <td style={{border: "1px solid #dddddd", textAlign: "left", padding: "5px"}}>{row.when_to_take}</td>
                        <td style={{border: "1px solid #dddddd", textAlign: "left", padding: "5px"}}>{row.frequency}</td>
                        <td style={{border: "1px solid #dddddd", textAlign: "left", padding: "5px"}}>{row.duration}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default FollowupMedicineTable;
