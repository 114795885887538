import React from 'react';
import { Form, Input, Modal, Select, DatePicker } from 'antd';
import dayjs from 'dayjs';
import axios from '../../services/axios';
const { Option } = Select;
const { TextArea } = Input;

const layout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 16,
    },
};

// const tailLayout = {
//     wrapperCol: {
//         offset: 8,
//         span: 16,
//     },
// };

export default function AddPatient({ open, onCreate, onCancel, onError }) {
    const [form] = Form.useForm();

    //const [modalOpen, setModalOpen] = useState(show);

    // function handleModalOk() {
    //     //setModalOpen(false);
    // }

    const getTodaysDate = function (date) {
        let d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;

        return [year, month, day].join('-');
    };

    const onFinish = (newPatient) => {
        newPatient.date_of_birth = getTodaysDate(newPatient.date_of_birth);
        if(newPatient.date_of_birth === "NaN-NaN-NaN"){
            delete newPatient.date_of_birth;
        }
        newPatient['user_type_lookup_id'] = 6; //Hardcoded to 6 as it is a lookup id for patient...
        axios.post(`/api/patients`, newPatient).then(() => {
            onCreate();
        })
        .catch((error) => {
            onError(error);
        });
    };

    // const onReset = () => {
    //     form.resetFields();
    // };

    const onDOBChange = (date, dateString) => {
        console.log(date, dateString);
    };

    // eslint-disable-next-line arrow-body-style
    const disabledDate = (current) => {
        // Can not select days before today and today
        return current && current > dayjs().endOf('day');
    };

    return (
        <>
            <Modal
                title="Add Patient"
                centered="true"
                open={open}
                mask={true}
                maskClosable={false}
                destroyOnClose
                okText="Add Patient"
                onOk={() => {
                    form.validateFields()
                        .then((newAppointment) => {
                            form.resetFields();
                            onFinish(newAppointment);
                            //onCreate(newAppointment);
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info);
                        });
                }}
                onCancel={() => {
                    form.resetFields();
                    onCancel();
                }}
                width={750}
            >
                <Form
                    {...layout}
                    form={form}
                    name="control-hooks"
                    onFinish={onFinish}
                    style={{
                        maxWidth: 600,
                    }}
                >
                    <Form.Item
                        name="patient_first_name"
                        label="First Name"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter patient First Name',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item name="patient_middle_name" label="Middle Name">
                        <Input />
                    </Form.Item>
                    <Form.Item name="patient_last_name" label="Last Name">
                        <Input />
                    </Form.Item>

                    <Form.Item name="date_of_birth" label="Date Of Birth">
                        <DatePicker
                            format="MM-DD-YYYY"
                            onChange={onDOBChange}
                            disabledDate={disabledDate}
                        />
                    </Form.Item>

                    <Form.Item name="patient_age" label="Age">
                        <Input />
                    </Form.Item>

                    <Form.Item
                        name="gender"
                        size="small "
                        label="Gender"
                        rules={[
                            {
                                required: true,
                                message: 'Please select Patient Gender',
                            },
                        ]}
                    >
                        <Select placeholder="Select Gender" allowClear>
                            <Option value="Male">Male</Option>
                            <Option value="Female">Female</Option>
                            <Option value="Other">Other</Option>
                        </Select>
                    </Form.Item>

                    <Form.Item
                        name="mobile"
                        label="Mobile Number"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter mobile number',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item name="email" label="Email Id">
                        <Input />
                    </Form.Item>

                    <Form.Item
                        name="alternate_mobile"
                        label="Sec. Mobile Number"
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item name="patient_address" label="Address">
                        <TextArea
                            rows={4}
                            placeholder="Type address here"
                            maxLength={500}
                        />
                    </Form.Item>

                    <Form.Item name="blood_group_lookup_id" label="Blood Group">
                        <Input />
                    </Form.Item>

                    {/* <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                        <Col xs={12} lg={24}>
                            <Form.Item>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    style={{
                                        float: 'right',
                                        marginLeft: '20px',
                                    }}
                                >
                                    Add Patient
                                </Button>
                                <Button
                                    htmlType="button"
                                    style={{ float: 'right' }}
                                    onClick={onReset}
                                >
                                    Reset
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row> */}
                </Form>
            </Modal>
        </>
    );
}
