import React, { useState } from 'react';
import { Divider, Space, Tag, Popover } from 'antd';

export default function TimeSots({ slots, tagSelected }) {
    //const [timeSlots, setTimeSlots] = useState(slots);
    const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);

    // console.log('received slots:', slots);

    function onTagSelected(slot) {
        // console.log('On tag Selection:', slot);
        if (slot.status.toLowerCase() === 'BOOKED'.toLowerCase()) {
            //the timeslot is booked
            console.info(
                'This time slot is already booked, please select another free slot...',
                selectedTimeSlot,
            );
        } else {
            let newSlots = slots.map((tag) => {
                //reset the color back to as it is (FREE) as earlier
                if (tag.status_color === '#87d068') {
                    tag.status_color = '#2db7f5';
                }

                if (slot.time_slot_id === tag.time_slot_id) {
                    tag.status_color = '#87d068';
                    setSelectedTimeSlot(slot);
                    tagSelected(slot);
                }
                return tag;
            });
            //setTimeSlots(newSlots);
        }
    }

    const popOverContent = (
        <div>
            <p
                style={{
                    fontSize: '12px',
                    color: '#000',
                    fontFamily: "'Poppins', sans-serif",
                    fontWeight: '100',
                }}
            >
                This slot is booked, please select another slot!
            </p>
        </div>
    );

    const tags = slots.map((slot) => {
        // console.log('received slots: inside map:', slot);
        if (slot.status.toLowerCase() === 'BOOKED'.toLowerCase()) {
            return (
                <Popover content={popOverContent}>
                    <Tag
                        key={slot.time_slot_id}
                        size="large"
                        data={slot}
                        color={slot.status_color}
                        onClick={() => {
                            onTagSelected(slot);
                        }}
                        style={{ borderRadius: 10 }}
                    >
                        {slot.time_slot_time}
                    </Tag>
                </Popover>
            );
        } else {
            return (
                <Tag
                    key={slot.time_slot_id}
                    size="large"
                    data={slot}
                    color={slot.status_color}
                    onClick={() => {
                        onTagSelected(slot);
                    }}
                    style={{ borderRadius: 10 }}
                >
                    {slot.time_slot_time}
                </Tag>
            );
        }
    });

    return (
        <>
            <Divider
                orientation="left"
                style={{ margin: '10px 0px', borderColor: 'red' }}
            >
                Select Time Slots
            </Divider>
            <Space size="small" wrap>
                {tags}
            </Space>
        </>
    );
}
