import React, { useState, useEffect  } from 'react';

//import logo from './logo.svg';
//import logo from './Healify.png';
// import logo from './assets/Healify2.png';
import { Layout, Menu, Dropdown, Avatar, Space, Badge } from 'antd';
import {
    UserOutlined,
    DashboardOutlined,
    MailOutlined,
    ScheduleOutlined,
    UsergroupDeleteOutlined,
    BellFilled,
} from '@ant-design/icons';

//import { FaFilePrescription, FaUserInjured, FaPrescription } from 'react-icons/fa';
import {  FaUserInjured, FaPrescription } from 'react-icons/fa';
import { FaUserDoctor } from 'react-icons/fa6';
import { MdSchedule } from 'react-icons/md';

//import { ScheduleOutlined } from '@ant-design/icons';

import { Route, Routes, Link, Navigate } from 'react-router-dom';
import { useNavigate } from "react-router-dom";


import ProfileMenu from './components/profile-menu/profile-menu';
//import Appointments from './pages/appointments/appointments';
import AppointmentList from './components/appointment/appointment-list';
//import BookAppointment from './components/appointment/BookAppointment';

import Dashboard from './pages/dashboard/index';
import Patients from './pages/patients/patients';
import Reports from './pages/reports/reports';
import Login from './components/login/login';
//import Prescription from './components/prescription/prescription';
import Prescription from './components/prescription/prescription-new';
import Doctors from './components/doctor/doctors';
import Users from './components/user/users';
import UserProfilePage from './components/user/profile';
import GeneratePDFPrescription from './components/prescription/pdf-generation';

import './App.css';
import MyPrescriptions from './pages/dashboard/my-prescriptions';

const { Header, Sider, Content } = Layout;

const items = [
    {
        label: 'Dashboard',
        icon: <DashboardOutlined style={{ fontSize: "25px"}}/>,
        key: 'dashboard',
    },
    {
        label: 'Appointments',
        key: 'appointments',
        icon: <MdSchedule  style={{ fontSize: "25px"}}/>
        // subMenu: [
        //     {
        //         label: 'Appointments List',
        //         key: 'appointments',
        //         icon: <MdSchedule />,
        //     },
        //     {
        //       label: 'New Appointment',
        //       key: 'book-appointments',
        //       icon: <MdSchedule />,
        //   },
        // ],
    },
    {
        label: 'Patients',
        key: 'patients',
        icon: <FaUserInjured style={{ fontSize: "25px"}}/>,
        // subMenu: [
        //     {
        //         label: 'Patients List',
        //         key: 'patients',
        //         icon: <MdSchedule />,
        //     },
        //     {
        //         label: 'Add Patients',
        //         key: 'create-patients',
        //         icon: <MdSchedule />,
        //     },
        // ],
    },
    {
        label: 'Doctors',
        key: 'doctors',
        icon: <FaUserDoctor style={{ fontSize: "25px"}}/>
    },
    {
        label: 'My Prescriptions',
        key: 'prescriptions',
        icon: <FaPrescription style={{ fontSize: "25px"}}/>
    },
    {
        label: 'Users',
        key: 'users',
        icon: <UsergroupDeleteOutlined style={{ fontSize: "25px"}}/>
    },
    // {
    //     label: 'Reports',
    //     key: 'reports',
    //     icon: <AiOutlineLineChart />,
    //     subMenu: [
    //         {
    //             label: 'Patient wise Reports',
    //             key: 'patient-reports',
    //             icon: <AiOutlineLineChart />,
    //         },
    //         {
    //           label: 'Doctor wise Reports',
    //           key: 'doctor-reports',
    //           icon: <AiOutlineLineChart />,
    //       },
    //       {
    //         label: 'All Reports',
    //         key: 'all-reports',
    //         icon: <AiOutlineLineChart />,
    //     },
    //     ],
    // },
    // {
    //     label: 'Prescriptions',
    //     key: 'prescriptions',
    //     // icon: <FaFilePrescription />
    //     icon: <FaPrescription />
    // },
    // {
    //     label: 'Schedules',
    //     key: 'schedules',
    //     icon: <ScheduleOutlined />,
    //     subMenu: [
    //         {
    //             label: 'View',
    //             key: 'schedules',
    //             icon: <ScheduleOutlined />,
    //         },
    //         {
    //             label: 'Create',
    //             key: 'create-schedule',
    //             icon: <ScheduleOutlined />,
    //         },
    //         {
    //             label: 'Edit',
    //             key: 'edit-schedule',
    //             icon: <ScheduleOutlined />,
    //         },
    //     ],
    // },
];

const renderSubMenu = (subMenu) => (
    <Menu.SubMenu key={subMenu.key} icon={subMenu.icon} title={subMenu.label}>
        {subMenu.subMenu.map((subMenuItem) => (
            <Menu.Item key={subMenuItem.key} style={{ background: "#082949"}}>
                <Link to={subMenuItem.key}>{subMenuItem.label}</Link>
            </Menu.Item>
        ))}
    </Menu.SubMenu>
);

const App = () => {
    const [firstName, setFirstName] = useState('');
    const navigate = useNavigate()

    
   // const [isLoggedIn, setIsLoggedIn] = useState(true);

    const [isLoggedIn, setIsLoggedIn] = useState(() => {
        // This function is the initial state value
        let loggedIn = false;
        let userRaw = localStorage.getItem('user'); 
        if(userRaw){
            loggedIn = true;
        }
        return loggedIn;
      });
    
    const [currentMenu, setCurrentMenu] = useState('Dashboard');


    // Implement your login logic here, which sets isLoggedIn to true when the user logs in.
    // Example:
    const handleLogin = (loggedIn) => {
        setIsLoggedIn(loggedIn);
        if(loggedIn){
            navigate('/dashboard');
        }
    };

    // Implement your logout logic here, which sets isLoggedIn to false when the user logs out.
    // Example:
    const handleLogout = () => {
        localStorage.clear();
        setIsLoggedIn(false);
    };

    const onMenuClick = (e) => {
        console.log('click ', e);
        setCurrentMenu(e.key);
    };

    return (
        <Layout style={{ minHeight: '100vh' }}>
            {isLoggedIn ? ( // Conditional rendering based on the login status
                <>
                    {/* <Sider width={250} theme="dark" collapsible> */}
                    <Sider width={250} theme="dark" collapsible style={{background: "#082949"}}>
                        {/* Sidebar Content */}
                       
                        <div className="logo" style={{marginTop: '0px', display: "block", fontSize: "40px", marginLeft: "33px", color: "#B0C50F", fontWeight: "bold"}}>
                            {/* <img
                                src={logo}
                                alt="Logo"
                                style={{ width: '200px', height: '120px' }}
                            /> */}
                            Symptam
                        </div>
                        <Menu
                            theme="dark"
                            mode="inline"
                            defaultSelectedKeys={['dashboard']}
                            defaultOpenKeys={['Dashboard']}
                            selectedKeys={[currentMenu]}
                            onClick={onMenuClick}
                            style={{ background: "#082949"}}
                        >
                            {items.map((item) =>
                                item.subMenu ? (
                                    renderSubMenu(item)
                                ) : (
                                    <Menu.Item key={item.key} icon={item.icon} style={{ background: "#082949"}}>
                                        <Link to={item.key}>{item.label}</Link>
                                    </Menu.Item>
                                ),
                            )}
                        </Menu>
                    </Sider>
                    <Layout>
                        <Header
                            style={{
                                // background: '#001529',
                                background: '#082949',
                                padding: 0,
                                color: 'white',
                                textAlign: 'center',
                            }}
                            className="app-header"
                        >
                            <span style={{fontSize: 24}}>
                                {JSON.parse(localStorage.getItem('user')).user.company_name} 

                            </span>
                            <div
                                style={{
                                    // width: '70vw',
                                    // textAlign: 'center',
                                    display: 'inline-block',
                                    color: '#fff',
                                }}
                            >
                                {/* <Typography.Title
                                    level={3}
                                    style={{ color: '#fff' }}
                                >
                                    Symptam
                                </Typography.Title> */}
                            </div>

                            <div
                                className="profile-container"
                                style={{
                                    float: 'right',
                                    marginRight: '5px',
                                    width: '5vw',
                                    color: '#fff',
                                }}
                            >
                                
                                <Dropdown
                                    overlay={<ProfileMenu logout={handleLogout}/>}
                                    placement="bottomRight"
                                >
                                    <Avatar
                                        icon={<UserOutlined />}
                                        size={40}
                                        style={{ cursor: 'pointer' }}
                                    />
                                </Dropdown>
                            </div>

                            <div
                                className="profile-container"
                                style={{
                                    float: 'right',
                                    marginRight: '0px', 
                                    paddingTop: '0px',
                                }}
                            >
                                <div >{JSON.parse(localStorage.getItem('user')).user.user_first_name}</div>
                                <Space> 
                                   
                                    {/* <Badge
                                        style={{ cursor: 'pointer' }}
                                        count={20}
                                        dot
                                    >
                                        <MailOutlined
                                            style={{
                                                fontSize: 24,
                                                cursor: 'pointer',
                                                color: '#fff',
                                            }}
                                        ></MailOutlined>
                                    </Badge> */}
                                    {/* <Badge
                                        count={20}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        <BellFilled
                                            style={{
                                                fontSize: 24,
                                                color: '#fff',
                                            }}
                                        ></BellFilled>
                                    </Badge> */}
                                </Space>
                                
                            </div>
                        </Header>
                        <Content
                            style={{
                                // margin: '16px',
                                margin: '8px',
                                overflow: 'hidden',
                                // marginTop: '10px',
                                background: '#fff'
                            }}
                        >
                            <Routes>
                                <Route
                                    path="/"
                                    element={<Dashboard />}
                                />
                                <Route
                                    path="/dashboard"
                                    element={<Dashboard />}
                                />
                                <Route
                                    path="/appointments"
                                    element={<AppointmentList />}
                                />
                                {/* Obsolete Path */}
                                {/* <Route
                                    path="/book-appointments"
                                    //element={<BookAppointment />}
                                    element={<><GeneratePDFPrescription></GeneratePDFPrescription></>}
                                /> */}
                                <Route
                                    path="/patients"
                                    element={<Patients />}
                                />
                                 <Route
                                    path="/doctors"
                                    element={<Doctors />}
                                />
                                <Route
                                    path="/prescriptions"
                                    element={<MyPrescriptions gridPageSize={15}/>}
                                />
                                 <Route
                                    path="/users"
                                    element={<Users />}
                                />
                                <Route
                                    path="/profile"
                                    element={<UserProfilePage />}
                                />



                                {/* <Route path="/reports" element={<Reports />} /> */}
                                {/* <Route
                                    path="/prescriptions"
                                    element={<Prescription />}
                                />
                                <Route
                                    path="/doctors-settings"
                                    element={<Doctors />}
                                />  */}
                                
                            </Routes>
                        </Content>
                        {/* <Footer
              style={{
                textAlign: 'center',
                background: '#002140',
                color: '#aaa',
                height: '50px',
                fontWeight: 300,
              }}
              className='fixed-bottom'
            >
              
              Ant Design Responsive Layout ©2023 Created by Sharad Biradar
            </Footer> */}
                    </Layout>
                </>
            ) : (
                <Routes>
                    <Route
                        path="/login"
                        element={<Login handleLogin={handleLogin} />}
                    />
                    <Route path="/*" element={<Navigate to="/login" />} />
                </Routes>
            )}
        </Layout>
    );
};

export default App;
