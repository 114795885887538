import React, { useRef, useState } from 'react';
import { Button, Form, Input, Modal, Select, DatePicker, notification } from 'antd';
import dayjs from 'dayjs';
import {
    PlusOutlined 
} from '@ant-design/icons';
import axios from '../../services/axios';
import CustomSelect from '../dropdown';

const { Option } = Select;
const { TextArea } = Input;

const layout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 16, 
    },
};

export default function AddUser({ initialValues, open, onCreate, onCancel, onError, edit = false }) {
    const refElement = useRef(null);
    const [selectedSpecialty, setSelectedSpecialty] = useState(null);
    const [selectedDepartment, setSelectedDepartment] = useState(null);
    const [api, contextHolder] = notification.useNotification();
    //Check username
    const [username, setUsername] = useState('');
    const [userMessage, setUserMessage] = useState('');
    const [isAvailable, setIsAvailable] = useState(null);

    const [form] = Form.useForm();
    const onFinish = (values) => {
        // console.log(values);
    };


    const checkUsernameAvailability = (enteredUsername) => {

        console.log("added enteredUsername: ", enteredUsername);
         // api/users?check_user_name='enteredUsername';
        // const fakeAPICall = new Promise((resolve, reject) => {
         axios
         .get(`api/users?check_user_name=${enteredUsername}`)
         .then((res) => {
            console.log("Check username:", res.data);
            let isAvailable = res.data.length === 0;
            if(isAvailable){ 
                setUserMessage('Username is available');
              } else {
                setUserMessage('Username is not available');
              }
            
         } 
         );
        //   setTimeout(() => {
        //     // const availableUsernames = ['user1', 'user2', 'user3'];  
        //     if (availableUsernames.includes(enteredUsername)) {
        //         console.log("Available username");
        //       resolve(true);  
                
        //     } else {
        //       resolve(false);  
        //       console.log("Not available username");
        //     }
        //   }, 1000);   
    
        // Update message based on API response
        // fakeAPICall.then((isAvailable) => {
        //     setIsAvailable(isAvailable);
        //   if (isAvailable) {
        //     setMessage('Username is available');
        //   } else {
        //     setMessage('Username is not available');
        //   }
        // });
      };
    
      // Event handler for input change
      const handleUsernameChange = (event) => {
        const { value } = event.target; 
        setUsername(value);
        // setMessage('');  
        // if (value.trim() !== '') {
        //   checkUsernameAvailability(value.trim());
        // }
      };


    const openNotificationWithIcon = (type, title, description) => {
        api[type]({
          message: title,
          description: description,
          placement: "top",
          duration: 2,
          role: "status"
        });
    };

    const getTodaysDate = function() {
        let d = new Date(),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
    
        return [year, month, day].join('-');
    };  

    const saveUser = function(payload){
        payload.date_of_birth = getTodaysDate(payload.date_of_birth);
        payload['company_id'] = JSON.parse(localStorage.getItem('user')).user.company_id;
        axios
            .post(`/api/users`, payload)
            .then(() => {
                setSelectedDepartment(null);
                onCreate();
            })
            .catch((error) => {
                 form.resetFields();
                //onError(error);
                onError(error);
            });
    };

    const editUser = function(payload){
        payload.date_of_birth = getTodaysDate(payload.date_of_birth);
        payload['company_id'] = JSON.parse(localStorage.getItem('user')).user.company_id;
        axios
            .put(`/api/users/${initialValues.user_id}`, payload)
            .then(() => {
                setSelectedDepartment(null);
                 form.resetFields();
                onCreate();
            })
            .catch((error) => {
                // form.resetFields();
                onError(error);
            });
    };

    // eslint-disable-next-line arrow-body-style
    const disabledDate = (current) => {
        // Can not select days before today and today
        return current && current > dayjs().endOf('day');
    };

    const handleDepartmentChange = (value) => { 
        setSelectedDepartment(value);
    };

    const onDOBChange = (date, dateString) => {
       // console.log(date, dateString);
    };

    return (
        <>
            <Modal
                title={edit ? "Edit User" : "Add User"}
                centered="true"
                open={open}
                okText={edit ? "Update" : "Submit"}
                mask={true}
                maskClosable={false}
                destroyOnClose
                onOk={() => {
                    form.validateFields()
                        .then((newUser) => {
                            form.resetFields();
                            if(edit){
                                editUser(newUser);
                            } else {
                                saveUser(newUser);
                            }
                        })
                        .catch((error) => { 
                            setSelectedDepartment(null);
                            openNotificationWithIcon('error', 'Error', 'OOPS! Something went wrong!');
                        });
                }}
                onCancel={() => {
                    form.resetFields();// onCancel();
                    setSelectedDepartment(null);
                    onCancel();
                }}
                width={800}
            >
 
                <Form initialValues={initialValues}
                    {...layout}
                    form={form}
                    name="add-user"
                    onFinish={onFinish}
                    style={{
                        maxWidth: 600,
                    }}
                >
                    <Form.Item
                        name="user_first_name"
                        label="First Name"
                        ref={refElement}
                        rules={[
                            {
                                required: true,
                                message: 'Please enter User First Name',
                            },
                        ]}
                    >
                        <Input placeholder='First Name' />
                    </Form.Item>
                    <Form.Item
                        name="user_middle_name"
                        label="Middle Name"
                    >
                        <Input placeholder='Middle Name' />
                    </Form.Item>
                    <Form.Item
                        name="user_last_name"
                        label="Last Name"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter User Last Name',
                            },
                        ]}
                    >
                        <Input placeholder='Last Name' />
                    </Form.Item>

                    <Form.Item 
                        name="date_of_birth"
                        label="Date Of Birth"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter User DOB',
                            },
                        ]}
                    >
                        <DatePicker
                            format="MM-DD-YYYY"
                            onChange={onDOBChange}
                            disabledDate={disabledDate}
                        />
                    </Form.Item>

                    <Form.Item name="user_name" label="User Name" rules={[
                            {
                                required: true,
                                message: 'Please enter username',
                            },
                        ]}>
                        <Input placeholder='User Name'  value={username} onChange={handleUsernameChange} 
                            
                         /> 
                        
                        {/* <Button
                            type="primary"
                            icon={<PlusOutlined />}
                            
                        >
                            Check User Name
                        </Button> */}
                    </Form.Item>
                    <p className={isAvailable === true ? 'ssssdd' : 'ss'}>{userMessage}</p> 
                    <Form.Item
                        name="password"
                        label="Password"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter Password',
                            },
                        ]}
                    >
                        <Input type='password' placeholder='Password' />
                    </Form.Item>

                    <Form.Item
                        name="user_gender"
                        size="small "
                        label="Gender"
                        rules={[
                            {
                                required: true,
                                message: 'Please select Gender',
                            },
                        ]}
                    >
                        <Select placeholder="Select Gender" allowClear>
                            <Option value="Male">Male</Option>
                            <Option value="Female">Female</Option>
                            <Option value="Other">Other</Option>
                        </Select>
                    </Form.Item>

                    <Form.Item
                        name="user_mobile"
                        label="Mobile Number"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter mobile number',
                            },
                        ]}
                    >
                        <Input
                            onChange={() => {
                                // console.log('On CHange');
                            }}
                            stringMode
                            placeholder='Mobile Number'
                        />
                    </Form.Item>

                    <Form.Item name="login_email" label="Email Id" rules={[
                            {
                                required: true,
                                message: 'Please enter email id',
                            }
                        ]}>
                        <Input placeholder='Email Address' />
                    </Form.Item>

                    <Form.Item
                        label="User Type"
                        name="user_type_lookup_id"
                        style={{ fontWeight: '500' }}
                    >
                        <CustomSelect
                            selectedValue={selectedDepartment}
                            apiEndpoint={`/api/users/${JSON.parse(localStorage.getItem('user')).user.user_id}/lookups?lookup_group=user_types&only_users=true`}
                            placeholder="Select User Type"
                            optionFormat={{
                                id: 'lookup_id',
                                label: 'lookup_value',
                            }}
                            onChange={handleDepartmentChange}
                        />
                    </Form.Item>

                    {/* <Form.Item
                        name="address"
                        label="Address"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter the Address',
                            },
                        ]}
                    >
                        <TextArea
                            rows={4}
                            placeholder="Type address here"
                            maxLength={500}
                        />
                    </Form.Item> */}
                </Form>
            </Modal>
        </>
    );
}
