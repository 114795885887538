import React, {useState} from 'react';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Form, Button, Select, Input, Row, Col, TimePicker } from 'antd';
//https://codesandbox.io/s/antd-form-list-multiple-formitem-dynamic-fields-cje76?file=/src/App.js:852-864
//https://api.drugbank.com/v1/drug_names?q=Tylenol
export default function DynamicSlots({ id, name, onTimeChange }) {
    const [timeRange, setTimeRange] = useState([null, null]);

    const labelCol = {
        span: 24,
        style: {
            // width: "100px", // this means the label will have a fixed width of 100px
            textAlign: 'ce nter',
        },
    };

    const inputMargin = {
        marginRight: '5px',
    };

    const format = 'HH:mm';

    const onDateRangeSelectd = (dates, timeString) => {
        console.log('Dates:', dates);
        console.log('timeString:', timeString);
        setTimeRange(dates);
        onTimeChange(timeString);
    };
    const disabledTime = (current, type) => {
        if (type === 'start' && timeRange && timeRange[1]) {
          // Disable times less than or equal to the end time
          return {
            disabledHours: () => {
              const endHour = timeRange[1].hour();
              return Array.from({ length: endHour }, (v, k) => k);
            },
            disabledMinutes: () => {
              const endHour = timeRange[1].hour();
              const endMinute = timeRange[1].minute();
              if (current.hour() === endHour) {
                return Array.from({ length: endMinute }, (v, k) => k);
              }
            },
          };
        } else if (type === 'end' && timeRange && timeRange[0]) {
          // Disable times less than the start time
          return {
            disabledHours: () => {
              const startHour = timeRange[0].hour();
              return Array.from({ length: startHour + 1 }, (v, k) => k);
            },
            disabledMinutes: () => {
              const startHour = timeRange[0].hour();
              const startMinute = timeRange[0].minute();
              if (current.hour() === startHour) {
                return Array.from({ length: startMinute + 1 }, (v, k) => k);
              }
            },
          };
        }
        return {};
    };
    
    return (
        <div id={id}>
            <Form.List name={name} initialValue={['']}>
                {(fields, { add, remove }) => {
                    return (
                        <div style={{ width: '100%' }}>
                            {fields.map((field, index) => {
                                return (
                                    <div key={field.key} id={id}>
                                        <Row>
                                            <Col
                                                xs={17}
                                                lg={17}
                                                style={{ ...inputMargin }}
                                            >
                                                <Form.Item
                                                    name={[index, 'field']}
                                                    label=""
                                                    labelCol={{ ...labelCol }}
                                                    key={field}
                                                    rules={[
                                                        ({ getFieldValue }) => ({
                                                          validator(_, value) {
                                                            if ((getFieldValue('startDate') || getFieldValue('endDate')) && !value) {
                                                               return Promise.reject(new Error('Please select a time if either start date or end date is selected!'));
                                                            }
                                                            return Promise.resolve();
                                                          },
                                                        }),
                                                      ]}
                                                >
                                                    <TimePicker.RangePicker
                                                        value={timeRange}
                                                        onChange={onDateRangeSelectd}
                                                        allowClear
                                                        format={format}
                                                        disabledTime={disabledTime}
                                                        minuteStep={5}
                                                        popupClassName='availability-time-picker'
                                                    />
                                                </Form.Item>
                                            </Col>
                                            {/* <Col
                                                xs={2}
                                                lg={2}
                                                style={{ ...inputMargin }}
                                            >
                                                 <Button
                                                        type="link"
                                                        onClick={() => add()}
                                                        // style={{ width: '20%' }}
                                                    >
                                                        <PlusOutlined />
                                                    </Button>
                                            </Col> */}
                                            <Col
                                                xs={2}
                                                lg={2}
                                                style={{ ...inputMargin }}
                                            >
                                                <Button
                                                    type="link"
                                                    className="dynamic-delete-button"
                                                    danger
                                                    onClick={(event) => {
                                                        console.log(
                                                            'Removing:',
                                                            event,
                                                        );
                                                        remove(field.name);
                                                    }}
                                                    icon={
                                                        <MinusCircleOutlined />
                                                    }
                                                ></Button>
                                            </Col>
                                        </Row>
                                    </div>
                                );
                            })}
                            {/* <Col xs={2} lg={2} style={{ ...inputMargin }}>
                                <Button
                                    type="link"
                                    onClick={() => add()}
                                    // style={{ width: '20%' }}
                                >
                                    <PlusOutlined />
                                </Button>
                            </Col> */}
                            <Row> 
                                <Col xs={17} lg={17} style={{ ...inputMargin }}>
                                    <Form.Item style={{ textAlign: 'center',  marginTop: "0px" }} labelCol={{span: 24}}>
                                        <Button
                                            type="dashed"
                                            onClick={() => add()}
                                            style={{ width: '100%' }}
                                        >
                                            <PlusOutlined />
                                        </Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </div>
                    );
                }}
            </Form.List>
        </div>
    );
}
