import {  Button, Space, notification,Input } from 'antd';
import { PlusOutlined,  FieldTimeOutlined, SearchOutlined } from '@ant-design/icons';
import { useState } from 'react';
import HealthPlusTable from '../../components/datatable/datatable-new';
import AddPatient from '../../components/patient/AddPatient';
//import BookAppointment from '../appointment/book-appointment';
import AddDoctor from './add-doctor';
import DoctorAvailability from './availability';
import BookAppointment from '../../components/appointment/book-appointment';
export default function Doctors() {
    const [api, contextHolder] = notification.useNotification();
    const [refreshKey, setRefreshKey] = useState(0);
    const [filters, setFilters] = useState({});
    //const [showAddPatient, setShowAddPatient] = useState(true);
    const [open, setOpen] = useState(false);
    const [openAppointmentDialog, setOpenAppointmentDialog] = useState(false);
    const [openAddDoctorDialog, setOpenAddDoctorDialog] = useState(false);
    const [openAvailabilityDialog, setOpenAvailabilityDialog] = useState(false);
    const [selectedDoctor, setSelectedDoctor] = useState({});

    const openNotificationWithIcon = (type, title, description) => {
        api[type]({
          message: title,
          description: description,
          placement: "top",
          duration: 2,
          role: "status"
        });
    };

    const onCreate = (values) => {
        console.log('Received values of form: ', values);
        setOpen(false);
        openNotificationWithIcon('success', 'Success', 'Saved schedules sucessfully!');
    };

    const openAppointmentModal = (values) => {
        setOpenAppointmentDialog(false);
    };

    const openDoctorsModal = (values) => {
        openNotificationWithIcon('success', 'Success', 'Doctor has been added sucessfully!');
        setOpenAddDoctorDialog(false);
        setRefreshKey((previousKey)=> previousKey + 1);
    };

    const openAvailabilityModal = (values) => {
        openNotificationWithIcon('success', 'Success', 'Doctor availability added successfully!');
        setOpenAvailabilityDialog(false);
    }

    // const handleFilterChange = (columnKey, value) => {
    //     const newFilters = { ...filters };
    //     newFilters[columnKey] = value.text;
    //     console.log('newFilters:', newFilters);
    //     setFilters(newFilters);
    // };

    const handleFilterChange = (columnKey, value) => {
        const newFilters = { ...filters };
        newFilters[columnKey] = value.text;
        console.log('newFilters:', newFilters);
        setFilters(newFilters);
    };

    const slots = [
        {
            time_slot_id: 1,
            name: '10:10',
            status: 'FREE',
            status_color: '#2db7f5',
        },
        {
            time_slot_id: 2,
            name: '10:20',
            status: 'FREE',
            status_color: '#2db7f5',
        },
        {
            time_slot_id: 3,
            name: '10:30',
            status: 'BOOKED',
            status_color: '#f50',
        },
        {
            time_slot_id: 4,
            name: '10:40',
            status: 'BOOKED',
            status_color: '#f50',
        },
        {
            time_slot_id: 5,
            name: '10:50',
            status: 'BOOKED',
            status_color: '#f50',
        },
        {
            time_slot_id: 6,
            name: '11:00',
            status: 'BOOKED',
            status_color: '#f50',
        },
        {
            time_slot_id: 7,
            name: '11:10',
            status: 'BOOKED',
            status_color: '#f50',
        },
        {
            time_slot_id: 8,
            name: '11:20',
            status: 'FREE',
            status_color: '#2db7f5',
        },
        {
            time_slot_id: 9,
            name: '11:30',
            status: 'FREE',
            status_color: '#2db7f5',
        },
        {
            time_slot_id: 10,
            name: '11:40',
            status: 'BOOKED',
            status_color: '#f50',
        },
        {
            time_slot_id: 11,
            name: '11:50',
            status: 'FREE',
            status_color: '#2db7f5',
        },
        {
            time_slot_id: 12,
            name: '12:00',
            status: 'BOOKED',
            status_color: '#f50',
        },
        {
            time_slot_id: 13,
            name: '12:10',
            status: 'FREE',
            status_color: '#2db7f5',
        },
        {
            time_slot_id: 14,
            name: '12:20',
            status: 'BOOKED',
            status_color: '#f50',
        },
        {
            time_slot_id: 15,
            name: '12:30',
            status: 'FREE',
            status_color: '#2db7f5',
        },
        {
            time_slot_id: 16,
            name: '12:40',
            status: 'BOOKED',
            status_color: '#f50',
        },
        {
            time_slot_id: 17,
            name: '12:50',
            status: 'FREE',
            status_color: '#2db7f5',
        },
        {
            time_slot_id: 18,
            name: '01:00',
            status: 'BOOKED',
            status_color: '#f50',
        },
        {
            time_slot_id: 19,
            name: '01:10',
            status: 'FREE',
            status_color: '#2db7f5',
        },
        {
            time_slot_id: 20,
            name: '01:20',
            status: 'BOOKED',
            status_color: '#f50',
        }
    ];

    function disableScheduleButton(record){
        let disabled = false;

        //JSON.parse(localStorage.getItem('user')).user.user_type_table_id;
        if(record.doctor_id !== JSON.parse(localStorage.getItem('user')).user.user_type_table_id){
            disabled =true;
        }
        return disabled;
    }
    //columns, url, searchText, filters, sortColumn, sortOrder, initialPageSize
    let columns = [
        {
            title: 'Doctor Id',
            dataIndex: 'doctor_id',
            sorter: false, //Disable sort on this column make it to for enabling sort
        },
        {
            title: 'First Name',
            dataIndex: 'first_name',
            sorter: false,
            filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
            }) => (
                <div style={{ padding: 8 }}>
                    <Input
                        placeholder="Search By First name"
                        value={selectedKeys[0]}
                        onChange={(e) =>
                            setSelectedKeys(
                                e.target.value ? [e.target.value] : [],
                            )
                        }
                        onPressEnter={() => {
                            handleFilterChange('first_name', {
                                text: selectedKeys[0],
                                confirm,
                            });
                        }}
                        style={{
                            width: 188,
                            marginBottom: 8,
                            display: 'block',
                        }}
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() =>
                                handleFilterChange('first_name', {
                                    text: selectedKeys[0],
                                    confirm,
                                })
                            }
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Search
                        </Button>
                        
                        <Button
                            onClick={() => {
                                clearFilters && clearFilters();
                                handleFilterChange('first_name', {
                                    text: '',
                                    confirm: null,
                                });
                            }}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Reset
                        </Button>
                    </Space>
                </div>
            ),
        },
        {
            title: 'Middle Name',
            dataIndex: 'middle_name',
            sorter: false,
            filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
            }) => (
                <div style={{ padding: 8 }}>
                    <Input
                        placeholder="Search By Middle name"
                        value={selectedKeys[0]}
                        onChange={(e) =>
                            setSelectedKeys(
                                e.target.value ? [e.target.value] : [],
                            )
                        }
                        onPressEnter={() => {
                            handleFilterChange('middle_name', {
                                text: selectedKeys[0],
                                confirm,
                            });
                        }}
                        style={{
                            width: 188,
                            marginBottom: 8,
                            display: 'block',
                        }}
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() =>
                                handleFilterChange('middle_name', {
                                    text: selectedKeys[0],
                                    confirm,
                                })
                            }
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Search
                        </Button>
                        
                        <Button
                            onClick={() => {
                                clearFilters && clearFilters();
                                handleFilterChange('middle_name', {
                                    text: '',
                                    confirm: null,
                                });
                            }}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Reset
                        </Button>
                    </Space>
                </div>
            ),
        },
        {
            title: 'Last Name',
            dataIndex: 'last_name',
            sorter: false,
            filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
            }) => (
                <div style={{ padding: 8 }}>
                    <Input
                        placeholder="Search By Last name"
                        value={selectedKeys[0]}
                        onChange={(e) =>
                            setSelectedKeys(
                                e.target.value ? [e.target.value] : [],
                            )
                        }
                        onPressEnter={() => {
                            handleFilterChange('last_name', {
                                text: selectedKeys[0],
                                confirm,
                            });
                        }}
                        style={{
                            width: 188,
                            marginBottom: 8,
                            display: 'block',
                        }}
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() =>
                                handleFilterChange('last_name', {
                                    text: selectedKeys[0],
                                    confirm,
                                })
                            }
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Search
                        </Button>
                        
                        <Button
                            onClick={() => {
                                clearFilters && clearFilters();
                                handleFilterChange('last_name', {
                                    text: '',
                                    confirm: null,
                                });
                            }}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Reset
                        </Button>
                    </Space>
                </div>
            ),
        },
        // {
        //     title: 'Qualification',
        //     dataIndex: 'qualification',
        //     sorter: false,
        // },
        // {
        //     title: 'Mobile',
        //     dataIndex: 'contact_number',
        //     sorter: false,
        // },
        {
            title: 'Mobile',
            dataIndex: 'contact_number',
            sorter: false,
            filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
            }) => (
                <div style={{ padding: 8 }}>
                    <Input
                        placeholder="Search By Mobile"
                        value={selectedKeys[0]}
                        onChange={(e) =>
                            setSelectedKeys(
                                e.target.value ? [e.target.value] : [],
                            )
                        }
                        onPressEnter={() => {
                            handleFilterChange('contact_number', {
                                text: selectedKeys[0],
                                confirm,
                            });
                        }}
                        style={{
                            width: 188,
                            marginBottom: 8,
                            display: 'block',
                        }}
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() =>
                                handleFilterChange('contact_number', {
                                    text: selectedKeys[0],
                                    confirm,
                                })
                            }
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Search
                        </Button>
                        
                        <Button
                            onClick={() => {
                                clearFilters && clearFilters();
                                handleFilterChange('contact_number', {
                                    text: '',
                                    confirm: null,
                                });
                            }}
                            // onClick={() =>
                            //     handleFilterChange('contact_number', {
                            //         text: '',
                            //         confirm: null,
                            //     })
                            // }
                            size="small"
                            style={{ width: 90 }}
                        >
                            Reset
                        </Button>
                    </Space>
                </div>
            ),
        },
        {
            title: 'Email',
            dataIndex: 'email',
            sorter: false,
            filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
            }) => (
                <div style={{ padding: 8 }}>
                    <Input
                        placeholder="Search By Email"
                        value={selectedKeys[0]}
                        onChange={(e) =>
                            setSelectedKeys(
                                e.target.value ? [e.target.value] : [],
                            )
                        }
                        onPressEnter={() => {
                            handleFilterChange('email', {
                                text: selectedKeys[0],
                                confirm,
                            });
                        }}
                        style={{
                            width: 188,
                            marginBottom: 8,
                            display: 'block',
                        }}
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() =>
                                handleFilterChange('email', {
                                    text: selectedKeys[0],
                                    confirm,
                                })
                            }
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Search
                        </Button>
                        
                        <Button
                            onClick={() => {
                                clearFilters && clearFilters();
                                handleFilterChange('email', {
                                    text: '',
                                    confirm: null,
                                });
                            }}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Reset
                        </Button>
                    </Space>
                </div>
            ),
        },
        {
            title: 'Department',
            dataIndex: 'department_name',
            sorter: false,
            filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
            }) => (
                <div style={{ padding: 8 }}>
                    <Input
                        placeholder="Search By Department"
                        value={selectedKeys[0]}
                        onChange={(e) =>
                            setSelectedKeys(
                                e.target.value ? [e.target.value] : [],
                            )
                        }
                        onPressEnter={() => {
                            handleFilterChange('department_name', {
                                text: selectedKeys[0],
                                confirm,
                            });
                        }}
                        style={{
                            width: 188,
                            marginBottom: 8,
                            display: 'block',
                        }}
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() =>
                                handleFilterChange('department_name', {
                                    text: selectedKeys[0],
                                    confirm,
                                })
                            }
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Search
                        </Button>
                        
                        <Button
                            onClick={() => {
                                clearFilters && clearFilters();
                                handleFilterChange('department_name', {
                                    text: '',
                                    confirm: null,
                                });
                            }}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Reset
                        </Button>
                    </Space>
                </div>
            ),
        },
        {
            title: 'Speciality',
            dataIndex: 'specialty_name',
            sorter: false,
            filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
            }) => (
                <div style={{ padding: 8 }}>
                    <Input
                        placeholder="Search By Speciality"
                        value={selectedKeys[0]}
                        onChange={(e) =>
                            setSelectedKeys(
                                e.target.value ? [e.target.value] : [],
                            )
                        }
                        onPressEnter={() => {
                            handleFilterChange('specialty_name', {
                                text: selectedKeys[0],
                                confirm,
                            });
                        }}
                        style={{
                            width: 188,
                            marginBottom: 8,
                            display: 'block',
                        }}
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() =>
                                handleFilterChange('specialty_name', {
                                    text: selectedKeys[0],
                                    confirm,
                                })
                            }
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Search
                        </Button>
                        
                        <Button
                            onClick={() => {
                                clearFilters && clearFilters();
                                handleFilterChange('specialty_name', {
                                    text: '',
                                    confirm: null,
                                });
                            }}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Reset
                        </Button>
                    </Space>
                </div>
            ),
        },
        // {
        //     title: 'Book Appointment',
        //     key: 'action',
        //     render: (text, record, index) => (
        //       <Space size="middle">
        //         <Button shape="circle" icon={<ScheduleOutlined />} onClick={e => {
        //             console.log(record); // this will log the row data
        //             //setSelectedDoctor(record);
        //             setSelectedDoctor(() => record);
        //             console.log('setSelectedDoctor:', selectedDoctor);
        //             setOpenAppointmentDialog(true);
        //         }} />
        //       </Space>
        //     ),
        // },
        {
            title: 'Schedule',
            key: 'action',
            render: (text, record, index) => (
              <Space size="middle">
                <Button shape="circle" icon={<FieldTimeOutlined/>} disabled={disableScheduleButton(record)} onClick={e => {
                    setSelectedDoctor(() => record);
                    setOpenAvailabilityDialog(true);
                }} />
              </Space>
            ),
        },
    ];

    const url = '/api/doctors';
    const sortColumn = 'doctor_id';
    const sortOrder = 'asc';
    const initialPageSize = 10;
    return (
        <div className='pad-tb10 marg-lr10'>
            {contextHolder}
            <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => {
                    setOpenAddDoctorDialog(true);
                }}
            >
                Add Doctor
            </Button>
            
            <AddPatient
                open={open}
                onCreate={onCreate}
                onCancel={() => {
                    setOpen(false);
                }}
            ></AddPatient>

            <BookAppointment
                open={openAppointmentDialog}
                slots={slots}
                onCreate={(openAppointmentModal)}
                onCancel={() => {
                    setOpenAppointmentDialog(false);
                }}
                doctor={selectedDoctor}
            ></BookAppointment>
 
            <AddDoctor open={openAddDoctorDialog}
                onCreate={openDoctorsModal}
                onCancel={() => {
                    setOpenAddDoctorDialog(false);
                }}
                onError={() => {
                    openNotificationWithIcon('error', 'Error', 'Doctor Could not be added!');
                    setOpenAddDoctorDialog(false);
                }}>
            </AddDoctor>

            <DoctorAvailability open={openAvailabilityDialog}
                onCreate={openAvailabilityModal}
                onCancel={() => {
                    setOpenAvailabilityDialog(false);
                    //openNotificationWithIcon('error', 'Error', 'Error in saving schedule!');
                }}
                doctor={selectedDoctor}
                >
                
            </DoctorAvailability>

            <HealthPlusTable
                columns={columns}
                apiUrl={url}
                filters={filters}
                sortColumn={sortColumn}
                sortOrder={sortOrder}
                initialPageSize={initialPageSize}
                refreshKey= {refreshKey}
            ></HealthPlusTable>
        </div>
    );
}
