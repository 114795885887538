import React, { useEffect, useState } from 'react';
import { Card, Avatar, Space } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import axios from '../../services/axios';
const { Meta } = Card;

export default function NextAppointments({ doctorId }) {
    const [appointments, setAppointments] = useState([]);

    useEffect(() => {
        axios.get(`/api/doctors/${doctorId}/dashboard/nextappointments`)
            .then((response) => {
                if (response && response.data) {
                    setAppointments(response.data); // Set all appointments
                }
            })
            .catch((error) => {
                console.log('Error in Getting next appointments:', error);
            });
    }, [doctorId]);

    return (
        <Card 
            className="pad-tb10 dashboard-my-prescriptions"
            style={{ minHeight: '350px'}}
            title={
                <div>
                    Next Appointments
                    <div style={{ float: 'right' }}>
                        <div
                            style={{
                                float: 'right',
                                marginLeft: '30px',
                            }}
                        >
                            <Link to="/appointments">View All</Link>
                        </div>
                    </div>
                </div>
            }
            bordered={true}
        >
            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
                {appointments.length > 0 ? (
                    <>
                        {appointments.map((appointment, index) => (
                            <Card key={index} style={{ width: 'calc(50% - 8px)' }} className="dashboard-my-prescriptions" bordered={false}>
                                <Space direction="horizontal" size={16} align="center">
                                    <Avatar
                                        icon={<UserOutlined />}
                                        size={64}
                                        src={appointment.avatarSrc}
                                        style={{ borderRadius: '50%' }}
                                    />
                                    <div style={{ textAlign: 'left', flexGrow: 1 }}>
                                        <Meta
                                            title={appointment.patient_first_name}
                                            description={appointment.time_slot_time}
                                            style={{ textAlign: 'left' }}
                                        />
                                    </div>
                                </Space>
                            </Card>
                        ))}
                    </>
                ): <p>No scheduled Appointments &#128524;!</p>}
            </div>
        </Card>
    );
}
