import { useState } from 'react';
import { Button, Space, Modal, Tooltip, notification, Popconfirm  } from 'antd';
import { FaPrescription } from 'react-icons/fa';
import {
    DeleteOutlined
} from '@ant-design/icons';
import HealthPlusTable from '../../components/datatable/datatable-new';
import PreviewPrescription from './preview-prescription';
import axios from '../../services/axios';

export default function DoctorPrescriptions({patient, refreshKey, setRefreshKey}) {
    const [filters, setFilters] = useState({patientId:patient.patient_id, doctorId: patient.doctor_id});
    const [openPrescriptionModal, setOpenPrescriptionModal] = useState(false);
    const [selectedPrescriptionRecord, setSelectedPrescriptionRecord] = useState({});
    const [api, contextHolder] = notification.useNotification();

    const openNotificationWithIcon = (type, title, description) => {
        api[type]({
          message: title,
          description: description,
          placement: "top",
          duration: 2,
          role: "status"
        });
    };

    const formatDate = function(date){
        let formattedDate = new Date(date);
        return `0${formattedDate.getDate().toString().slice(-2)}-0${formattedDate.getMonth()+1}-${formattedDate.getFullYear()}`
    };

    const onGeneratePrescriptionClick = function (record) {
        setSelectedPrescriptionRecord(() => {
            console.log('record:', record);
            return record;
        });
        setOpenPrescriptionModal(true);
    };

    const onDeletePrescriptionClick = function (record) {
        console.log('onDeletePrescriptionClick record:', record);
        axios.delete(`/api/doctors/${record.doctor_id}/prescriptions/${record.prescription_id}`)
        .then(function () {
            setRefreshKey(); //TO refresh teh Grid
            openNotificationWithIcon('success', 'Success', 'Prescription Has been saved sucessfully!');
        })
        .catch(function (error) {
            console.log('Error in saving the prescription error:', error);
            openNotificationWithIcon('error', 'Error', 'OOPS! SOmething went wrong while saving the Prescription.');
        });
    };

    let columns = [
        {
            title: 'Episode Number',
            dataIndex: 'episode_number',
            sorter: true,
        },
        {
            title: 'Prescription Id',
            dataIndex: 'prescription_id',
            sorter: true,
        },
        {
            title: 'Visited Date',
            dataIndex: 'prescription_date',
            sorter: true,
            // render: (text, record) => ({
            //     children: <span>{formatDate(text)}</span>,
            // })
        },
        {
            title: 'Hospital Number',
            dataIndex: 'icard_number',
            sorter: true,
        },
        {
            title: 'Patient Name',
            dataIndex: 'patient_first_name',
            sorter: true
        },
        {
            title: 'Patient Middle Name',
            dataIndex: 'patient_middle_name',
            sorter: true,
        },
        {
            title: 'Last Name',
            dataIndex: 'patient_last_name',
            sorter: true,
        },
        {
            title: 'Gender',
            dataIndex: 'patient_gender',
            sorter: true,
        },
        {
            title: 'Actions',
            key: 'action',
            render: (_, record) => (
                <>
                    <Space size="middle">
                        <Tooltip placement="top" title="View Prescription">
                            <Button
                                shape="circle"
                                icon={<FaPrescription />}
                                onClick={(e) => {
                                    onGeneratePrescriptionClick(record);
                                }}
                            />
                        </Tooltip>
                    </Space>
                    <Space size="middle">
                        <Popconfirm
                            title="Delete Prescription"
                            description="Are you sure to delete this Prescription?"
                            onConfirm={(e) => {
                                onDeletePrescriptionClick(record);
                            }}
                            onCancel={()=>{ console.log('Do not delete....'); }}
                            okText="Delete"
                            cancelText="No"
                        >
                            <Tooltip placement="top" title="Delete Prescription">
                                <Button style={{ marginTop: "3px"}}
                                    shape="circle"
                                    icon={<DeleteOutlined />}
                                    // onClick={(e) => {
                                    //     onDeletePrescriptionClick(record);
                                    // }}
                                />
                            </Tooltip>
                        </Popconfirm>
                    </Space>
                </>
                
                // <Space size="middle">
                //     <Button
                //         shape="circle"
                //         icon={<FaPrescription />}
                //         onClick={(e) => {
                //             alert('Open prescription for this visit...');
                //         }}
                //     />
                // </Space>
            ),
        },
    ];

    console.log('doctor-prescription patient:', patient);
    const url = `/api/doctors/${patient.doctor_id}/prescriptions`;
    const sortColumn = 'prescription_id';
    const sortOrder = 'desc';
    const initialPageSize = 3;
    return (
        <div className='pad-tb10 marg-lr10'>
            {contextHolder}
            <HealthPlusTable
                columns={columns}
                apiUrl={url}
                filters={filters}
                sortColumn={sortColumn}
                sortOrder={sortOrder}
                initialPageSize={initialPageSize}
                refreshKey= {refreshKey}
                //refreshKey= {gridRefreshKey}
            ></HealthPlusTable>

            <Modal
                title="Prescription"
                centered="true"
                open={openPrescriptionModal}
                okText="Generate"
                // okButtonProps={{ icon: <ScheduleOutlined /> }}
                // cancelButtonProps={{
                //     danger: 'danger',
                //     icon: <MdFreeCancellation />,
                // }}
                mask={true}
                destroyOnClose={true}
                maskClosable={false}
                // onOk={() => {
                    
                // }}
                onCancel={() => {
                    console.log("typeof onCancel", typeof onCancel);
                    // onCancel();
                    setOpenPrescriptionModal(false);
                }}
                width={'1200px'}
                footer={null}
            >
                <PreviewPrescription prescriptionDetails={selectedPrescriptionRecord}></PreviewPrescription>
            </Modal>

            {/* <GeneratePrescription
                open={openPrescriptionModal}
                onCreate={onCreatePrescriptionModal}
                onCancel={() => {
                    setOpenPrescriptionModal(false);
                }}
                patient={selectedpatient}
            ></GeneratePrescription> */}
        </div>
    );
}
