import React from 'react';
import { Form, Input, Modal, Select, DatePicker, Radio } from 'antd';
import dayjs from 'dayjs';
import axios from '../services/axios';
const { Option } = Select;
const { TextArea } = Input;

const layout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 16,
    },
};

// const tailLayout = {
//     wrapperCol: {
//         offset: 8,
//         span: 16,
//     },
// };

export default function Inquiry({ open, onCreate, onCancel, onError }) {
    const [form] = Form.useForm();

    const onFinish = (newInquiry) => {
        console.log('newInquiry:', newInquiry);
        axios
            .post(`/api/inquiry`, newInquiry)
            .then(() => {
                onCreate();
            })
            .catch((error) => {
                onError(error);
            });
    };

    return (
        <>
            <Modal
                title="Contact Details"
                centered="true"
                open={open}
                mask={true}
                maskClosable={false}
                destroyOnClose
                okText="Submit"
                onOk={() => {
                    form.validateFields()
                        .then((contact) => {
                            form.resetFields();
                            onFinish(contact);
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info);
                        });
                }}
                onCancel={() => {
                    form.resetFields();
                    onCancel();
                }}
                width={700}
            >
                <Form
                    {...layout}
                    form={form}
                    name="inquery-form"
                    onFinish={onFinish}
                    style={{
                        maxWidth: 600,
                    }}
                >
                    <Form.Item
                        name="contact_first_name"
                        label="First Name"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter patient First Name',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="contact_last_name"
                        label="Last Name"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter Last Name',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        name="contact_mobile"
                        label="Mobile Number"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter mobile number',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        name="contact_email"
                        label="Email Id"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter Email Id',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        name="contact_city"
                        label="City"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter City',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        name="contact_state"
                        label="State"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter State',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
}
