import React from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

// const borderColor = '#90e5fc';
const borderColor = '#000';
const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        // borderBottomColor: '#bff0fd',
        borderBottomColor: '#000',
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 24,
        fontSize: 12,
        fontStyle: 'bold',
    },
    description: {
        width: '85%',
        textAlign: 'right',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingRight: 8,
    },
    total: {
        width: '15%',
        textAlign: 'right',
        paddingRight: 8,
    }
});


const InvoiceTableFooter = ({invoice}) => {
    // const total = items.map(item => item.qty * item.rate)
    //     .reduce((accumulator, currentValue) => accumulator + currentValue , 0);

    return( 
        <>
            <View style={styles.row}>
                <Text style={styles.description}>Total</Text>
                <Text style={styles.total}>{ Number.parseFloat(invoice.total_amount).toFixed(2)}</Text>
            </View>

            <View style={styles.row}>
                <Text style={styles.description}>Amount Paid</Text>
                <Text style={styles.total}>{ Number.parseFloat(invoice.total_amount).toFixed(2)}</Text>
            </View>
        </>   
    )
};
  
  export default InvoiceTableFooter;