import React, {Fragment} from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

// const borderColor = '#90e5fc';
const borderColor = '#000';
const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        // borderBottomColor: '#bff0fd',
        borderBottomColor: '#000',
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 24,
        fontStyle: 'bold',
    },
    slno: {
        width: '10%',
        textAlign: 'left',
        borderRightColor: borderColor,
        borderRightWidth: '1',
        paddingLeft: 8,
    },
    particular: {
        width: '45%',
        textAlign: 'left',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingLeft: 8,
    },
    qty: {
        width: '15%',
        borderRightColor: borderColor,
        borderRightWidth: '1',
        textAlign: 'right',
        paddingRight: 8,
    },
    rate: {
        width: '15%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'right',
        paddingRight:8,
    },
    amount: {
        width: '15%',
        textAlign: 'right',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingRight: 8,
    },
    total: {
        width: '15%',
        textAlign: 'right',
        paddingRight: 8,
    },
    amountpaid: {
        width: '15%',
        textAlign: 'right',
        paddingRight: 8,
    }
  });
  

const InvoiceTableRow = ({items}) => {
    // const rows = items.map( item => 
    //     <View style={styles.row} key={item.sl_no.toString()}>
    //         <Text style={styles.slno}>{item.sl_no}</Text>
    //         <Text style={styles.particular}>{item.particular}</Text>
    //         <Text style={styles.qty}>{item.rate}</Text>
    //         <Text style={styles.rate}>{item.discount}</Text>
    //         <Text style={styles.amount}>{item.total}</Text>
    //         <Text style={styles.amountpaid}>{item.amount_paid}</Text>
    //     </View>
    // )
    const rows = items.map( item => 
        <View style={styles.row} key={item.sl_no.toString()}>
            <Text style={styles.slno}>{item.sl_no}</Text> 
            <Text style={styles.particular}>{item.particular}</Text>
            <Text style={styles.qty}>{item.rate}</Text>
            <Text style={styles.rate}>{item.discount}</Text>
            <Text style={styles.total}>{item.total}</Text>
            {/* <Text style={styles.amountpaid}>1100</Text> */}
        </View>
    )
    return (<Fragment>{rows}</Fragment> )
};
  
  export default InvoiceTableRow