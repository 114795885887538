import { Menu } from 'antd';
import { Link } from 'react-router-dom';

export default function ProfileMenu({logout}) {
    return (
        <Menu>
            <Menu.Item key="profile">
                <Link to="/profile">Profile</Link>
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item key="logout">
                <Link to="/logout" onClick={logout}>Logout</Link>
            </Menu.Item>
        </Menu>
    );
}