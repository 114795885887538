import { Button, Space, notification, Input, Tooltip, Form } from 'antd';
import {
    PlusOutlined,
    SearchOutlined,
    EditOutlined,
    DeleteOutlined,
    UserAddOutlined
} from '@ant-design/icons';
import { useState } from 'react';
import HealthPlusTable from '../../components/datatable/datatable-new';
import axios from '../../services/axios';
//import BookAppointment from '../appointment/book-appointment';
import AddUser from './add-user';

export default function Users() {
    const [api, contextHolder] = notification.useNotification();
    const [refreshKey, setRefreshKey] = useState(0);
    const [filters, setFilters] = useState({}); 
    const [openAddUserDialog, setOpenAddUserDialog] = useState(false);
    const [form] = Form.useForm();

    const [selectedUser, setSelectedUser] = useState(null);
    const [isEditFlow, setIsEditFlow] = useState(false);

    const openNotificationWithIcon = (type, title, description) => {
        api[type]({
            message: title,
            description: description,
            placement: 'top',
            duration: 2,
            role: 'status',
        });
    };

    const onCreateUserModal = (values) => {
        openNotificationWithIcon(
            'success',
            'Success',
            'User has been added sucessfully!',
        );
        setSelectedUser(null);
        setOpenAddUserDialog(false); 
        setRefreshKey((previousKey) => previousKey + 1);
    };

    // const handleFilterChange = (columnKey, value) => {
    //     const newFilters = { ...filters };
    //     newFilters[columnKey] = value.text;
    //     console.log('newFilters:', newFilters);
    //     setFilters(newFilters);
    // };

    const handleFilterChange = (columnKey, value) => {
        const newFilters = { ...filters };
        newFilters[columnKey] = value.text; 
        setFilters(newFilters);
    };

    const onDeleteUserClick = function (record) { 
        axios
            .delete(`/api/users/${record.user_id}?is_active=0`)
            .then(() => {
                setSelectedUser(null);
                setRefreshKey((previousKey) => previousKey + 1);
                openNotificationWithIcon(
                    'success',
                    'Success',
                    'User Deleted Successfully!',
                );
            }) 
            .catch(() => {
                openNotificationWithIcon(
                    'error',
                    'Error',
                    'OOPS! Something went wrong!',
                );
            });
    };

    const onActivateUserClick = function (record) {
        axios
            .delete(`/api/users/${record.user_id}?is_active=1`)
            .then(() => {
                setSelectedUser(null);
                setRefreshKey((previousKey) => previousKey + 1);
                openNotificationWithIcon(
                    'success',
                    'Success',
                    'User activated Successfully!',
                );
            }) 
            .catch(() => {
                openNotificationWithIcon(
                    'error',
                    'Error',
                    'OOPS! Something went wrong!',
                );
            });
    };

    const onEditUserClick = function (record) { 
        setOpenAddUserDialog(true);
        setIsEditFlow(true);
        setSelectedUser(record);
    };

    let columns = [
        {
            title: 'User Id',
            dataIndex: 'user_id',
            sorter: false, //Disable sort on this column make it to for enabling sort
        },
        {
            title: 'First Name',
            dataIndex: 'user_first_name',
            sorter: false,
            filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
            }) => (
                <div style={{ padding: 8 }}>
                    <Input
                        placeholder="Search By First name"
                        value={selectedKeys[0]}
                        onChange={(e) =>
                            setSelectedKeys(
                                e.target.value ? [e.target.value] : [],
                            )
                        }
                        onPressEnter={() => {
                            handleFilterChange('user_first_name', {
                                text: selectedKeys[0],
                                confirm,
                            });
                        }}
                        style={{
                            width: 188,
                            marginBottom: 8,
                            display: 'block',
                        }}
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() =>
                                handleFilterChange('user_first_name', {
                                    text: selectedKeys[0],
                                    confirm,
                                })
                            }
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Search
                        </Button>

                        <Button
                            onClick={() => {
                                clearFilters && clearFilters();
                                handleFilterChange('user_first_name', {
                                    text: '',
                                    confirm: null,
                                });
                            }}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Reset
                        </Button>
                    </Space>
                </div>
            ),
        },
        {
            title: 'Middle Name',
            dataIndex: 'user_middle_name',
            sorter: false,
            filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
            }) => (
                <div style={{ padding: 8 }}>
                    <Input
                        placeholder="Search By Middle name"
                        value={selectedKeys[0]}
                        onChange={(e) =>
                            setSelectedKeys(
                                e.target.value ? [e.target.value] : [],
                            )
                        }
                        onPressEnter={() => {
                            handleFilterChange('user_middle_name', {
                                text: selectedKeys[0],
                                confirm,
                            });
                        }}
                        style={{
                            width: 188,
                            marginBottom: 8,
                            display: 'block',
                        }}
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() =>
                                handleFilterChange('user_middle_name', {
                                    text: selectedKeys[0],
                                    confirm,
                                })
                            }
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Search
                        </Button>

                        <Button
                            onClick={() => {
                                clearFilters && clearFilters();
                                handleFilterChange('user_middle_name', {
                                    text: '',
                                    confirm: null,
                                });
                            }}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Reset
                        </Button>
                    </Space>
                </div>
            ),
        },
        {
            title: 'Last Name',
            dataIndex: 'user_last_name',
            sorter: false,
            filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
            }) => (
                <div style={{ padding: 8 }}>
                    <Input
                        placeholder="Search By Last name"
                        value={selectedKeys[0]}
                        onChange={(e) =>
                            setSelectedKeys(
                                e.target.value ? [e.target.value] : [],
                            )
                        }
                        onPressEnter={() => {
                            handleFilterChange('user_last_name', {
                                text: selectedKeys[0],
                                confirm,
                            });
                        }}
                        style={{
                            width: 188,
                            marginBottom: 8,
                            display: 'block',
                        }}
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() =>
                                handleFilterChange('user_last_name', {
                                    text: selectedKeys[0],
                                    confirm,
                                })
                            }
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Search
                        </Button>

                        <Button
                            onClick={() => {
                                clearFilters && clearFilters();
                                handleFilterChange('user_last_name', {
                                    text: '',
                                    confirm: null,
                                });
                            }}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Reset
                        </Button>
                    </Space>
                </div>
            ),
        },
        {
            title: 'Mobile',
            dataIndex: 'user_mobile',
            sorter: false,
            filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
            }) => (
                <div style={{ padding: 8 }}>
                    <Input
                        placeholder="Search By Mobile"
                        value={selectedKeys[0]}
                        onChange={(e) =>
                            setSelectedKeys(
                                e.target.value ? [e.target.value] : [],
                            )
                        }
                        onPressEnter={() => {
                            handleFilterChange('user_mobile', {
                                text: selectedKeys[0],
                                confirm,
                            });
                        }}
                        style={{
                            width: 188,
                            marginBottom: 8,
                            display: 'block',
                        }}
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() =>
                                handleFilterChange('user_mobile', {
                                    text: selectedKeys[0],
                                    confirm,
                                })
                            }
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Search
                        </Button>

                        <Button
                            onClick={() => {
                                clearFilters && clearFilters();
                                handleFilterChange('user_mobile', {
                                    text: '',
                                    confirm: null,
                                });
                            }}
                            // onClick={() =>
                            //     handleFilterChange('contact_number', {
                            //         text: '',
                            //         confirm: null,
                            //     })
                            // }
                            size="small"
                            style={{ width: 90 }}
                        >
                            Reset
                        </Button>
                    </Space>
                </div>
            ),
        },
        {
            title: 'Email',
            dataIndex: 'user_email',
            sorter: false,
            filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
            }) => (
                <div style={{ padding: 8 }}>
                    <Input
                        placeholder="Search By Email"
                        value={selectedKeys[0]}
                        onChange={(e) =>
                            setSelectedKeys(
                                e.target.value ? [e.target.value] : [],
                            )
                        }
                        onPressEnter={() => {
                            handleFilterChange('user_email', {
                                text: selectedKeys[0],
                                confirm,
                            });
                        }}
                        style={{
                            width: 188,
                            marginBottom: 8,
                            display: 'block',
                        }}
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() =>
                                handleFilterChange('user_email', {
                                    text: selectedKeys[0],
                                    confirm,
                                })
                            }
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Search
                        </Button>

                        <Button
                            onClick={() => {
                                clearFilters && clearFilters();
                                handleFilterChange('user_email', {
                                    text: '',
                                    confirm: null,
                                });
                            }}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Reset
                        </Button>
                    </Space>
                </div>
            ),
        },
        {
            title: 'User Type',
            dataIndex: 'lookup_value',
            sorter: false,
            filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
            }) => (
                <div style={{ padding: 8 }}>
                    <Input
                        placeholder="Search By User Type"
                        value={selectedKeys[0]}
                        onChange={(e) =>
                            setSelectedKeys(
                                e.target.value ? [e.target.value] : [],
                            )
                        }
                        onPressEnter={() => {
                            handleFilterChange('lookup_value', {
                                text: selectedKeys[0],
                                confirm,
                            });
                        }}
                        style={{
                            width: 188,
                            marginBottom: 8,
                            display: 'block',
                        }}
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() =>
                                handleFilterChange('lookup_value', {
                                    text: selectedKeys[0],
                                    confirm,
                                })
                            }
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Search
                        </Button>

                        <Button
                            onClick={() => {
                                clearFilters && clearFilters();
                                handleFilterChange('lookup_value', {
                                    text: '',
                                    confirm: null,
                                });
                            }}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Reset
                        </Button>
                    </Space>
                </div>
            ),
        },
        {
            title: 'User Is Active?',
            dataIndex: 'is_active',
            sorter: false,
            render: (_, record) => (
                <Space size="middle">
                   <text>{record.is_active === 1 ? 'Yes' : 'No'}</text>
                </Space>
            ),
            filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
            }) => (
                <div style={{ padding: 8 }}>
                    <Input
                        placeholder="Search By User Type"
                        value={selectedKeys[0]}
                        onChange={(e) =>
                            setSelectedKeys(
                                e.target.value ? [e.target.value] : [],
                            )
                        }
                        onPressEnter={() => {
                            handleFilterChange('is_active', {
                                text: selectedKeys[0],
                                confirm,
                            });
                        }}
                        style={{
                            width: 188,
                            marginBottom: 8,
                            display: 'block',
                        }}
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() =>
                                handleFilterChange('is_active', {
                                    text: selectedKeys[0],
                                    confirm,
                                })
                            }
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Search
                        </Button>

                        <Button
                            onClick={() => {
                                clearFilters && clearFilters();
                                handleFilterChange('is_active', {
                                    text: '',
                                    confirm: null,
                                });
                            }}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Reset
                        </Button>
                    </Space>
                </div>
            ),
        },
        {
            title: 'Actions',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    {/* <Tooltip placement="top" title="Edit User">
                        <Button
                            shape="circle"
                            icon={<EditOutlined />}
                            onClick={(e) => {
                                onEditUserClick(record);
                            }}
                        />
                    </Tooltip> */}
                        
                    {parseInt(record.is_active) !== 0 && <Tooltip placement="top" title="Delete User">
                        <Button
                            shape="circle"
                            icon={<DeleteOutlined />}
                            onClick={(e) => {
                                onDeleteUserClick(record);
                            }}
                        />
                    </Tooltip>}
                    
                    {parseInt(record.is_active) === 0 && <Tooltip placement="top" title="Activate User">
                        <Button
                            shape="circle"
                            icon={<UserAddOutlined />}
                            onClick={(e) => {
                                onActivateUserClick(record);
                            }}
                        />
                    </Tooltip>}
                    
                </Space>
            ),
        },
    ];

    const url = '/api/users';
    const sortColumn = 'user_id';
    const sortOrder = 'desc';
    const initialPageSize = 10;
    return (
        <div className="pad-tb10 marg-lr10">
            {contextHolder}
            <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => {
                    form.resetFields();
                    setIsEditFlow(false);
                    setOpenAddUserDialog(true);
                }}
            >
                Register User
            </Button>
           
            <AddUser initialValues = {selectedUser} 
                edit={isEditFlow}
                open={openAddUserDialog}
                onCreate={onCreateUserModal}
                onCancel={() => {
                    setOpenAddUserDialog(false);
                    setSelectedUser(null);
                }}
                onError={() => {
                    openNotificationWithIcon(
                        'error',
                        'Error',
                        'User Could not be Registered!',
                    );
                    setSelectedUser(null);
                }}
            ></AddUser>

            <HealthPlusTable
                columns={columns}
                apiUrl={url}
                filters={filters}
                sortColumn={sortColumn}
                sortOrder={sortOrder}
                initialPageSize={initialPageSize}
                refreshKey={refreshKey}
            ></HealthPlusTable>
        </div>
    );
}
