import { Card } from 'antd';
import PatientLastVisits from './patient-visits';
export default function LastVisits(){
    return (
        <div>
            <Card  className='dashboard-my-prescriptions' style={{marginBottom: '20px'}}
                title="Last Visits"
                bordered={true}
                
            >
                <div>
                    <PatientLastVisits></PatientLastVisits>
                </div>
            </Card>
        </div>
    );
}