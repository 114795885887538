// import React, { useState } from 'react';
// import { Input, Tag} from 'antd';
// import PropTypes from 'prop-types';

// const TagInput = ({ placeholder, size, onTagsChange, values = [] }) => {
//     const [inputValue, setInputValue] = useState('');
//     const [tags, setTags] = useState(values);

//     const handleInputChange = (e) => {
//         setInputValue(e.target.value);
//     };

//     const handleInputConfirm = () => {
//         if (inputValue && !tags.includes(inputValue)) {
//             const newTags = [...tags, inputValue];
//             setTags(newTags);
//             onTagsChange(newTags);
//             setInputValue('');
//         }
//     };

//     const handleTagClose = (removedTag) => {
//         const updatedTags = tags.filter(tag => tag !== removedTag);
//         setTags(updatedTags);
//         onTagsChange(updatedTags);
//     };

//     return (
//         <div>
//             <div>
//                 {tags.map((tag, index) => (
//                     <Tag className='prescription-tag'
//                         key={tag}
//                         size="large"
//                         closable
//                         onClose={() => handleTagClose(tag)}
//                     >
//                         {tag}
//                     </Tag>
//                 ))}
//             </div>
            
//             <Input
//                 type="text"
//                 size={size}
//                 value={inputValue}
//                 onChange={handleInputChange}
//                 onBlur={handleInputConfirm}
//                 onPressEnter={handleInputConfirm}
//                 placeholder={placeholder}
//                 style={{ width:'70%', marginBottom: '8px', marginTop: "30px" }}
//             />
//         </div>
//     );
// };

// TagInput.propTypes = {
//     placeholder: PropTypes.string,
//     size: PropTypes.string,
//     onTagsChange: PropTypes.func.isRequired,
// };

// TagInput.defaultProps = {
//     placeholder: 'Enter a tag',
//     size: 'small',
// };

// export default TagInput;


import React, { useState } from 'react';
import { AutoComplete, Tag } from 'antd';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import axios from '../services/axios';

const TagInput = ({ placeholder, size, onTagsChange, values = [], type }) => {
    const [inputValue, setInputValue] = useState('');
    const [tags, setTags] = useState(values);
    const [options, setOptions] = useState([]);
    //let apiEndpoint = '';
    // console.log('apiEndpoint:', apiEndpoint);
    // switch(type){ 
    //     case "complaints": apiEndpoint = `/api/cache/search/diagnosis`; break; 
    //     case "advice": apiEndpoint = `/api/cache/search/diagnosis`; break; 
    //     case "tests": apiEndpoint = `/api/cache/search/diagnosis`; break; 
    //     case "diagnosis": apiEndpoint = `/api/cache/search/diagnosis`; break; 
    //     default: apiEndpoint = ``; break; 
    // }

    const handleInputChange = (value) => {
        console.log("Hit key", value);
        setInputValue(value);
        //Currently not using autosearch for advice tag input
        if (value) {
            fetchSuggestions(value);
        } else {
            setOptions([]);
        }
    };

   
    const handleInputConfirm = () => {
        if (inputValue && !tags.includes(inputValue)) {
            const newTags = [...tags, inputValue];
            setTags(newTags);
            onTagsChange(newTags);
            setInputValue('');
            setOptions([]);
        }
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleInputConfirm();
        }
    };


    const handleTagClose = (removedTag) => {
        const updatedTags = tags.filter(tag => tag !== removedTag);
        setTags(updatedTags);
        onTagsChange(updatedTags);
    };

    const handleSelect = (value) => {
        if (!tags.includes(value)) {
            const newTags = [...tags, value];
            setTags(newTags);
            onTagsChange(newTags);
        }
        setInputValue('');
        setOptions([]);
    };

    const fetchSuggestions = debounce((value) => {
        axios.get(`/api/cache/search?type=${type}&term=${value}`).then((response)=>{
            let data = response.data;
            setOptions(data.map(tag => ({ value: tag })));
        }).catch((error)=>{
            console.error('Error in fetching the diagnosis data....', error);
        });
        // // Replace with your actual API call
        // fetch(`/api/cache/search/diagnosis?term=${value}`)
        //     .then(response => response.json())
        //     .then(data => {
        //         setOptions(data.map(tag => ({ value: tag })));
        // });
    }, 300);

    return (
        <div>
            <div>
                {tags.map((tag, index) => (
                    <Tag
                        className='prescription-tag'
                        key={tag}
                        size="large"
                        closable
                        onClose={() => handleTagClose(tag)}
                    >
                        {tag}
                    </Tag>
                ))}
            </div>
            
            <AutoComplete
                value={inputValue}
                options={options}
                onChange={handleInputChange}
                onSelect={handleSelect}
                onBlur={handleInputConfirm}
                onPressEnter={handleInputConfirm}
                onKeyDown={handleKeyDown}
                placeholder={placeholder}
                style={{ width: '70%', marginBottom: '8px', marginTop: '30px' }}
            />
        </div>
    );
};

TagInput.propTypes = {
    placeholder: PropTypes.string,
    size: PropTypes.string,
    onTagsChange: PropTypes.func.isRequired,
    values: PropTypes.array,
};

TagInput.defaultProps = {
    placeholder: 'Enter a tag',
    size: 'small',
    values: [],
};

export default TagInput;
