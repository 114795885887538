import React, { useRef, useState } from 'react';
import { Form, Input, Modal, Select, DatePicker } from 'antd';
import dayjs from 'dayjs';
import axios from '../../services/axios';
import CustomSelect from '../dropdown';
const { Option } = Select;
const { TextArea } = Input;

const layout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 16,
    },
};

export default function AddDoctor({ open, onCreate, onCancel, onError }) {
    const refElement = useRef(null);
    const [selectedSpecialty, setSelectedSpecialty] = useState(null);
    const [selectedDepartment, setSelectedDepartment] = useState(null);

    const [form] = Form.useForm();
    const onFinish = (values) => {
        console.log(values);
    };

    const getTodaysDate = function (date) {
        let d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;

        return [year, month, day].join('-');
    };

    const saveDoctor = function(payload){
        payload.date_of_birth = getTodaysDate(payload.date_of_birth);
        payload['user_type_lookup_id'] = 1;
        axios
            .post(`/api/doctors`, payload)
            .then(() => {
                setSelectedSpecialty(null);
                setSelectedDepartment(null);
                onCreate();
            })
            .catch((error) => {
                form.resetFields();
                onError(error);
            });

        // axios.post('/api/doctors', payload).then(()=>{
        //     onCreate();
        // }).catch((error)=>{
        //     console.log('Error in adding doctor:', error);
        //     onError(error);
        // });
    };

    // eslint-disable-next-line arrow-body-style
    const disabledDate = (current) => {
        // Can not select days before today and today
        return current && current > dayjs().endOf('day');
    };

    const handleSpecialtyChange = (value) => {
        console.log('Speciality changed to:', value);
        setSelectedSpecialty(value);
    };

    const handleDepartmentChange = (value) => {
        console.log('Speciality changed to:', value);
        setSelectedDepartment(value);
    };

    const onDOBChange = (date, dateString) => {
        console.log(date, dateString);
    };


    return (
        <>
            <Modal
                title="Add Doctor"
                centered="true"
                open={open}
                okText="Add Doctor"
                mask={true}
                maskClosable={false}
                destroyOnClose
                onOk={() => {
                    form.validateFields()
                        .then((newDoctor) => {
                            form.resetFields();
                            saveDoctor(newDoctor);
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info);
                        });
                }}
                onCancel={() => {
                    form.resetFields();// onCancel();
                    setSelectedSpecialty(null);
                    setSelectedDepartment(null);
                    onCancel();
                }}
                width={800}
            >
                <Form
                    {...layout}
                    form={form}
                    name="control-hooks"
                    onFinish={onFinish}
                    style={{
                        maxWidth: 600,
                    }}
                >
                    <Form.Item
                        name="first_name"
                        label="First Name"
                        ref={refElement}
                        rules={[
                            {
                                required: true,
                                message: 'Please enter Doctor First Name',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="middle_name"
                        label="Middle Name"
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="last_name"
                        label="Last Name"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter Doctor Last Name',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        name="date_of_birth"
                        label="Date Of Birth"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter Doctor DOB',
                            },
                        ]}
                    >
                        <DatePicker
                            format="MM-DD-YYYY"
                            onChange={onDOBChange}
                            disabledDate={disabledDate}
                        />
                    </Form.Item>

                    <Form.Item name="qualification" label="Qualification" rules={[
                            {
                                required: true,
                                message: 'Please enter Doctor Qualification',
                            },
                        ]}>
                        <Input />
                    </Form.Item>

                    <Form.Item
                        name="registration_number"
                        label="Med. Reg No"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter Medical Registration Number',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        name="gender"
                        size="small "
                        label="Gender"
                        rules={[
                            {
                                required: true,
                                message: 'Please select Doctor Gender',
                            },
                        ]}
                    >
                        <Select placeholder="Select Gender" allowClear>
                            <Option value="Male">Male</Option>
                            <Option value="Female">Female</Option>
                            <Option value="Other">Other</Option>
                        </Select>
                    </Form.Item>

                    <Form.Item
                        name="contact_number"
                        label="Mobile Number"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter mobile number',
                            },
                        ]}
                    >
                        <Input
                            onChange={() => {
                                console.log('On CHange');
                            }}
                            stringMode
                        />
                    </Form.Item>

                    <Form.Item name="email" label="Email Id" rules={[
                            {
                                required: true,
                                message: 'Please enter email Id',
                            },
                        ]}>
                        <Input />
                    </Form.Item>


                    {/* <Form.Item
                        name="alternate_mobile"
                        label="Sec. Mobile Number"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter alternate mobile number',
                            },
                        ]}
                    >
                        <Input
                            onChange={() => {
                                console.log('On CHange');
                            }}
                            stringMode
                        />
                    </Form.Item> */}

                    <Form.Item
                        label="Department"
                        name="department_id"
                        style={{ fontWeight: '500' }}
                    >
                        <CustomSelect
                            selectedValue={selectedDepartment}
                            apiEndpoint={`/api/departments`}
                            placeholder="Select Department"
                            optionFormat={{
                                id: 'department_id',
                                label: 'department_name',
                            }}
                            onChange={handleDepartmentChange}
                        />
                    </Form.Item>

                    <Form.Item
                        label="Speciality"
                        name="specialty_id"
                        style={{ fontWeight: '500' }}
                    >
                        <CustomSelect
                            selectedValue={selectedSpecialty}
                            apiEndpoint={`/api/specialities`}
                            placeholder="Select Specialty"
                            optionFormat={{
                                id: 'specialty_id',
                                label: 'specialty_name',
                            }}
                            onChange={handleSpecialtyChange}
                        />
                    </Form.Item>

                    <Form.Item
                        name="address"
                        label="Address"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter the Address',
                            },
                        ]}
                    >
                        <TextArea
                            rows={4}
                            placeholder="Type address here"
                            maxLength={500}
                        />
                    </Form.Item>

                    {/* <Form.Item
                        name="blood_group_lookup_id"
                        label="Blood Group"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter patient Last Name',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item> */}
                </Form>
            </Modal>
        </>
    );
}
