import { Card } from 'antd';
import { Typography } from 'antd';
const { Title } = Typography;


export default function PatientCount({data, title, background="#F0F8FF"}) {
    console.log('data:', data);
    return (
        <Card.Grid className='dashboard-card' style={{border:"1px solid #F0FFF0", backgroundColor: `${background}`, textAlign: "center"}}> 
            <div>
                <Title level={4}>Male: {data.male_patients}, Female: {data.female_patients}</Title>
                <p style={{fontSize: "18px"}}>{title}</p>
            </div>
        </Card.Grid>
    );
}
