import React, { useRef, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Form, Input, Checkbox, Button, Alert,notification } from 'antd';
import { LockOutlined, UserOutlined } from '@ant-design/icons';

import './login.css';
import axiosAPI from '../../services/axios';
import Inquiry from '../inquiry';

export default function Login({ handleLogin }) {
    const [searchParams] = useSearchParams();
    const error = searchParams.get('error');
    const refElement = useRef(null);
    const [errorText, setErrorText] = useState(null);
    const [showErrorText, setShowErrorText] = useState(false);
    const [api, contextHolder] = notification.useNotification();

    const [shownIqueryDialog, setShownIqueryDialog] = useState(false);

    const openNotificationWithIcon = (type, title, description) => {
        api[type]({
          message: title,
          description: description,
          placement: "top",
          duration: 2,
          role: "status"
        });
    };

    // const [form] = Form.useForm();

    useEffect(() => {
        if (refElement.current) {
            refElement.current.focus();
        }
    }, []);

    const onFinish = (values) => {
        console.log('Success:', values);
        axiosAPI
            .post('/api/authenticate', values)
            .then((response) => {
                localStorage.setItem('user', JSON.stringify(response.data));
                localStorage.setItem('token', response.data.token);
                handleLogin(true);
            })
            .catch((error) => {
                console.log('Error in authentication:', error);
                handleLogin(false);
                setErrorText(error.message ? error.message : 'Oops! Something went Wrong!');
                setShowErrorText(true);
            });
    };

    const onErrorAlertClose = function(){
        setShowErrorText(false);
    };

    const onCreate = () => {
        setShownIqueryDialog(false);
        openNotificationWithIcon('success', 'Success', 'Thanks for Showing interest  in Symptam, Our representative will call you Shortly.');  
    };

    return (
        <div className="login-bg-wrapper">
            {contextHolder}
            <Inquiry
                open={shownIqueryDialog}
                onCreate={onCreate}
                onCancel={() => {
                    setShownIqueryDialog(false);
                }}
                onError={()=>{
                    openNotificationWithIcon('error', 'Error', 'OOPS! Something went wrong!');
                }}
            ></Inquiry>

            <div className="left-panel-wrap">
                <div className="login-left-title">
                    <span style={{ color: "#B0C50F"}}>Symptam</span>
                </div>

                {/* <p style={{fontSize: "15px", lineHeight: "1.9rem"}}>
                    Symptam empowers Doctors & Health Care providers by streamlining appointment
                    scheduling and providing a secure environment for generating
                    e-prescriptions. With efficient prescription retrieval,
                    integrated health records, and a user-friendly interface,
                    doctors can enhance patient care, save time, and ensure
                    compliance with healthcare regulations. Experience a
                    simplified workflow, allowing doctors to focus on delivering
                    personalized and high-quality healthcare services.

                </p> */}
                <p style={{fontSize: "15px", lineHeight: "1.9rem"}}>
                    Symptam is a cloud-based software that helps doctors and healthcare providers with a seamless and efficient solution for all their administrative needs.
                    This user-friendly software streamlines appointment scheduling at hospital desk with a hassle-free & user-friendly interface, secure e-prescription generation, efficient prescription retrieval, and integrated health records.
                </p>
            </div>
            <div className="login-wrapper">
                <div className="login-title">
                    <div className="login-title-icon">
                        <div style={{ color: "#B0C50F"}}>Symptam</div>
                        <div>
                            <span className="material-symbols-outlined">
                                passkey
                            </span>
                        </div>
                    </div>
                </div>
                <Form
                    name="normal_login"
                    className="login-form"
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                    layout="vertical"
                    size="large"
                >
                    {error && <Alert  style={{ padding: "10px 10px", marginBottom: "5px"}}
                        description={error}
                        type="error"
                        closable
                        // onClose={onClose}
                    />}
                    
                    <Form.Item
                        label="Username"
                        name="user_name"
                        ref={refElement}
                        rules={[
                            {
                                required: true,
                                message: 'Please input your username!',
                            },
                        ]}
                    >
                        <Input
                            prefix={
                                <UserOutlined className="site-form-item-icon" />
                            }
                            placeholder="Username"
                        />
                    </Form.Item>
                    <Form.Item
                        label="Password"
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your password!',
                            },
                        ]}
                    >
                        <Input
                            prefix={
                                <LockOutlined className="site-form-item-icon" />
                            }
                            type="password"
                            placeholder="Password"
                        />
                    </Form.Item>
                    <Form.Item>
                        <Form.Item
                            name="remember"
                            valuePropName="checked"
                            noStyle
                        >
                            <Checkbox>Remember me</Checkbox>
                        </Form.Item>
                        <a className="login-form-forgot" href="">
                            Forgot password?
                        </a>
                    </Form.Item>
                    {showErrorText && <Alert style={{ padding: "10px 10px", marginBottom: "5px"}}
                        description={errorText}
                        type="error"
                        closable={true}
                        onClose={onErrorAlertClose}
                    />}
                    
                    <Form.Item>
                        <Button
                            type="primary"
                            htmlType="submit"
                            className="login-form-button"
                        >
                            Sign In
                        </Button>
                    </Form.Item>
                    <div style={{ textAlign:'center'}}>
                        <Button type="link" onClick={()=>{setShownIqueryDialog(true);}}> Create your e-hospital</Button>
                    </div>
                </Form>
            </div>
        </div>
    );
}
