import React from 'react';
import { Page, Document, View, Text, Image, StyleSheet } from '@react-pdf/renderer';

import InvoiceTitle from './InvoiceTitle'
import BillTo from './BillTo'
import InvoiceNo from './InvoiceNo'
import InvoiceItemsTable from './InvoiceItemsTable'
import InvoiceThankYouMsg from './InvoiceThankYouMsg'
//import logo from '../../../src/logo.png';
import logo from '../../Healify.png';

const styles = StyleSheet.create({
    page: {
        fontFamily: 'Helvetica',
        fontSize: 11,
        paddingTop: 30,
        paddingLeft: 60,
        paddingRight: 60,
        lineHeight: 1.5,
        flexDirection: 'column',
    },
    hospitalDetailsContainer: {
        //flexDirection: 'row',
        flexDirection: 'column', // Change to column direction
        alignItems: 'center',    // Center align items
        // marginTop: 12,
        marginTop: 8,
        borderBottomWidth: 1, // Add border bottom
        borderBottomColor: '#000', // Add border color
    },
    hospitalName: {
        textTransform: 'uppercase',
        fontWeight: 'bold',
        textAlign: 'center',
        fontSize: 20,
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    hospitalAddress: {
        fontSize: 12,
        textAlign: 'center',
    },
    billContainerContainer: {
        //flexDirection: 'row',
        marginTop: 5,
        flexDirection: 'column', // Change to column direction
        alignItems: 'center',    // Center align items
    },
    billTitle: {
        fontSize: 14,
        textAlign: 'center',
    },
    logo: {
        width: 74,
        height: 66,
        marginLeft: 'auto',
        marginRight: 'auto'
    }
});

const Invoice = ({ invoice }) => (
    <Document>
        <Page size="A4" style={styles.page}>
            <View style={styles.hospitalDetailsContainer}>
                <Text style={styles.hospitalName}>{invoice.company}</Text>
                <Text style={styles.hospitalAddress}>{invoice.company_address}</Text>
            </View>

            <View style={styles.billContainerContainer}>
                <Text style={styles.billTitle}>BILL CUM RECEIPT</Text>
            </View>

            {/* <Image style={styles.logo} src={logo} /> */}
            {/* <InvoiceTitle title='Invoice'/> */}
            {/* <InvoiceNo invoice={invoice} /> */}
            <BillTo invoice={invoice} />
            <InvoiceItemsTable invoice={invoice} />
            <InvoiceThankYouMsg />
        </Page>
    </Document>
);

export default Invoice;