import React, {useState, useEffect} from 'react';
import { Row, Col, Card } from 'antd';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';

import WelcomeMessage from './welcome';
import PatientCount from './patients-count';
import MyPrescriptions from './my-prescriptions';
import LastVisits from './last-visits';
import NextAppointments from './next-appointments';
import TodaysAppointments from './todays-appointments';
import axios from '../../services/axios';

import './dashboard.css';

export default function Dashboard() {
    const data = { male_patients: 20, female_patients: 30 };
    const today = dayjs().format('YYYY-MM-DD');

    let [todaysAppointments, setTodaysAppointments] = useState(0);
    let [totalPatients, setTotalPatients] = useState({ male_patients: 0, female_patients: 0 });
    let [myPatients, setMyPatients] = useState({ male_patients: 0, female_patients: 0 });

    let doctorId = JSON.parse(localStorage.getItem('user')).doctor.doctor_id;

    //get todays appointments for the doctor
    useEffect(() => {
        axios.get(`/api/hospital/appointment?doctor_id=${doctorId}&pageno=1&date=${today}`)
            .then((response) => {
                if (response && response.data) {
                    setTodaysAppointments(response.data.totalrecords); // Set all appointments
                }
            })
            .catch((error) => {
                console.log('Error in Getting next appointments:', error);
            });
    }, [doctorId, today]);

    //Get all patients
    useEffect(() => {
        axios.get(`/api/doctors/${doctorId}/dashboard/totalpatients?myPatients=false`)
            .then((response) => {
                if (response && response.data) {
                    let data = {};
                    response.data.forEach((record)=>{
                        record.gender === 'Male' ? data['male_patients'] = record.total_count : data['female_patients'] = record.total_count;
                    });
                    setTotalPatients(data); // Set all appointments
                }
            })
            .catch((error) => {
                console.log('Error in Getting next appointments:', error);
            });
    }, [doctorId]);

    //Get my Patients
    useEffect(() => {
        axios.get(`/api/doctors/${doctorId}/dashboard/totalpatients?myPatients=true`)
            .then((response) => {
                if (response && response.data) {
                    let data = {};
                    response.data.forEach((record)=>{
                        record.gender === 'Male' ? data['male_patients'] = record.total_count : data['female_patients'] = record.total_count;
                    });
                    setMyPatients(data);
                }
            })
            .catch((error) => {
                console.log('Error in Getting next appointments:', error);
            });
    }, [doctorId]);

    return (
        <>
            <Row justify="center"
                gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                className="dashboard-greeting-panel padding-l10 padding-t10"
            >
                <Col xs={12} lg={6}>
                    <WelcomeMessage todaysAppointments={todaysAppointments}/>
                </Col>

                <Col xs={12} lg={6}>
                    <PatientCount data={totalPatients} title="Total Patients" />
                </Col>

                <Col xs={12} lg={6}>
                    <PatientCount data={myPatients} title="My Patients" background="#F5F5DC"/>
                </Col>

                <Col xs={12} lg={6}>
                    <TodaysAppointments todaysAppointments={todaysAppointments} doctorId={JSON.parse(localStorage.getItem('user')).doctor.doctor_id}/>
                </Col>
            </Row>

            <Row gutter={16} className="padding-l10 padding-t10 doctor-dashboard-wrapper">
                <Col span={24} md={8} lg={8} className='new-appointment-section'>
                    <NextAppointments doctorId={JSON.parse(localStorage.getItem('user')).doctor.doctor_id} title="Next Appointments" />
                </Col>
                <Col span={24} md={15} lg={15}>
                    <Card
                        style={{ minHeight: '350px'}}
                        className="dashboard-my-prescriptions"
                        title={
                            <div>
                                My Prescriptions
                                <div style={{ float: 'right' }}>
                                    <div
                                        style={{
                                            float: 'right',
                                            marginLeft: '30px',
                                        }}
                                    >
                                        <Link to="/prescriptions">
                                            View All
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        }
                        bordered={true}
                    >
                        <MyPrescriptions gridPageSize="5" />
                    </Card>
                </Col>
            </Row>

            <Row gutter={16} className="padding-l10 padding-t10 mb-4 last-appointment-section">
                <Col span={24} md={8} lg={8}>
                   
                    <div
                        // style={{
                        //     height: '100px',
                        //     backgroundColor: 'lightpink',
                        //     marginBottom: '16px',
                        // }}
                    >
                        {/* Second Column (Second Row) */}
                    </div>
                </Col>
                <Col span={28} md={15} lg={15}>
                    <LastVisits data={data} title="Last Visits" />
                </Col>
            </Row>
        </>
    );
}