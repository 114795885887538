import React, { useRef, useState, useEffect } from 'react';
import {
    Form,
    Input,
    Modal,
    DatePicker,
    Row,
    Col,
    Radio,
    Button,
    Checkbox,
    Divider,
} from 'antd';

import { ScheduleOutlined, SaveOutlined } from '@ant-design/icons';
import { MdFreeCancellation } from 'react-icons/md';
import DynamicSlots from './slots';
// import DynamicFormList from './dynamic-form-list';
import dayjs from 'dayjs';
import axios from '../../services/axios';
const { RangePicker } = DatePicker;

const layout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 16,
    },
};

// const tailLayout = {
//     wrapperCol: {
//         offset: 8,
//         span: 16,
//     },
// };

export default function DoctorAvailability({ open, onCreate, onCancel, doctor }) {
    // const refElement = useRef(null);

    //const [bookingForDoctor, setBookingForDoctor] = useState(doctor);
    
    // if (bookingForDoctor !== doctor) { // don't update unnecessarily
    //     setBookingForDoctor(doctor);
    // }

    console.log('booking for doctor:', doctor);
    const [appointmentDuration, setAppointmentDuration] = useState(10);
    const [customAppointmentDuration, setCustomAppointmentDuration] = useState(10);
    const [sundayAvailable, setSundayAvailable] = useState(false);
    const [exclusionDateRadio, setExclusionDateRadio] = useState(1);
    const [exclusionDateValue, setExclusionDateValue] = useState(null);
    const [exclusionDateRange, setExclusionDateRange] = useState(null, null);

    const [weekDatasetMap, setWeekDatasetMap] = useState({});
    const [selectedWeek, setSelectedWeek] = useState(null);

    //Slots state
    const [mondayStartTimes, setMondayStartTimes] = useState([]);
    const [mondayEndTimes, setMondayEndTimes] = useState([]);

    const [tuesdayStartTimes, setTuesdayStartTimes] = useState([]);
    const [tuesdayEndTimes, setTuesdayEndTimes] = useState([]);

    const [wednesdayStartTimes, setWednesdayStartTimes] = useState([]);
    const [wednesdayEndTimes, setWednesdayEndTimes] = useState([]);

    const [thursdayStartTimes, setThursdayStartTimes] = useState([]);
    const [thursdayEndTimes, setThursdayEndTimes] = useState([]);

    const [fridayStartTimes, setFridayStartTimes] = useState([]);
    const [fridayEndTimes, setFridayEndTimes] = useState([]);

    const [saturdayStartTimes, setSaturdayStartTimes] = useState([]);
    const [saturdayEndTimes, setSaturdayEndTimes] = useState([]);

    const [sundayStartTimes, setSundayStartTimes] = useState([]);
    const [sundayEndTimes, setSundayEndTimes] = useState([]);

    const onMondayTimeChange = function(timeString){
        console.log('onMondayTimeChange timeString:', timeString);
        if(timeString.length >=2){
            setMondayStartTimes([...mondayStartTimes, timeString[0]]);
            setMondayEndTimes([...mondayEndTimes, timeString[1]]);
        }
    };

    const onTuesdayTimeChange = function(timeString){
        console.log('onTuesdayTimeChange timeString:', timeString);
        if(timeString.length >=2){
            setTuesdayStartTimes([...tuesdayStartTimes, timeString[0]]);
            setTuesdayEndTimes([...tuesdayEndTimes, timeString[1]]);
        }
    };

    const onWednesdayTimeChange = function(timeString){
        console.log('onWednesdayTimeChange timeString:', timeString);
        if(timeString.length >=2){
            setWednesdayEndTimes([...wednesdayStartTimes, timeString[0]]);
            setWednesdayEndTimes([...wednesdayEndTimes, timeString[1]]);
        }
    };

    const onThursdayTimeChange = function(timeString){
        console.log('onThursdayTimeChange timeString:', timeString);
        if(timeString.length >=2){
            setThursdayStartTimes([...thursdayStartTimes, timeString[0]]);
            setThursdayEndTimes([...thursdayEndTimes, timeString[1]]);
        }
    };

    const onFridayTimeChange = function(timeString){
        console.log('onThursdayTimeChange timeString:', timeString);
        if(timeString.length >=2){
            setFridayStartTimes([...fridayStartTimes, timeString[0]]);
            setFridayEndTimes([...fridayEndTimes, timeString[1]]);
        }
    };

    const onSaturdayTimeChange = function(timeString){
        console.log('onThursdayTimeChange timeString:', timeString);
        if(timeString.length >=2){
            setSaturdayStartTimes([...saturdayStartTimes, timeString[0]]);
            setSaturdayEndTimes([...saturdayEndTimes, timeString[1]]);
        }
    };

    const onSundayTimeChange = function(timeString){
        console.log('onThursdayTimeChange timeString:', timeString);
        if(timeString.length >=2){
            setSundayStartTimes([...sundayStartTimes, timeString[0]]);
            setSundayAvailable([...sundayEndTimes, timeString[1]]);
        }
    };
    

    const onAppointmentDurationChange = (e) => {
        console.log('radio checked', e.target.value);
        setAppointmentDuration(e.target.value);
        setCustomAppointmentDuration(e.target.value);
    };

    const onSundayChexkboxChange = (e) => {
        console.log(`checked = ${e.target.checked}`);
        setSundayAvailable(e.target.checked);
    };

    const onExclusionRadioChange = (event) => {
        console.log('availability_exclusion_type');
        setExclusionDateRadio(event.target.value);
    };

    const onExclusionDateChange = (value, dateString) => {
        console.log('Selected Time: ', value);
        console.log('Formatted Selected Time: ', dateString);
        setExclusionDateValue(value);
    };

    const onExclusionDateRangeChange = (dates, dateString) => {
        console.log('Selected Time: ', dates);
        console.log('Formatted Selected Time: ', dateString);
        setExclusionDateValue(dates);
    };

    const onExclusionDateOk = (value) => {
        console.log('onOk: ', value);
        setExclusionDateRange(value);
    };

    //const [modalOpen, setModalOpen] = useState(show);

    // function handleModalOk() {
    //     //setModalOpen(false);
    // }

    // useEffect(() => {
    //     // if (refElement.current) {
    //     //     refElement.current.focus();
    //     // }
    //     setBookingForDoctor(bookingForDoctor);
    // }, [bookingForDoctor]);

    // useEffect(()=>{
    //    // refElement.current.focus();
    //     refElement.current.focus({
    //         cursor: 'start',
    //       });
    // },[]);

    const [form] = Form.useForm();

    const onFinish = (values) => {
        console.log('Form Values:', values);
    };

    const onReset = () => {
        form.resetFields();
    };

    // const onFill = () => {
    //     form.setFieldsValue({
    //         note: 'Hello world!',
    //         gender: 'Male',
    //     });
    // };

    // eslint-disable-next-line arrow-body-style
    const disabledDate = (current) => {
        // Can not select days before today and today
       // return current && current > dayjs().endOf('day');
        return current && current < dayjs().endOf('day');
    };

    const labelCol = {
        span: 11, // this means the label will take 4/24 of the row width
        offset: 0, // this means the label will have no offset from the left
        style: {
            width: '100px', // this means the label will have a fixed width of 100px
            textAlign: 'left',
        },
    };

    function handleAppointmentValue(event) {
        setCustomAppointmentDuration(event.target.value);
    }

    const checkTime = function(i) {
        return (i < 10) ? "0" + i : i;
    };

    const formatDateToHHMM = function(date){
        let hour = checkTime(date.getHours());
        let minutes = checkTime(date.getMinutes());
        return `${hour}:${minutes}`.toString();
    };

    const prepareTimeSlots = function(formValues, day){
        let date;
        let startTimes = [];
        let endTimes = [];
        formValues[day].forEach((element)=>{
            console.log('field:', element); 
            element.field.forEach((timeSlot, index)=>{
                date = new Date(timeSlot);
                console.log('date:', date, index);
                if(index === 0){
                    startTimes.push(formatDateToHHMM(date));
                }

                if(index === 1){
                    endTimes.push(formatDateToHHMM(date));
                }
            });
        });
        return {
            startTimes: startTimes,
            endTimes: endTimes
        }
    };
    

    const saveAvailability = function(formValues){
        console.log('formValues:', formValues);
        const payload = {
            slot_duration: formValues.appoinment_duration || 10,
            slots: []
        };

        if(customAppointmentDuration && !isNaN(customAppointmentDuration)){
            payload.slot_duration = parseInt(customAppointmentDuration);
        }

        //let mondayTImeSlots = prepareTimeSlots(formValues, 'monday_availability');
        if(formValues['monday_availability'].length && formValues['monday_availability'][0]!== ''){
            payload.slots.push({
                date: weekDatasetMap.get('Monday'),
                dayOfWeek: 'Monday',
                doctorId: doctor.doctor_id,
                ...prepareTimeSlots(formValues, 'monday_availability')
            });
    
        }

        if(formValues['tuesday_availability'].length && formValues['tuesday_availability'][0]!== ''){
            payload.slots.push({
                date: weekDatasetMap.get('Tuesday'),
                dayOfWeek: 'Tuesday',
                doctorId: doctor.doctor_id,
                ...prepareTimeSlots(formValues, 'tuesday_availability')
            });
        }

        if(formValues['wednesday_availability'].length && formValues['wednesday_availability'][0]!== ''){
            payload.slots.push({
                date: weekDatasetMap.get('Wednesday'),
                dayOfWeek: 'Wednesday',
                doctor: doctor.doctor_id,
                ...prepareTimeSlots(formValues, 'wednesday_availability')
            });
        }

        if(formValues['thursday_availability'].length && formValues['thursday_availability'][0]!== ''){
            payload.slots.push({
                date: weekDatasetMap.get('Thursday'),
                dayOfWeek: 'Thursday',
                doctor: doctor.doctor_id,
                ...prepareTimeSlots(formValues, 'thursday_availability')
            });
        }
        
        if(formValues['friday_availability'].length && formValues['friday_availability'][0]!== ''){
            payload.slots.push({
                date: weekDatasetMap.get('Friday'),
                dayOfWeek: 'Friday',
                doctor: doctor.doctor_id,
                ...prepareTimeSlots(formValues, 'friday_availability')
            });
        }

        if(formValues['saturday_availability'].length && formValues['saturday_availability'][0]!== ''){
            payload.slots.push({
                date: weekDatasetMap.get('Saturday'),
                dayOfWeek: 'Saturday',
                doctor: doctor.doctor_id,
                ...prepareTimeSlots(formValues, 'saturday_availability')
            });
        }

        if(formValues['sunday_availability'] && formValues['sunday_availability'].length && formValues['sunday_availability'][0]!== ''){
            payload.slots.push({
                date: weekDatasetMap.get('Sunday'),
                dayOfWeek: 'Sunday',
                doctor: doctor.doctor_id,
                ...prepareTimeSlots(formValues, 'sunday_availability')
            });
        }  

        console.log('Final Payload:', payload);
        if(payload.slots.length){
             axios.post(`api/doctors/${doctor.doctor_id}/slots`, payload)
             .then(()=>{
                onCreate(form.getFieldsValue());
                form.resetFields();
                setCustomAppointmentDuration(10);
             }).catch((error)=>{
                console.log('Error in saving schedule!');
                form.resetFields(); //Uncomment this
               // onCancel(error);
               setCustomAppointmentDuration(10);
             });
        }
    };

    const onWeekChange = function(date, datestring){
        console.log('onWeekChange Date :', date);
        console.log('onWeekChange datestring :', datestring);
        setSelectedWeek(datestring);
        getWeekMapping(datestring);
    };

    const getWeekMapping = function(weekNumberString) {
        const parseWeekNumber = (weekNumberString) => {
            const [year, week] = weekNumberString.split('-');
            return { year: parseInt(year), week: parseInt(week) };
        };

        const getWeekDatesMap = (year, week) => {
            const startOfWeek = dayjs().year(year).week(week).startOf('week');
            const endOfWeek = dayjs().year(year).week(week).endOf('week');

            const weekDatesMap = new Map();
            let currentDate = startOfWeek;

            while (currentDate.isBefore(endOfWeek) || currentDate.isSame(endOfWeek, 'day')) {
                const dayOfWeek = currentDate.format('dddd');
                weekDatesMap.set(dayOfWeek, currentDate.format('YYYY-MM-DD'));
                currentDate = currentDate.add(1, 'day');
            }

            return weekDatesMap;
        };

        
        const { year, week } = parseWeekNumber(weekNumberString);
        const weekDatesMap = getWeekDatesMap(year, week);
        console.log("weekDatesMap:", weekDatesMap);
        setWeekDatasetMap(()=>{ return weekDatesMap;});
    };

    return (
        <>
            <Modal
                title="Appointment Availability"
                centered="true"
                destroyOnClose={true}
                open={open}
                okText="Set Schedule"
                okButtonProps={{ icon: <ScheduleOutlined /> }}
                cancelButtonProps={{
                    danger: 'danger',
                    icon: <MdFreeCancellation />,
                }}
                mask={true}
                maskClosable={false}
                onOk={() => {
                    
                    if (!selectedWeek) {
                        return;
                    }

                    saveAvailability(form.getFieldsValue());
                    //onCreate(form.getFieldsValue());
                    //form.resetFields(); //Uncomment this

                    // form.validateFields()
                    //     .then((values) => {
                    //         //form.resetFields(); //Uncomment this
                    //         saveAvailability(values);
                    //         onCreate(values);
                    //     })
                    //     .catch((info) => {
                    //         console.log('Validate Failed:', info);
                    //     });
                }}
                onCancel={() => {
                    //form.resetFields();
                    onCancel();
                }}
                width={800}
                style={{
                    top: 20,
                }}
            >
                <Form form={form}
                    {...layout}
                    name="control-hooks"
                    onFinish={onFinish}
                    style={{
                        maxWidth: 700, marginTop: "20px"
                    }}
                    layout="vertical"
                    preserve={false}
                >
                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                        <Col xs={12} offset={1} lg={11}>
                            <Form.Item
                                name="appoinment_duration"
                                label="Select a Duration (min)"
                                labelCol={{ span: 24, offset: 0 }}
                                wrapperCol={{ span: 24 }}
                                // ref={refElement}
                                // rules={[
                                //     {
                                //         required: true,
                                //         message: 'Please Select Duration',
                                //     },
                                // ]}
                            >
                                <Radio.Group
                                    defaultValue={10}
                                    buttonStyle="solid"
                                    onChange={onAppointmentDurationChange}
                                    value={appointmentDuration}
                                    style={{ marginBottom: '8px' }}
                                >
                                    <Radio.Button value={10}>
                                        10 Min
                                    </Radio.Button>
                                    <Radio.Button value={15}>
                                        15 Min
                                    </Radio.Button>
                                    <Radio.Button value={20}>
                                        20 Min
                                    </Radio.Button>
                                </Radio.Group>
                            </Form.Item>
                        </Col>

                        <Col xs={12} lg={11}>
                            <label
                                style={{
                                    marginBottom: '5px',
                                    display: 'inline-block',
                                }}
                            >
                                {' '}
                                Or Enter Custom duration in Minutes
                            </label>
                            <Input
                                value={customAppointmentDuration}
                                onChange={handleAppointmentValue}
                            />
                        </Col>
                    </Row>
                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                        <Col xs={24} offset={1} lg={24}>
                            <h3>Availability</h3>
                            {/* <Divider
                                orientation="left"
                                style={{
                                    marginTop: '0px',
                                    marginBottom: '0px',
                                }}
                            >
                                <h5>Availability</h5>
                            </Divider> */}

                            <Form.Item
                                name="sunday_available"
                                label=""
                                labelCol={{ span: 24, offset: 0 }}
                                wrapperCol={{ span: 24 }}
                                style={{ marginBottom: '5px', fontWeight: "bold" }}
                            >
                                <p
                                    style={{
                                        fontSize: '13px',
                                        fontWeight: '500',
                                        paddingBottom: '5px',
                                    }}
                                >
                                    Define custom hours (24 hours format) to configure when the
                                    appointment can be booked.
                                </p>

                                <div
                                    style={{
                                        fontSize: '13px',
                                        paddingBottom: '5px',
                                    }}
                                >
                                    <Checkbox checked={sundayAvailable} onChange={onSundayChexkboxChange}>
                                        Sunday
                                    </Checkbox>
                                    <span>
                                        {sundayAvailable
                                            ? 'Available'
                                            : 'Unavailable'}
                                    </span>
                                </div>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                        <Col xs={24} offset={1} lg={24}>
                            <Form.Item
                                name="week_selector"
                                label="Select One week"
                                labelCol={{ span: 24, offset: 0 }}
                                wrapperCol={{ span: 24 }}
                                style={{ marginBottom: '5px', fontWeight: "bold" }}
                                // rules={[
                                //     {
                                //       required: true,
                                //       message: 'Please Select the Week',
                                //     },
                                // ]}
                            >
                                <p
                                    style={{
                                        fontSize: '13px',
                                        fontWeight: '500',
                                        paddingBottom: '5px',
                                    }}
                                >
                                    Select Only one week from Sunday to Saturday
                                </p>
                                {/* <DatePicker  picker="week" onChange={onWeekChange} style={{ widdh: "300px"}}/> */}
                                <DatePicker  picker="week" onChange={(date, dateString) => onWeekChange(date, dateString)} style={{ widdh: "300px"}}/>
                                <p className={!selectedWeek ? 'display-block' : 'display-none'} style={{ color: "red", fontWeight: "normal"}}>Please Select the Week!</p>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                        <Col xs={12} offset={1} lg={11}>
                            <Form.Item
                                name="monday_availability"
                                label="Monday"
                                labelCol={{ span: 24, offset: 0 }}
                                wrapperCol={{ span: 24 }}
                                style={{ marginBottom: '0px', fontWeight: "bold"  }}
                            >
                                <DynamicSlots
                                    id="monday"
                                    name="monday_availability"
                                    onTimeChange={onMondayTimeChange}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={12} offset={1} lg={11}>
                            <Form.Item
                                name="tuesday_availability"
                                label="Tuesday"
                                labelCol={{ span: 24, offset: 0 }}
                                wrapperCol={{ span: 24 }}
                                style={{ marginBottom: '0px', fontWeight: "bold" }}
                            >
                                <DynamicSlots
                                    id="tuesday"
                                    name="tuesday_availability"
                                    onTimeChange={onTuesdayTimeChange}
                                />
                                {/* <DynamicFormList name="anotherEmails" placeholder="Another Email" initialValue={['']}/> */}
                            </Form.Item>
                        </Col>
                        <Col xs={12} offset={1} lg={11}>
                            <Form.Item
                                name="wednesday_availability"
                                label="Wednesday"
                                labelCol={{ span: 24, offset: 0 }}
                                wrapperCol={{ span: 24 }}
                                style={{ marginBottom: '0px', fontWeight: "bold"  }}
                            >
                                <DynamicSlots
                                    id="wednesday"
                                    name="wednesday_availability"
                                    onTimeChange={onWednesdayTimeChange}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={12} offset={1} lg={11}>
                            <Form.Item
                                name="thursday_availability"
                                label="Thursday"
                                labelCol={{ span: 24, offset: 0 }}
                                wrapperCol={{ span: 24 }}
                                style={{ marginBottom: '0px', fontWeight: "bold" }}
                            >
                                <DynamicSlots
                                    id="thursday"
                                    name="thursday_availability"
                                    onTimeChange={onThursdayTimeChange}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={12} offset={1} lg={11}>
                            <Form.Item
                                name="friday_availability"
                                label="Friday"
                                labelCol={{ span: 24, offset: 0 }}
                                wrapperCol={{ span: 24 }}
                                style={{ marginBottom: '0px', fontWeight: "bold" }}
                            >
                                <DynamicSlots
                                    id="friday"
                                    name="friday_availability"
                                    onTimeChange={onFridayTimeChange}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={12} offset={1} lg={11}>
                            <Form.Item
                                name="saturday_availability"
                                label="Saturday"
                                labelCol={{ span: 24, offset: 0 }}
                                wrapperCol={{ span: 24 }}
                                style={{ marginBottom: '0px', fontWeight: "bold" }}
                            >
                                <DynamicSlots
                                    id="saturday"
                                    name="saturday_availability"
                                    onTimeChange={onSaturdayTimeChange}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={12} offset={1} lg={11}>
                            {sundayAvailable && (
                                <Form.Item
                                    name="sunday_availability"
                                    label="Sunday"
                                    labelCol={{ span: 24, offset: 0 }}
                                    wrapperCol={{ span: 24 }}
                                    style={{ marginBottom: '0px', fontWeight: "bold"  }}
                                >
                                    <DynamicSlots
                                        id="sunday"
                                        name="sunday_availability"
                                        onTimeChange={onSundayTimeChange}
                                    />
                                </Form.Item>
                            )}
                        </Col>
                    </Row>

                    {/* <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                        <Col xs={12} offset={1} lg={11}>
                            <div style={{ padding: '0px' }}>
                                <Divider
                                    orientation="left"
                                    style={{
                                        marginTop: '0px',
                                        marginBottom: '0px',
                                    }}
                                >
                                    <h5>Exclusions</h5>
                                </Divider>
                                <Form.Item
                                    name="availability_exclusion_type"
                                    label=""
                                    labelCol={{ span: 24, offset: 0 }}
                                    wrapperCol={{ span: 24 }}
                                    // ref={refElement}
                                >
                                    <p
                                        style={{
                                            fontSize: '13px',
                                            fontWeight: '500',
                                        }}
                                    >
                                        Add dates or custom range to exclude
                                        from your availability. Eg Holidays,
                                        vacations etc.
                                    </p>

                                    <Radio.Group
                                        onChange={onExclusionRadioChange}
                                        value={exclusionDateRadio}
                                        style={{ marginTop: '5px' }}
                                    >
                                        <Radio value={1}>Single Date</Radio>
                                        <Radio value={2}>Date Range</Radio>
                                    </Radio.Group>
                                </Form.Item>
                                {exclusionDateRadio === 1 && (
                                    <Form.Item
                                        name="availability_exclusion_single_date"
                                        label=""
                                        labelCol={{ span: 24, offset: 0 }}
                                        wrapperCol={{ span: 24 }}
                                        // ref={refElement}
                                    >
                                        <DatePicker
                                            format="DD-MM-YYYY"
                                            disabledDate={disabledDate}
                                            value={exclusionDateValue}
                                            onChange={onExclusionDateChange}
                                            style={{ marginTop: '8px' }}
                                        />
                                    </Form.Item>
                                )}
                                {exclusionDateRadio === 2 && (
                                    <Form.Item
                                        name="availability_exclusion_date_range"
                                        label=""
                                        labelCol={{ span: 24, offset: 0 }}
                                        wrapperCol={{ span: 24 }}
                                        ref={refElement}
                                    >
                                        <RangePicker
                                            format="DD-MM-YYYY"
                                            disabledDate={disabledDate}
                                            value={exclusionDateRange}
                                            onChange={
                                                onExclusionDateRangeChange
                                            }
                                            onOk={onExclusionDateOk}
                                            style={{ marginTop: '8px' }}
                                        />
                                    </Form.Item>
                                )}
                            </div>
                        </Col>
                    </Row> */}
                    {/* <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                        <Col xs={12} offset={14} lg={10}>
                            <Button
                                type="primary"
                                icon={<SaveOutlined />}
                                htmlType="submit"
                                style={{ marginRight: '5px' }}
                            >
                                Schedule
                            </Button>

                            <Button
                                type="primary"
                                icon={<SaveOutlined />}
                                htmlType="submit"
                                onClick={onReset}
                                style={{ marginRight: '5px' }}
                            >
                                Reset
                            </Button>
                        </Col>
                    </Row> */}
                </Form>
            </Modal>
        </>
    );
}
