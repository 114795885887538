import { Button, Space, Tooltip, notification, Input, DatePicker  } from 'antd';
import dayjs from 'dayjs';

import {
    DeleteOutlined,
    CheckOutlined
} from '@ant-design/icons';
import { GoCommentDiscussion } from 'react-icons/go';
import { FaPrescription } from 'react-icons/fa';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from '../../services/axios';
import HealthPlusTable from '../../components/datatable/datatable-new';
//import BookAppointment from '../appointment/book-appointment';
import GeneratePrescription from '../prescription/generate-prescription';
import AppointmentStatus from './appointment-status';
import CustomSelect from '../dropdown';
import './appointment-list.css';

const { Search } = Input;
export default function AppointmentList() {
    
    const getTodaysDate = function() {
        let d = new Date(),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
    
        return [year, month, day].join('-');
    };
    let todaysDate = getTodaysDate();
    //todaysDate = '2023-12-25'; //TODO Remove this line 
    //If logged int user is doctor
    let filterValue = {date: todaysDate};
    if(localStorage.getItem('user') && JSON.parse(localStorage.getItem('user')).user.user_type_lookup_id === 1){
        filterValue = {...filterValue, doctor_id: JSON.parse(localStorage.getItem('user')).user.user_type_table_id}; //user_type_table_id is a doctor id saved in user table for referencing to doctor table.
        console.log("filterValue:", filterValue);
    }

    //const [filters, setFilters] = useState({date: todaysDate});
    const [selectedSpecialty, setSelectedSpecialty] = useState(null);
    const [selectedDoctor, setSelectedDoctor] = useState(null);

    const [filters, setFilters] = useState(filterValue);
    const [refreshKey, setRefreshKey] = useState(0);
    const [open, setOpen] = useState(false);
    const [api, contextHolder] = notification.useNotification();
    
    //prescription
    const [openPrescriptionModal, setOpenPrescriptionModal] = useState(false);
    const [selectedpatient, setSelectedpatient] = useState({});
    const navigate = useNavigate();
    const slots = [
        {
            time_slot_id: 1,
            name: '10:10',
            status: 'FREE',
            status_color: '#2db7f5',
        },
        {
            time_slot_id: 2,
            name: '10:20',
            status: 'FREE',
            status_color: '#2db7f5',
        },
        {
            time_slot_id: 3,
            name: '10:30',
            status: 'BOOKED',
            status_color: '#f50',
        },
        {
            time_slot_id: 4,
            name: '10:40',
            status: 'BOOKED',
            status_color: '#f50',
        },
        {
            time_slot_id: 5,
            name: '10:50',
            status: 'BOOKED',
            status_color: '#f50',
        },
        {
            time_slot_id: 6,
            name: '11:00',
            status: 'BOOKED',
            status_color: '#f50',
        },
        {
            time_slot_id: 7,
            name: '11:10',
            status: 'BOOKED',
            status_color: '#f50',
        },
        {
            time_slot_id: 8,
            name: '11:20',
            status: 'FREE',
            status_color: '#2db7f5',
        },
        {
            time_slot_id: 9,
            name: '11:30',
            status: 'FREE',
            status_color: '#2db7f5',
        },
        {
            time_slot_id: 10,
            name: '11:40',
            status: 'BOOKED',
            status_color: '#f50',
        },
        {
            time_slot_id: 11,
            name: '11:50',
            status: 'FREE',
            status_color: '#2db7f5',
        },
        {
            time_slot_id: 12,
            name: '12:00',
            status: 'BOOKED',
            status_color: '#f50',
        },
        {
            time_slot_id: 13,
            name: '12:10',
            status: 'FREE',
            status_color: '#2db7f5',
        },
        {
            time_slot_id: 14,
            name: '12:20',
            status: 'BOOKED',
            status_color: '#f50',
        },
        {
            time_slot_id: 15,
            name: '12:30',
            status: 'FREE',
            status_color: '#2db7f5',
        },
        {
            time_slot_id: 16,
            name: '12:40',
            status: 'BOOKED',
            status_color: '#f50',
        },
        {
            time_slot_id: 17,
            name: '12:50',
            status: 'FREE',
            status_color: '#2db7f5',
        },
        {
            time_slot_id: 18,
            name: '01:00',
            status: 'BOOKED',
            status_color: '#f50',
        },
        {
            time_slot_id: 19,
            name: '01:10',
            status: 'FREE',
            status_color: '#2db7f5',
        },
        {
            time_slot_id: 20,
            name: '01:20',
            status: 'BOOKED',
            status_color: '#f50',
        },
    ];

    const onCreate = (values) => {
        console.log('Received values of form: ', values);
        setOpen(false);
    };

    // const handleFilterChange = (columnKey, value) => {
    //     const newFilters = { ...filters };
    //     newFilters[columnKey] = value.text;
    //     console.log('newFilters:', newFilters);
    //     setFilters(newFilters);
    // };

    const onCreatePrescriptionModal = (values) => {
        console.log('Received values of form: ', values);
        setOpen(false);
    };

    const onGeneratePrescriptionClick = function (record) {
        setSelectedpatient(() => {
            console.log('record:', record);
            return record;
        });
        setOpenPrescriptionModal(true);
    };

    //Unused but do ot delete this
    // const onNameCellClick = (record) => {
    //     console.log('Cell clicked:', record);
    //     navigate(`/prescriptions?patientId=${record.patient_id}&doctorId=${record.doctor_id}`);
    //     // Add your custom logic here
    // };

    //https://ant.design/components/notification#api
    const openNotificationWithIcon = (type, title, description) => {
        api[type]({
          message: title,
          description: description,
          placement: "top",
          duration: 2,
          role: "status"
        });
    };

    const onStartAppointmentClick = function(record){
        const payload = {
            appointment_status: "In Progress"
        };
        console.log('record:', record);
        axios.put(`/api/hospital/appointment/${record.patient_appointment_id}`, payload).then(()=>{
            setRefreshKey((previousKey)=> previousKey + 1);
            openNotificationWithIcon('success', 'Success', '');
        }).catch(()=>{
            openNotificationWithIcon('error', 'Error', 'OOPS! Something went wrong!');
        });
    }

    const onCancelAppointmentClick = function(record){
        const payload = {
            appointment_status: "Cancelled"
        };
        console.log('record:', record);
        axios.put(`/api/hospital/appointment/${record.patient_appointment_id}`, payload).then(()=>{
            setRefreshKey((previousKey)=> previousKey + 1);
            openNotificationWithIcon('success', 'Success', '');
        }).catch(()=>{
            openNotificationWithIcon('error', 'Error', 'OOPS! Something went wrong!');
        });
    }

    const onCompleteAppointmentClick = function(record){
        const payload = {
            appointment_status: "Consulted"
        };
        console.log('record:', record);
        axios.put(`/api/hospital/appointment/${record.patient_appointment_id}`, payload).then(()=>{
            setRefreshKey((previousKey)=> previousKey + 1);
            openNotificationWithIcon('success', 'Success', '');
        }).catch(()=>{
            openNotificationWithIcon('error', 'Error', 'OOPS! Something went wrong!');
        });
    }
    //columns, url, searchText, filters, sortColumn, sortOrder, initialPageSize
    let columns = [
        // {
        //     title: 'Appointment Id',
        //     dataIndex: 'patient_appointment_id',
        //     sorter: false,
        // },
        // {
        //     title: 'Appointment No',
        //     dataIndex: 'appointment_number',
        //     sorter: false,
        // },
        {
            title: 'Token',
            dataIndex: 'token_number',
            sorter: false,
        },
        // {
        //     title: 'Token Number',
        //     dataIndex: 'token_number',
        //     sorter: false,
        //     filterDropdown: ({
        //         setSelectedKeys,
        //         selectedKeys,
        //         confirm,
        //         clearFilters,
        //     }) => (
        //         <div style={{ padding: 8 }}>
        //             <Input
        //                 placeholder="Search Patient Name"
        //                 value={selectedKeys[0]}
        //                 onChange={(e) =>
        //                     setSelectedKeys(
        //                         e.target.value ? [e.target.value] : [],
        //                     )
        //                 }
        //                 onPressEnter={() => {
        //                     handleFilterChange('patient_first_name', {
        //                         text: selectedKeys[0],
        //                         confirm,
        //                     });
        //                 }}
        //                 style={{
        //                     width: 188,
        //                     marginBottom: 8,
        //                     display: 'block',
        //                 }}
        //             />
        //             <Space>
        //                 <Button
        //                     type="primary"
        //                     onClick={() =>
        //                         handleFilterChange('patient_first_name', {
        //                             text: selectedKeys[0],
        //                             confirm,
        //                         })
        //                     }
        //                     icon={<SearchOutlined />}
        //                     size="small"
        //                     style={{ width: 90 }}
        //                 >
        //                     Search
        //                 </Button>
        //                 <Button
        //                     onClick={() =>
        //                         handleFilterChange('patient_first_name', {
        //                             text: '',
        //                             confirm: null,
        //                         })
        //                     }
        //                     size="small"
        //                     style={{ width: 90 }}
        //                 >
        //                     Reset
        //                 </Button>
        //             </Space>
        //         </div>
        //     ),
        // },
        {
            title: 'Patient Name',
            dataIndex: 'patient_name',
            sorter: false,
            //TODO: THis was created by thinking that doctor can generate the prescription if clicked on the patient name cell , currently disabling it
            // render: (text, record) => ({
            //     children: <span onClick={() => onNameCellClick(record)}>{text}</span>,
            // })
        },
        {
            title: 'Address',
            dataIndex: 'patient_address',
            sorter: false,
        },
        {
            title: 'Date',
            dataIndex: 'timeSlotDate',
            sorter: false,
        },
        {
            title: 'time',
            dataIndex: 'time',
            sorter: false,
        },
        {
            title: 'Status',
            dataIndex: 'appointment_status',
            sorter: false,
            render: (text, record) => (
                <Space size="middle">
                    <AppointmentStatus
                        status={record.appointment_status}
                    ></AppointmentStatus>
                </Space>
            ),
        },
        {
            title: 'Patient Gender',
            dataIndex: 'gender',
            sorter: false,
        },
        {
            title: 'Mobile',
            dataIndex: 'mobile',
            sorter: false,
        },
        {
            title: 'Doctor',
            dataIndex: 'doctor',
            sorter: false,
        },
        {
            title: 'Department',
            dataIndex: 'department_name',
            sorter: false,
        },
        {
            title: 'Actions',
            key: 'action',
            render: (_, record) => ( 
                <Space size="middle">
                    {(record.appointment_status.toLowerCase() !==
                        'cancelled'.toLowerCase() && record.appointment_status.toLowerCase() !==
                        'Refunded'.toLowerCase() && (JSON.parse(localStorage.getItem('user')).user.user_type_lookup_id ===  1)) &&  (
                            <Tooltip placement="top" title="Generate Prescription">
                                <Button
                                    shape="circle"
                                    icon={<FaPrescription />}
                                    onClick={(e) => {
                                        onGeneratePrescriptionClick(record);
                                    }}
                                />
                            </Tooltip>
                    )}
                
                    {(record.appointment_status.toLowerCase() ===
                        'Confirmed'.toLowerCase() && (JSON.parse(localStorage.getItem('user')).user.user_type_lookup_id ===  1)) && (
                            <Tooltip placement="top" title="Start Appointment">
                                <Button
                                    shape="circle"
                                    icon={<GoCommentDiscussion />}
                                    onClick={(e) => {
                                        onStartAppointmentClick(record);
                                    }}
                                />
                            </Tooltip>
                    )}

                    {record.appointment_status.toLowerCase() ===
                        'Confirmed'.toLowerCase() && (
                            <Tooltip placement="top" title="Cancel Appointment">
                                <Button
                                    shape="circle"
                                    icon={<DeleteOutlined />}
                                    onClick={(e) => {
                                        onCancelAppointmentClick(record);
                                    }}
                                />
                            </Tooltip>
                    )}

                    {record.appointment_status.toLowerCase() ===
                        'In Progress'.toLowerCase() && (
                            <Tooltip placement="top" title="Complete Appointment">
                                <Button
                                    shape="circle"
                                    icon={<CheckOutlined />}
                                    onClick={(e) => {
                                        onCompleteAppointmentClick(record);
                                    }}
                                />
                            </Tooltip>
                    )}
                </Space>
            ),
        },
    ];

    const handleFilterChange = (columnKey, value) => {
        if(!value){
           setFilters({});
        } else {
            const newFilters = { ...filters };
            newFilters[columnKey] = (value && value.text) || value;
            console.log('newFilters:', newFilters);
            setFilters(newFilters);
        }
    };

    // const onDateSearch = function(value){
    //     if(isNaN(value)){
    //         handleFilterChange('patient_first_name', value);
    //     } else {
    //         handleFilterChange('mobile', value);
    //     }
    // };

    const onPatientNameSearch = (value) => {
        const newFilters = { ...filters, patient_name: value};
        setFilters(newFilters);
    };

    const onDateChange = (date, dateString) => {
        //console.log(date, dateString);
        console.log("date:", date);
        console.log('dateString:', dateString);
        const newFilters = { ...filters, date: dateString};
        setFilters(newFilters);
    };
    
    const handleSpecialtyChange = (value) => {
        console.log('speciality changed:', value);
        setSelectedSpecialty(value);
        setSelectedDoctor(null);
        const newFilters = { ...filters, doctor_id: undefined};
        setFilters(newFilters);
    };

    const handleDoctorChange = (value) => {
        console.log('handleDoctorChange:', value);
        setSelectedDoctor(value);
        const newFilters = { ...filters, doctor_id: value};
        setFilters(newFilters);
    };


    //const url = 'http://localhost:3006/api/patients';
    const url = `/api/hospital/appointment`;
    const sortColumn = 'patient_appointment_id';
    const sortOrder = 'asc';
    const initialPageSize = 10;
    const dateFormat = 'YYYY/MM/DD';

    return (
        
        <div className="pad-tb10 appointment-list-container">
            {contextHolder}

            <Space direction="horizontal" className='healify-aptmt-search-space-item' >
                    {/* <Button 
                        type="primary"
                        icon={<ScheduleOutlined />}
                        onClick={() => {
                            setOpen(true);
                        }}
                        className="marg-lr10"
                    >
                        Book Appointment
                    </Button> */}

                    <Search className='aptmt-search-patient-name'
                        placeholder="Search By patient First Name"
                        name="patient_name"
                        allowClear
                        enterButton
                        style={{
                            width: 300,
                            marginLeft: "10px",
                        }}
                        onSearch={(event)=> {onPatientNameSearch(event)}}
                    />  
                    
                    <DatePicker defaultValue={dayjs(getTodaysDate(), dateFormat)} placeholder="Search By appointment Date" className='aptmt-search-aptmt-date' style={{marginLeft: "10px", width: 140 }} onChange={onDateChange} format={dateFormat} />
                    
                    
                    {/* Show Speciality and doctor DD only if doctor is not logged in. As by default doctors can see their own appointments. */}
                    {JSON.parse(localStorage.getItem('user')).user.user_type_lookup_id !==  1 && (<>
                        <div className='appointment-list-speciality-container' style={{width: "250"}}>
                        <CustomSelect
                            selectedValue={selectedSpecialty}
                            apiEndpoint={`/api/users/${JSON.parse(localStorage.getItem('user'))
                                    .user.user_id
                                }/speciality`}
                            placeholder="Select Specialty"
                            optionFormat={{
                                id: 'specialty_id',
                                label: 'specialty_name',
                            }}
                            onChange={handleSpecialtyChange}
                        />
                    </div>
                    
                    <div className='appointment-list-doctor-container'>
                        <CustomSelect
                            selectedValue={selectedDoctor}
                            apiEndpoint={`/api/doctors?specialty_id=${selectedSpecialty}`}
                            placeholder="Select Doctor"
                            optionFormat={{
                                id: 'doctor_id',
                                label: 'first_name',
                            }}
                            onChange={handleDoctorChange}
                            disabled={!selectedSpecialty}
                        />
                    </div>
                    </>)}
            </Space>
            

            {/* <BookAppointment
                open={open}
                slots={slots}
                onCreate={onCreate}
                onCancel={() => {
                    setOpen(false);
                }}
            ></BookAppointment> */}

            <GeneratePrescription
                open={openPrescriptionModal}
                onCreate={onCreatePrescriptionModal}
                onCancel={() => {
                    setOpenPrescriptionModal(false);
                }}
                patient={selectedpatient}
            ></GeneratePrescription>

            {/* <GeneratePrescription
                open={openPrescriptionModal}>
                onCreate={onCreatePrescriptionModal}
                onCancel={() => {
                    setOpenPrescriptionModal(false)
                }}
                patient={selectedpatient}
            </GeneratePrescription> */}

            <HealthPlusTable
                columns={columns}
                apiUrl={url}
                filters={filters}
                sortColumn={sortColumn}
                sortOrder={sortOrder}
                initialPageSize={initialPageSize}
                refreshKey= {refreshKey}
            ></HealthPlusTable>
        </div>
    );
}
