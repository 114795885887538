import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    headerContainer: {
        marginTop: 10,
        flexDirection: 'row', // Add this style to align items horizontally
        justifyContent: 'space-between', // Add this style to distribute items evenly along the row
    },
    leftColumn: {
        flex: 1, // Take up 50% of the available space
    },
    rightColumn: {
        flex: 1, // Take up 50% of the available space
        textAlign: 'right', // Align text to the right
    },
    billTo: {
        paddingBottom: 3,
        fontFamily: 'Helvetica-Oblique'
    },
    // title: {
    //     width: '100%', // Set width to 100%
    //     textAlign: 'right', // Align text to the right
    // },
    boldTitle:{
        //fontWeight: 'bold', // Apply bold style
        fontStyle: 'bold',
    },
    title:{
        fontWeight: 'bold',
        textAlign: 'left',
        width: 100
    }
});

const BillTo = ({ invoice }) => (
    <View style={styles.headerContainer}>
        <View style={styles.leftColumn}>
            <Text>Patient No:  {invoice.patient_id}</Text>
            <Text>Patient Name: {invoice.patient_name}</Text>  
            <Text>Patient Mobile: {invoice.patient_phone}</Text> 
            <Text>Invoice Date: {invoice.created_ts}</Text> 
            <Text>Invoice No: {invoice.invoice_id}</Text> 
        </View>
        <View style={styles.rightColumn}>
            <Text>Doctor: {invoice.doctor_name}</Text>
            <Text>Department: {invoice.department}</Text> 
            <Text>OPD Date: {invoice?.time_slot_date}</Text>
            <Text>Token No: {invoice?.token_number}</Text>
        </View>
    </View>
);

export default BillTo;
