import { Card } from 'antd';
import { Typography } from 'antd';
const { Title } = Typography;


export default function TodaysAppointments({todaysAppointments}) {
    return (
        <Card.Grid className='dashboard-card' style={{border:"1px solid rgb(246 216 216)", backgroundColor: `rgb(246 216 216)`, textAlign: "center"}}> 
            <div>
                <Title level={3}>{todaysAppointments ? todaysAppointments: '0'}</Title>
                <p style={{fontSize: "18px"}}>Appointments Scheduled</p>
            </div>
        </Card.Grid>
    );
}
