const invoiceData = {
    "id": "65f587c690558c9a19a809cd",
    "invoice_no": "202312-47",
    "balance": "$2,587.29",
    "company": "Om Multi-Speciality Hospital",
    "company_address": "Mumbai Road, Omerga Osmanabad, Osmanabad - 413606",
    "patient_name": "Sharad",
    "patient_phone": "9986477769",
    "patient_number": "P1234",
    "doctor_name": "Dr Jaibharat Bhalke",
    "department": "Cardiology",
    "transaction_date": "2024-03-27",
    "appointment_date": "2024-03-27",
    "amount_paid": 1100,
    "items": [
        {
            "sl_no": 1,
            "particular": "Consultation",
            "rate": 1100,
            "qty": 1,
            "discount": 0,
            "total": 1100,
            // "amount_paid": 1100
        }
    ]
};

module.exports = invoiceData;