import axios from 'axios';
//const environment = process.env.REACT_APP_ENV;
console.log('process.env.REACT_APP_API_URL:', process.env.REACT_APP_BASE_URL);
const axiosAPI = axios.create({
    //baseURL: 'http://localhost:3006',
    //baseURL: process.env.REACT_APP_BASE_URL
    //baseURL: process.env.NODE_ENV === 'production' ? process.env.REACT_APP_PROD_API_URL : process.env.REACT_APP_DEV_API_URL
    baseURL: "https://www.symptam.com" //For generating the build
});

// Add a request interceptor
axiosAPI.interceptors.request.use(
    (config) => {
        // console.log('config sharad:', config);
        // console.log('config environment:', environment);
        const token = localStorage.getItem('token');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => Promise.reject(error),
);

// Add a response interceptor
axiosAPI.interceptors.response.use(
    function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response;
    },
    function (error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        const status = error.response?.status || 500;
        if (status === 401) {
            localStorage.removeItem('user');
            localStorage.removeItem('token');
            window.location = `${window.location.protocol}//${window.location.host}/login?error=You have been logged-off , please Login again.`;
            //window.location = window.location.protocol + '//' +window.location.host +'/login';
            return;
        } else {
            return Promise.reject(error); // Delegate error to calling side
        }
    }
);
export default axiosAPI;